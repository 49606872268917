import { Fragment, useState } from "react";
import styled from "styled-components";
import { Col, Form, InputGroup } from "react-bootstrap";

import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
// import { FaStarOfLife } from "react-icons/fa";
import { Placeholder } from "react-bootstrap";

interface InputBasicProps {
  id?: string;
  type?: string;
  textArea?: string;
  placeholder?: string;
  outerClassName?: string;
  innerClassName?: string;
  className?: string;
  icon?: JSX.Element | string;
  isRequired?: boolean;
  value?: string;
  name?: string;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  label?: string;
  md?: string;
  rules?: {
    function: (name: any, rules: any) => any;
    name: string;
    rules: any;
    errors?: any;
  };
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  loading?: boolean;
}

/**
 *
 * @returns
 * @param props
 */
export default function InputBasic(props: InputBasicProps): JSX.Element {
  const {
    id,
    type,
    placeholder,
    icon,
    outerClassName,
    innerClassName,
    isRequired,
    label,
    md,
    textArea,
    // value,
    // name,
    // minLength,
    // maxLength,
    // disabled,
    rules,
    // onChange,
    // autoComplete,
    // onKeyDown,
    loading,
  } = props;

  const [passwordType, setPasswordType] = useState("password");

  /**
   * Function to toggle password type
   *
   * @return {void}
   */
  const togglePassword = (): void => {
    setPasswordType(passwordType === "password" ? "text" : "password");
  };

  return (
    <Fragment>
      {loading ?? false ? (
        <div className={`input-group text-start ${outerClassName ?? "mb-3"}`}>
          <div className={`form-floating ${innerClassName ?? ""}`}>
            <Placeholder xs={6} />
            <Placeholder as={"h2"} animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        </div>
      ) : label && !textArea ? (
        <Form.Group as={Col} md={md} {...props}>
          <FormLabel>
            {label}
            {isRequired && <small className="req">*</small>}
          </FormLabel>
          <FormControlBasic
            id={id}
            type={type}
            placeholder={placeholder}
            errors={rules?.errors[rules?.name] !== undefined}
            {...rules?.function(rules?.name, rules?.rules)}
          />
          {rules?.errors[rules?.name] !== undefined && (
            <span
              className="invalid-feedback d-block text-start"
              style={{
                fontSize: "0.75rem",
              }}
            >
              {rules?.errors[rules?.name].message}
            </span>
          )}
        </Form.Group>
      ) : textArea ? (  
        <Form.Group as={Col} md={md} {...props}>
          <FormLabel>
            {label}
            {isRequired && <small className="req">*</small>}
          </FormLabel>
          <FormControlBasic
            id={id}
            as="textarea"
            style={{ height: "100px" }}
            aria-describedby="inputGroupPrepend"
            type={type}
            placeholder={placeholder}
            errors={rules?.errors[rules?.name] !== undefined}
            {...rules?.function(rules?.name, rules?.rules)}
          />
          {rules?.errors[rules?.name] !== undefined && (
            <span
              className="invalid-feedback d-block text-start"
              style={{
                fontSize: "0.75rem",
              }}
            >
              {rules?.errors[rules?.name].message}
            </span>
          )}
        </Form.Group>
      ) : type === "password" ? (
        <>
          <InputGroupCustom errors={rules?.errors[rules?.name] !== undefined} className={`${outerClassName ?? "mb-3"}`}>
            <InputCostumLeft>{icon}</InputCostumLeft>
            <FormControl
              id={id}
              type={passwordType}
              placeholder={placeholder}
              className="border-end-0"
              {...rules?.function(rules?.name, rules?.rules)}
              errors={rules?.errors[rules?.name] !== undefined}
            />
            <ButtonCustomIcon type="button" onClick={togglePassword}>
              {passwordType === "password" ? (
                <AiOutlineEye size={18} />
              ) : (
                <AiOutlineEyeInvisible size={18} />
              )}
            </ButtonCustomIcon>
          </InputGroupCustom>
          {rules?.errors[rules?.name] !== undefined && (
            <span
              className="invalid-feedback d-block text-start"
              style={{
                fontSize: "0.75rem",
              }}
            >
              {rules?.errors[rules?.name].message}
            </span>
          )}
        </>
      ) : (
        <>
          <InputGroupCustom errors={rules?.errors[rules?.name] !== undefined}>
            <InputCostumLeft>{icon}</InputCostumLeft>
            <FormControl
              id={id}
              type={type}
              placeholder={placeholder}
              {...rules?.function(rules?.name, rules?.rules)}
              errors={rules?.errors[rules?.name] !== undefined}
            />
          </InputGroupCustom>
          {rules?.errors[rules?.name] !== undefined && (
            <span
              className="invalid-feedback d-block text-start"
              style={{
                fontSize: "0.75rem",
              }}
            >
              {rules?.errors[rules?.name].message}
            </span>
          )}
        </>
      )}
    </Fragment>
  );
}

const InputGroupCustom = styled(InputGroup)<{ errors?: boolean }>`
  padding: 5px !important;
  margin: 12px 0;
  border-radius: 10px;
  border: 1px solid
    ${(props) => (props.errors ?? false ? "#FB6056" : "#e6e6e6")};

  background: #f9fbfe;
`;

const InputCostumLeft = styled(InputGroup.Text)`
  background: #f9fbfe;
  border: none;
  color: #acacac;
`;

const FormControl = styled(Form.Control)`
  border: none;
  background: #f9fbfe;

  &::placeholder {
    color: #707070;
    text-align: justify;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
`;

const FormControlBasic = styled(Form.Control)<{ errors?: boolean }>`
  background: #fff;
  border-radius: 10px;
  border: 1px solid
  ${(props) => (props.errors ?? false ? "#FB6056" : "#e6e6e6")};

  padding: 11px 22px;

  &::placeholder {
    color: #707070;
    text-align: justify;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
`;

const ButtonCustomIcon = styled.button`
  background: transparent;
  border: none;
  padding-left: 10px;
  color: #acacac;
`;

export const InputWrapper = styled.div<{ errors?: boolean }>`
  &:focus-within {
    .icon-active {
      background-color: ${(props) =>
        props.errors ?? false ? "#FB6056" : "#5648fb"};
    }

    .label-active {
      color: ${(props) => (props.errors ?? false ? "#FB6056" : "#5648fb")};
    }
  }
`;

export const Input = styled.input<{ errors?: boolean }>`
  border: none;
  border-bottom: 1px solid #cfcfcf;
  background-color: transparent;
  border-radius: 0;
  margin-top: 10px;
  padding: 0 50px 10px 63px !important;
  height: 45px !important;

  &:focus {
    box-shadow: none;
    border-bottom: 1px solid
      ${(props) => (props.errors ?? false ? "#FB6056" : "#5648fb")};
    background-color: transparent;

    .icon-content {
      background-color: ${(props) =>
        props.errors ?? false ? "#FB6056" : "#5648fb"};
    }
  }

  &:disabled {
    background-color: transparent;
    color: #cfcfcf;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #f8f9fe inset;
  }
`;

export const Icon = styled.span`
  border: none;
  border-radius: 0;
  background-color: transparent;
  position: absolute;
  top: 10px;
  border-right: 1px solid #cfcfcf;

  .span-custom {
    width: 30px;
    auto: 30px;
  }
`;

export const IconContent = styled.span<{ icon: string; errors?: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  mask: url(${(props) => props.icon}) no-repeat center;
  mask-size: 80%;
  -webkit-mask: url(${(props) => props.icon}) no-repeat center;
  -webkit-mask-size: 80%;
  background-color: #838383;
`;

export const Label = styled.label<{ errors?: boolean }>`
  padding-left: 60px !important;
  color: #838383;
  top: -2px !important;

  .form-control:focus + & {
    top: -10px !important;
    padding-left: 70px !important;
    font-size: 12px !important;
  }

  .form-control:not(:placeholder-shown) + & {
    top: -10px !important;
    font-size: 12px !important;
    padding-left: 70px !important;
  }
`;

export const InputWithoutIcon = styled.input<{ errors?: boolean }>`
  border: none;
  border-bottom: 1px solid #cfcfcf;
  background-color: transparent;
  border-radius: 0;
  margin-top: 10px;
  padding: 0 50px 10px 4px !important;
  height: 45px !important;

  &:focus {
    box-shadow: none;
    border-bottom: 1px solid
      ${(props) => (props.errors ?? false ? "#FB6056" : "#5648fb")};
    background-color: transparent;
  }

  &:disabled {
    background-color: transparent;
    color: #cfcfcf;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #f8f9fe inset;
  }
`;

export const LabelWithoutIcon = styled.label<{ errors?: boolean }>`
  padding-left: 0 !important;
  color: #838383;
  top: -2px !important;

  &:focus {
    box-shadow: none;
    border-bottom: 1px solid
      ${(props) => (props.errors ?? false ? "#FB6056" : "#5648fb")};
    background-color: transparent;
  }

  .form-control:focus + & {
    // color: ${(props) => (props.errors ?? false ? "#FB6056" : "#5648fb")};
    top: -10px !important;
    font-size: 12px !important;
  }

  .form-control:not(:placeholder-shown) + & {
    top: -10px !important;
    font-size: 12px !important;
  }
`;

const FormLabel = styled(Form.Label)`
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  .req {
    color: #fb6056;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;
