import { axiosClient } from "services/GatewayService";
import { AppDispatch } from "redux/store";

import {
  GET_ALL_CONSULTATIONS_REQUEST,
  GET_ALL_CONSULTATIONS_SUCCESS,
  GET_ALL_CONSULTATIONS_FAIL,
  GET_ALL_CONVERTATIONS_ROOM_REQUEST,
  GET_ALL_CONVERTATIONS_ROOM_SUCCESS,
  GET_ALL_CONVERTATIONS_ROOM_FAIL,
  GET_DETAILS_CONSULTATIONS_REQUEST,
  GET_DETAILS_CONSULTATIONS_SUCCESS,
  GET_DETAILS_CONSULTATIONS_FAIL,
  GET_DETAILS_CONVERTATION_REQUEST,
  GET_DETAILS_CONVERTATION_SUCCESS,
  GET_DETAILS_CONVERTATION_FAIL,
  RESET_CONVERTATION_SUCCESS,
} from "../reducers";

/**
 * Function to get all consultations
 *
 */
export const getConsultations = (params: {
  limit?: string;
  with_pagination?: boolean;
  search?: string;
  page: number;
}): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: GET_ALL_CONSULTATIONS_REQUEST });
    try {
      const res = await axiosClient.get("/api/admin/consultations", {
        params: {
          ...params,
        },
      });

      dispatch({
        type: GET_ALL_CONSULTATIONS_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_ALL_CONSULTATIONS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};

/**
 * Function to get details consultation
 *
 */
export const getDetailsConsul = (params: { id: string }): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: GET_DETAILS_CONSULTATIONS_REQUEST });
    try {
      const res = await axiosClient.get("api/admin/consultations/view", {
        params: {
          consultation_id: params.id,
        },
      });

      dispatch({
        type: GET_DETAILS_CONSULTATIONS_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_DETAILS_CONSULTATIONS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};

/**
 * Function to reset convertation
 *
 */
export const resetConvertation = (): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: RESET_CONVERTATION_SUCCESS });
  };
};

/**
 * Function to get details convertation
 *
 */
export const getDetailsConvertation = (params: { id: string }): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: GET_DETAILS_CONVERTATION_REQUEST });
    try {
      const res = await axiosClient.get(
        "api/admin/consultations/conversations",
        {
          params: {
            user_consultation_id: params.id,
          },
        }
      );

      dispatch({
        type: GET_DETAILS_CONVERTATION_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_DETAILS_CONVERTATION_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};

/**
 * Function to get all convertations room
 *
 */
export const getAllRoomConvertations = (params: {
  limit?: string;
  all_conversations: boolean;
  with_pagination?: boolean;
  search?: string;
  page: number;
}): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: GET_ALL_CONVERTATIONS_ROOM_REQUEST });
    try {
      const res = await axiosClient.get("api/admin/consultations/list-conversations", {
        params: {
          ...params,
        },
      });


      dispatch({
        type: GET_ALL_CONVERTATIONS_ROOM_SUCCESS,
        payload: res.data,
      });

      return res.data;
    } catch (error: any) {
      dispatch({
        type: GET_ALL_CONVERTATIONS_ROOM_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};

/**
 * Function to create consul
 *
 * @param formData FormData - data of consul
 */
export const createConsul = async (formData: FormData) => {
  try {
    const res = await axiosClient.post("api/admin/consultations", formData);
    const result = {
      message: res.data,
      errors: null,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.response.data.message,
      errors: error.response.data.errors,
      status: false,
    };
    return res;
  }
};

/**
 * Function to update consul
 *
 * @param formData FormData - data of consul
 */
export const updateConsul = async (formData: FormData) => {
  try {
    const res = await axiosClient.post(
      "api/admin/consultations/update",
      formData
    );
    const result = {
      message: res.data,
      errors: null,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.response.data.message,
      errors: error.response.data.errors,
      status: false,
    };
    return res;
  }
};

/**
 * Function to delete consul
 *
 * @param formData FormData - data of consul
 */
export const deleteConsul = async (params: { id: string }) => {
  try {
    const res = await axiosClient.delete("api/admin/consultations", {
      params: {
        consultation_id: params.id,
      },
    });
    const result = {
      message: res.data.message,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.response.data.message,
      status: false,
    };
    return res;
  }
};

/**
 * Function to delete covertation
 *
 * @param formData FormData - data of covertation
 */
export const deleteCovertation = async (params: { id: string }) => {
  try {
    const res = await axiosClient.delete("api/admin/consultations/delete-conversations", {
      params: {
        user_consultation_id: params.id,
      },
    });
    const result = {
      message: res.data.message,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.response.data.message,
      status: false,
    };
    return res;
  }
};

/**
 * Function to close covertation
 *
 * @param formData FormData - data of convertation
 */
export const closeConvertation = async (formData: FormData) => {
  try {
    const res = await axiosClient.post(
      "api/admin/consultations/close-conversations",
      formData
    );
    const result = {
      message: res.data.message,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.response.data.message,
      status: false,
    };
    return res;
  }
};

/**
 * Function to open covertation
 *
 */
export const openConvertation = async (params: { id: string }) => {
  try {
    const res = await axiosClient.post(
      "api/admin/consultations/open-conversations",
      {
        user_consultation_id: params.id
      }
    );
    const result = {
      message: res.data.message,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.response.data.message,
      status: false,
    };
    return res;
  }
};

/**
 * Function to close consul
 *
 * @param formData FormData - data of consul
 */
export const closeConsul = async (formData: FormData) => {
  try {
    const res = await axiosClient.post(
      "api/admin/consultations/close",
      formData
    );
    const result = {
      message: res.data.message,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.response.data.message,
      status: false,
    };
    return res;
  }
};

/**
 * Function to open consul
 *
 * @param formData FormData - data of consul
 */
export const openConsul = async (formData: FormData) => {
  try {
    const res = await axiosClient.post(
      "api/admin/consultations/open",
      formData
    );
    const result = {
      message: res.data.message,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.response.data.message,
      status: false,
    };
    return res;
  }
};

/**
 * Function to send message
 *
 * @param formData FormData - data of message
 */
export const sendMessage = async (formData: FormData) => {
  try {
    const res = await axiosClient.post(
      "api/admin/consultations/send-conversations",
      formData
    );
    const result = {
      message: res.data,
      errors: null,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.response.data.message,
      errors: error.response.data.errors,
      status: false,
    };
    return res;
  }
};

/**
 * Function to update consul
 *
 * @param formData FormData - data of consul
 */
export const createConsulRoom = async (formData: FormData) => {
  try {
    const res = await axiosClient.post(
      "api/admin/consultations/create-conversations",
      formData
    );
    const result = {
      message: res.data,
      errors: null,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.response.data.message,
      errors: error.response.data.errors,
      status: false,
    };
    return res;
  }
};