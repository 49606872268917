/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { TailSpin } from "react-loader-spinner";

import {
  getAllRoomConvertations,
  deleteCovertation,
  getConsultations,
} from "../../redux/actions";
import { getAllStudents } from "pages/Admin/StudentManagement/redux/actions";

import InputCustom from "components/Atoms/Inputs/InputCustom";
import Pagination from "components/Atoms/Paginations";
import { RootState } from "redux/store";
import { TableHeader } from "components/Atoms/Tables/header";
import { TableRow } from "components/Atoms/Tables/row";
import ModalCustom from "components/Organism/Modal";
import ModalCreate from "./ModalCreate";

interface TypeDataConvertation {
  user_consultation_id: string;
  consultation: {
    name: string;
  };
  first_mentor: null | {
    name: string;
  };
  user: {
    full_name: string;
  };
}

const tableTitle = ["Student", "Consultation Name", "Mentor", "Action"];

const InfiniteScrollCustom: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modalCreate, setModalCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [selected, setSelected] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [datas, setDatas] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [params, setParams] = useState({
    with_pagination: true,
    all_conversations: true,
    limit: "10",
    page: 1,
    search: "",
  });

  const all_consultations = useSelector(
    (state: RootState) => state.consultations_rooms
  );

  useEffect(() => {
    dispatch(getConsultations({ limit: '9999', page: 1 }))
    dispatch(getAllStudents({ limit: 9999, page: 1 }))
  }, []);

  useEffect(() => {
    dispatch(getAllRoomConvertations(params));
  }, [dispatch, params]);

  useEffect(() => {
    if (!all_consultations.loading && all_consultations.status === 200) {
      formatData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_consultations]);

  const formatData = () => {
    let obj = {};
    // eslint-disable-next-line array-callback-return
    all_consultations.response.data.map(
      // eslint-disable-next-line array-callback-return
      (d: TypeDataConvertation, i: number) => {
        let oneRow = {
          id: d.user_consultation_id,
          student: d.user.full_name,
          consultation_name: d.consultation.name,
          mentor: d.first_mentor !== null ? d.first_mentor.name : "",
          action: "",
        };

        obj = { ...obj, [i]: oneRow };
      }
    );
    setDatas(obj);
  };

  /**
   * Function to handle search with callback
   *
   * @returns void
   */
  const handleDebouncedSearch = useCallback(() => {
    setParams({
      ...params,
      search: searchValue,
    });

    const payload = {
      ...params,
      search: searchValue,
    };

    dispatch(getAllRoomConvertations(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // Function for handle debounce search
  useEffect(() => {
    const debounce = setTimeout(() => {
      handleDebouncedSearch();
    }, 500);

    return () => {
      clearTimeout(debounce);
    };
  }, [handleDebouncedSearch]);

  /**
   * Function to handle search
   *
   * @param string value
   * @returns void
   */
  const handleSearch = useCallback((value: string): void => {
    setSearchValue(value);
  }, []);

  const handleNavigate = (id: string) => {
    navigate(`/admin/consultation-room/${id}`);
  };

  const handlePage = async ({ selected }: { selected: number }) => {
    setParams((prev) => ({
      ...prev,
      page: selected + 1,
    }));

    const payload = {
      ...params,
      page: selected + 1,
    };
    await dispatch(getAllRoomConvertations(payload));
  };

  const handleOpenCreate = async () => {
    handleModalCreate();
  }

  const handleModal = () => setShowModal(!showModal);
  const handleModalCreate = () => setModalCreate(!modalCreate);

  const openModal = (key: string) => {
    setSelected(key);
    handleModal();
  };

  const closeModal = (): void => {
    setSelected("");
    handleModal();
  };

  const confirmDelete = async () => {
    setLoading(true);
    const res = await deleteCovertation({ id: selected });
    if (res.status) {
      setSuccess(true);
      setLoading(false);
      closeModal();
      dispatch(getAllRoomConvertations(params));
    }
    if (!res.status) {
      setLoading(false);
      toast.error(res.message);
    }
  };

  return (
    <ContainerCenter>
      <div className="header-content">
        <ButtonSubmit type="button" onClick={handleOpenCreate}>
          Create Room Consultation
        </ButtonSubmit>
        <div className="w-25">
          <InputCustom
            id="search"
            type="text"
            placeholder="Search consultation..."
            icon={<IoSearchOutline size={20} />}
            onChange={(e) => {
              handleSearch?.(e.target.value);
            }}
          />
        </div>
      </div>
      {all_consultations.loading && (
        <div className="h-100 d-flex justify-center align-items-center">
          <TailSpin
            height="200"
            width="200"
            color="#0F999B"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {!all_consultations.loading && all_consultations.status === 200 && (
        <table className="table mt-2">
          <TableHeader data={tableTitle} />
          <tbody>
            {datas
              ? Object.values(datas).map((columns: any) => (
                  <TableRow
                    key={columns.id}
                    columns={columns}
                    detailsTitle="View Convertation"
                    detailsAction={() => handleNavigate(columns.id)}
                    deleteTitle="Delete Convertation"
                    deleteAction={() => openModal(columns.id)}
                  />
                ))
              : ""}
          </tbody>
        </table>
      )}
      {!all_consultations.loading && all_consultations.status === 200 && (
        <Pagination
          pageCount={all_consultations.response.meta.last_page}
          forcePage={all_consultations.response.meta.current_page}
          onPageChange={handlePage}
        />
      )}

      <ModalCustom
        show={showModal}
        onHide={closeModal}
        onConfirm={confirmDelete}
        loading={loading}
        type="delete"
        title="Delete Convertation?"
        key_component="Convertation"
        desc="Are you sure want to delete this convertation?, you will not have access to this convertation anymore."
      />

      <ModalCustom
        show={success}
        key_component="Convertation"
        onHide={() => setSuccess(!success)}
        type="success"
        title="Success"
        desc="Successfully delete convertation"
      />

      <ModalCreate 
        show={modalCreate}
        onHide={handleModalCreate}
        params={params}
      />
    </ContainerCenter>
  );
};

export default InfiniteScrollCustom;

const ContainerCenter = styled.div`
  padding: 30px 40px;
  margin-bottom: 30px;
  position: relative;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 50px;

  .header-content {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .title {
      color: #303030;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const ButtonSubmit = styled.button`
  display: flex;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
`;
