/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage } from "@hookform/error-message";

import { createMentor } from "../redux/actions";
import { getAllClassesAdmin } from "pages/Home/redux/actions";
import { RootState } from "redux/store";

import InputBasic from "components/Atoms/Inputs/InputBasic";
import InputFile from "components/Atoms/Inputs/InputFile";
import InputCustom from "components/Atoms/Inputs/InputCustom";
import Layout from "components/Organism/Layouts";
import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import InputToogle from "components/Atoms/Inputs/InputToogle";
import InputSearchSelect from "components/Atoms/Inputs/InputSearchSelect";

export default function CreateMentorPage(): JSX.Element {
  const methods = useForm({
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    setError,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [classesLists, setClassesLists] = useState<any[]>([]);

  const allClasses = useSelector((state: RootState) => state.classess_courses);

  useEffect(() => {
    dispatch(getAllClassesAdmin())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!allClasses.loading && allClasses.status === 200) {
      const temp: any[] = [];
      allClasses.response.map((d: string) => {
        temp.push({
          label: d,
          value: d,
        })
      })
      setClassesLists(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allClasses]);

  const onSubmit = async (value: any) => {
    setLoading(true);
    const payload = new FormData();
    payload.append("name", value.name);
    payload.append("email", value.email);
    payload.append("password", value.password);
    payload.append("class", value.class);
    payload.append("description", value.description);
    payload.append("consultation_session", value.consultation_session);
    payload.append("image", value.image);

    const res = await createMentor(payload);
    if (res.status) {
      setLoading(false);
      toast.success(res.message);
      navigate("/admin/mentor");
    }
    if (!res.status) {
      setLoading(false);
      Object.entries(res.errors).map((d: any) => {
        let message: string = d[1][0];
        setError(
          d[0],
          { message: message },
          {
            shouldFocus: true,
          }
        );
      });
    }
  };

  const handleNavigate = (key: string): void => {
    if (key === "back") {
      return navigate(-1);
    }
    navigate(key);
  };

  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Create New Mentor" />

        <ContainerCenter>
          <div className="form-content">
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="d-flex flex-column gap-3"
              >
                <Row className="mb-3 border-bottom pb-5">
                  <div className="title-form">
                    <h2>General Information</h2>
                  </div>
                  <InputBasic
                    id="name"
                    type="text"
                    placeholder="Enter mentor name..."
                    md="6"
                    isRequired={true}
                    label="Mentor Name"
                    outerClassName="mb-3"
                    rules={{
                      function: register,
                      name: "name",
                      rules: {
                        required: "Mentor name is required",
                      },
                      errors,
                    }}
                  />
                  <div className="col-lg-6">
                    <Controller
                      control={control}
                      name="class"
                      rules={{
                        required: "Class is required",
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        const checkError = !!error && !value;

                        return (
                        <div className="text-start">
                          <FormLabel>
                            Class
                            <small className="req">*</small>
                          </FormLabel>
                          <InputSearchSelect
                            placeholder="Select Class"
                            innerClassName="mb-1"
                            options={classesLists}
                            isMulti={false}
                            withCreatable
                            errors={checkError}
                            onChange={(value: {
                              value: string;
                              label: string;
                            }) => {
                              onChange(value.value);
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="class"
                            render={({ message }) => (
                              <span
                                className="invalid-feedback d-block text-start"
                                style={{
                                  fontSize: "0.75rem",
                                }}
                              >
                                {message}
                              </span>
                            )}
                          />
                        </div>
                      )
                      }}
                    />
                  </div>

                  <InputBasic
                    id="mentor_desc"
                    type="text"
                    placeholder="Enter course description..."
                    md="6"
                    label="Mentor Description"
                    isRequired={true}
                    textArea="textarea"
                    rules={{
                      function: register,
                      name: "description",
                      rules: {
                        required: "Mentor Description is required",
                      },
                      errors,
                    }}
                    className="d-flex flex-column my-3"
                  />

                  <div className="col-lg-6 my-3">
                    <InputFile
                      accepted=".jpg,.png,.jpeg"
                      name="image"
                      label="Mentor Photo"
                      isRequired={true}
                      watch={watch}
                      placeholder="Upload photo (JPG, PNG,JPEG)"
                      rules={{
                        function: register,
                        name: "image",
                        rules: {
                          required: "Image is required",
                        },
                        errors,
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <InputToogle
                      label="Consultation Session Availability Status"
                      id="consultation_session"
                      rules={{
                        function: register,
                        name: "consultation_session",
                      }}
                    />
                  </div>
                </Row>

                <Row className="mb-3 border-bottom pb-5">
                  <div className="title-form">
                    <h2>Login Information</h2>
                  </div>
                  <InputBasic
                    id="email"
                    type="text"
                    placeholder="Enter mentor email address..."
                    md="6"
                    isRequired={true}
                    label="Mentor Email Address"
                    outerClassName="mb-3"
                    rules={{
                      function: register,
                      name: "email",
                      rules: {
                        required: "Mentor Email is required",
                      },
                      errors,
                    }}
                  />

                  <div className="col-lg-6">
                    <InputCustom
                      id="password"
                      type="password"
                      placeholder="Enter default password..."
                      label="Default Password"
                      isRequired={true}
                      rules={{
                        function: register,
                        name: "password",
                        rules: {
                          required: "Default Password is required",
                          validate: {
                            isMaxLength: (value: string) =>
                              value.length >= 8 ||
                              "At least 10 characters required",
                            isUppercase: (value: string) =>
                              /[A-Z]/.test(value) ||
                              "An uppercase character required",
                            isLowercase: (value: string) =>
                              /[a-z]/.test(value) ||
                              "A lowercase character required",
                            isNumber: (value: string) =>
                              /[0-9]/.test(value) || "A number required",
                            isSpecialCharacter: (value: string) =>
                              /[^a-zA-Z0-9]/.test(value) ||
                              "A special character required",
                          },
                        },
                        errors,
                      }}
                    />
                  </div>
                </Row>

                <div className="d-flex gap-3 justify-content-end">
                  <ButtonCancel
                    type="button"
                    onClick={() => handleNavigate("back")}
                  >
                    Back
                  </ButtonCancel>
                  <ButtonSubmit type={loading ? "button" : "submit"}>
                    {loading ? (
                      <Bars
                        height="30"
                        width="30"
                        color="#fff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      "Create Mentor"
                    )}
                  </ButtonSubmit>
                </div>
              </form>
            </FormProvider>
          </div>
        </ContainerCenter>
      </ContainerCustom>
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0;
`;

const ContainerCenter = styled.div`
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .form-content {
    width: 100%;

    .title-form {
      margin-bottom: 30px;
      h2 {
        color: #303030;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const ButtonSubmit = styled.button`
  display: flex;
  width: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
`;

const ButtonCancel = styled.button`
  display: flex;
  width: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #0f999b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #0f999b;
`;

const FormLabel = styled(Form.Label)`
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  .req {
    color: #fb6056;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;
