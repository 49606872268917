export const GET_MY_CONSULTATIONS_REQUEST = "GET_MY_CONSULTATIONS_REQUEST";
export const GET_MY_CONSULTATIONS_SUCCESS = "GET_MY_CONSULTATIONS_SUCCESS";
export const GET_MY_CONSULTATIONS_FAIL = "GET_MY_CONSULTATIONS_FAIL";

export const GET_DETAILS_MY_CONSULTATIONS_REQUEST =
  "GET_DETAILS_MY_CONSULTATIONS_REQUEST";
export const GET_DETAILS_MY_CONSULTATIONS_SUCCESS =
  "GET_DETAILS_MY_CONSULTATIONS_SUCCESS";
export const GET_DETAILS_MY_CONSULTATIONS_FAIL =
  "GET_DETAILS_MY_CONSULTATIONS_FAIL";

export const GET_MY_CONVERTATIONS_REQUEST = "GET_MY_CONVERTATIONS_REQUEST";
export const GET_MY_CONVERTATIONS_SUCCESS = "GET_MY_CONVERTATIONS_SUCCESS";
export const GET_MY_CONVERTATIONS_FAIL = "GET_MY_CONVERTATIONS_FAIL";

export const GET_ALL_CLASS_REQUEST = "GET_ALL_CLASS_REQUEST";
export const GET_ALL_CLASS_SUCCESS = "GET_ALL_CLASS_SUCCESS";
export const GET_ALL_CLASS_FAIL = "GET_ALL_CLASS_FAIL";

export const MyConsultationsReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_MY_CONSULTATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_MY_CONSULTATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_MY_CONSULTATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};

export const MyDetailsConsultationsReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_MY_CONSULTATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_MY_CONSULTATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_MY_CONSULTATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};

export const MyConvertationReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_MY_CONVERTATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_MY_CONVERTATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_MY_CONVERTATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};

export const AllClassReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_ALL_CLASS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_ALL_CLASS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};
