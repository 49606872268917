import styled from "styled-components";
import { Menu, MenuItem } from "react-pro-sidebar";

import { BsGrid3X3 } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { LuFileBadge2 } from "react-icons/lu";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { logout } from "pages/Authentication/redux/actions";

export default function Layout(): JSX.Element {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  /**
   * Function to handle style of navlink
   *
   */
  const getNavLinkClass = (path: string) => {
    if (window.location.pathname === "/" && path === "/course") {
      return true;
    }
    return window.location.pathname.includes(path);
  };

  return (
    <Menu
      rootStyles={{
        padding: "0px 20px 0px 10px",
      }}
    >
      <MenuItemCustom
        active={getNavLinkClass("home")}
        className={getNavLinkClass("/course") ? "ps-active" : ""}
        component={<Link to="/course" /> }
        icon={<BsGrid3X3 size={20} />}
        href="#"
      >
        {" "}
        Course{" "}
      </MenuItemCustom>
      <MenuItemCustom
        active={getNavLinkClass("consultations")}
        className={getNavLinkClass("/consultations") ? "ps-active" : ""}
        icon={<IoPeopleOutline size={20} />}
        component={<Link to="/consultations" /> }
        rootStyles={{
          color: "#ACACAC",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "30px",
        }}
      >
        {" "}
        Consultation{" "}
      </MenuItemCustom>
      <MenuItemCustom
        active={getNavLinkClass("my-certificates")}
        className={getNavLinkClass("/my-certificates") ? "ps-active" : ""}
        component={<Link to="/my-certificates" /> }
        icon={<LuFileBadge2 size={20} />}
        href="#"
        rootStyles={{
          color: "#ACACAC",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "30px",
        }}
      >
        {" "}
        Certificate{" "}
      </MenuItemCustom>
      <MenuItemCustom
        onClick={handleLogout}
        icon={<RiLogoutBoxRLine size={20} />}
        rootStyles={{
          color: "#ACACAC",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "30px",
        }}
      >
        {" "}
        Logout{" "}
      </MenuItemCustom>
    </Menu>
  );
}

const MenuItemCustom = styled(MenuItem)`
  color: ${(p) => p.active ? "#0F999B" : "#acacac"};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin: 10px 0px;

  &.ps-active {
    background-color: #0f999b;
    margin: 0px 5px;
    border-radius: 10px;
    color: #ffffff;
  }
`;
