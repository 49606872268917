import { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Bars } from "react-loader-spinner";

import Menus from "components/Organism/Navbar";
import {
  viewCoursesDetails,
  createOrderClass,
  createOrderConsul,
} from "../CourseDetails/redux/actions";
import { getAllClasses, getAllConsultations } from "./redux/actions";
import { fCurrency } from "utility/Utils";

import Bg from "assets/images/bg-co.jpeg";
import IconImg from "assets/images/icon-list.svg";
import logoImg from "assets/images/exclodemy.png";
import Akulaku from "assets/images/akulaku.png";
import Atm from "assets/images/atm.png";
import BCA from "assets/images/bca.png";
import BNI from "assets/images/bni.png";
import Gopay from "assets/images/gopay.png";
import Kredivo from "assets/images/kredivo.png";
import Mandiri from "assets/images/mandiri.png";
import Master from "assets/images/master.png";
import Qris from "assets/images/qris.png";
import Spay from "assets/images/spay.png";
import Visa from "assets/images/visa.png";
import Midtrans from "assets/images/midtrans.png";

type DataCurrency = {
  amount: string;
  currency_iso3: string;
};

export default function Checkout(): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    mode: "onChange",
  });

  const dispatch = useDispatch();
  const { idcourse } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("class");

  const courseDetails = useSelector((state: RootState) => state.view_course);

  function checkTextEnding(text: string, keyword: string): boolean {
    const endIndex = text.length - keyword.length;
    return text.indexOf(keyword, endIndex) !== -1;
  }

  useEffect(() => {
    dispatch(getAllClasses({ limit: 9 }));
    dispatch(getAllConsultations({ limit: 9 }));

    if (idcourse) {
      if (checkTextEnding(idcourse, "consul")) {
        setType("consul");
      }
      dispatch(viewCoursesDetails(idcourse));
    }
  }, [dispatch, idcourse]);

  const handleSubmited = async (event: any) => {
    setLoading(true);
    if (type === 'class') {
      let params = {
        ...event,
        course_id: idcourse,
      };
      const res = await createOrderClass(params);
      if (typeof res !== "boolean") {
        window.location.replace(res.data.redirect_url);
        setLoading(false);
      }  
    }

    if (type === 'consul') {
      let params = {
        ...event,
        consultation_id: idcourse,
      };
      const res = await createOrderConsul(params);
      if (typeof res !== "boolean") {
        window.location.replace(res.data.redirect_url);
        setLoading(false);
      }  
    }
    setLoading(false);
  };

  // const handleShow = () => setShow(!show);

  const filterDataPrice = (data: DataCurrency[]) => {
    let result: string = "";
    // eslint-disable-next-line array-callback-return
    data.filter((item) => {
      if (item.currency_iso3.toLowerCase() === "idr") {
        result = item.amount;
      }
    });

    return result;
  };

  const handleNavigate = (url: string) => {
    window.location.replace(url);
  };

  return (
    <ContainerCustom fluid>
      <div>
        <Menus />
      </div>
      <div className="hero">
        <h2>Checkout</h2>
        <span className="desc">
          70% lulusan bootcamp gagal karena mereka tidak memahami softskill yang
          dibutuhkan ketika terjun ke dunia langsung. Pengalaman dari orang
          dalam adalah kunci!
        </span>
      </div>

      <ContainerCenter className="row gap-5 mx-0">
        <div className="col-lg-5 content-left">
          <h2 className="title-top">
            Hard Skill dan Soft Skill: Dua Sisi Mata Uang Keberhasilan Karir
            Anda
          </h2>
          <span className="desc-top">
            70% lulusan bootcamp gagal karena mereka tidak memahami softskill
            yang dibutuhkan ketika terjun ke dunia langsung. Pengalaman dari
            orang dalam adalah kunci!
          </span>
          <CustomImage src={Bg} alt="hero" />
          <span className="desc-bottom">
            70% lulusan bootcamp gagal karena mereka tidak memahami softskill
            yang dibutuhkan ketika terjun ke dunia langsung.
          </span>
          <ul>
            <li>
              <div className="list-content">
                <img src={IconImg} alt="icon" />
                <span className="text-list">
                  Exclusive Learning Material Khusus Untuk Anda
                </span>
              </div>
            </li>
            <li>
              <div className="list-content">
                <img src={IconImg} alt="icon" />
                <span className="text-list">
                  Free 1:1 Consultation with the Pros
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-lg-5">
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(handleSubmited)}
          >
            <Row className="mb-3">
              <Form.Group
                as={Col}
                md="6"
                controlId="validationCustom01"
                className="mb-3"
              >
                <FormLabel>
                  First Name <small className="req">*</small>
                </FormLabel>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter first name"
                  {...register("first_name", {
                    required: "Please enter first name",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="first_name"
                  render={({ message }) => (
                    <span
                      className="invalid-feedback d-block text-start"
                      style={{
                        fontSize: "0.75rem",
                      }}
                    >
                      {message}
                    </span>
                  )}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <FormLabel>
                  Last Name <small className="req">*</small>
                </FormLabel>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter last name"
                  {...register("last_name", {
                    required: "Please enter last name",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="last_name"
                  render={({ message }) => (
                    <span
                      className="invalid-feedback d-block text-start"
                      style={{
                        fontSize: "0.75rem",
                      }}
                    >
                      {message}
                    </span>
                  )}
                />
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                <FormLabel>
                  Email <small className="req">*</small>
                </FormLabel>
                <Form.Control
                  type="email"
                  placeholder="example@mail.com"
                  aria-describedby="inputGroupPrepend"
                  {...register("email", {
                    required: "Please enter email",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => (
                    <span
                      className="invalid-feedback d-block text-start"
                      style={{
                        fontSize: "0.75rem",
                      }}
                    >
                      {message}
                    </span>
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter email
                </Form.Control.Feedback>
              </Form.Group>
              {type === "consul" && (
                <Form.Group
                  as={Col}
                  md="12"
                  controlId="validationCustomUsername"
                >
                  <FormLabel>Perihal</FormLabel>
                  <Form.Control
                    type="text"
                    placeholder="Perihal membeli consultation ini..."
                    as="textarea"
                    aria-describedby="inputGroupPrepend"
                    {...register("needs")}
                  />
                </Form.Group>
              )}
            </Row>
            <Row className="mb-3">
              {!courseDetails.loading && courseDetails.status === 200 && (
                <CardCustom>
                  <div className="card-top">
                    <h4 className="text-capitalize">
                      {courseDetails.response.data.name}
                    </h4>
                    <span>
                      70% lulusan bootcamp gagal karena mereka tidak memahami
                      softskill yang dibutuhkan ketika terjun ke dunia langsung.
                      Pengalaman dari orang dalam adalah kunci!
                    </span>
                  </div>

                  <div className="total-content">
                    <h6>Total</h6>
                    {/* <p className="discount">Rp. 1.000.000</p> */}
                      {type === 'class' ? (
                        <p className="gt">
                          {fCurrency(
                            filterDataPrice(courseDetails.response.data.prices)
                          )}
                        </p>
                      ) : (
                        <p className="gt">
                          {fCurrency(
                            courseDetails.response.data.amount
                          )}
                        </p>
                      )}
                  </div>
                </CardCustom>
              )}
              <PaymentContent className="payment-method ">
                <h5>Payment Method</h5>
                <div className="list-content">
                  <ul>
                    <li>
                      <div className="d-flex gap-2">
                        <ImagePayment src={Visa} alt="visa" />
                        <ImagePayment src={Master} alt="master" />
                      </div>
                    </li>
                    <li>
                      <div className="d-flex gap-2">
                        <ImagePaymentBank src={BCA} alt="bca" />
                        <ImagePaymentBank src={BNI} alt="bni" />
                        <ImagePaymentBank src={Mandiri} alt="mandiri" />
                        <ImagePaymentAtm src={Atm} alt="atm" />
                      </div>
                    </li>
                    <li>
                      <div className="d-flex gap-2">
                        <ImagePaymentBank src={Gopay} alt="gopay" />
                        <ImagePaymentBank src={Qris} alt="qris" />
                        <ImagePaymentBank src={Spay} alt="spay" />
                      </div>
                    </li>
                    <li>
                      <div className="d-flex gap-2">
                        <ImagePaymentBank src={Akulaku} alt="akulaku" />
                        <ImagePaymentBank src={Kredivo} alt="kredivo" />
                      </div>
                    </li>
                  </ul>
                </div>
              </PaymentContent>
            </Row>

            <ButtonSubmit type="submit">
              {loading ? (
                <div className="mx-2">
                  <Bars
                    height="40"
                    width="40"
                    color="#fff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              ) : (
                <>
                  <span>Bayar Melalui</span>
                  <img src={Midtrans} alt="midtrans" />
                </>
              )}
            </ButtonSubmit>
          </Form>
        </div>
      </ContainerCenter>

      <FooterCustom>
        <div className="row mx-0 d-flex justify-content-between border-bottom pb-5">
          <div className="col-lg-6 content-left pe-5 me-5">
            <img className="image" src={logoImg} alt="logo" />
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              rhoncus vulputate ipsum. In nibh ipsum, maximus et dui vitae,
              congue bibendum metus.
            </span>
          </div>
          <div className="col-lg-2 content-list">
            <h6>Information</h6>
            <ul>
              <li
                onClick={() =>
                  handleNavigate(
                    "https://exclolab.com/exclodemy/about-exclodemy/"
                  )
                }
              >
                About Exclomerce
              </li>
              <li
                onClick={() =>
                  handleNavigate(
                    "https://exclolab.com/exclodemy/contact-exclodemy/"
                  )
                }
              >
                Contact Us
              </li>
              <li
                onClick={() =>
                  handleNavigate(`${window.location.origin}/login`)
                }
              >
                Log in
              </li>
            </ul>
          </div>
          <div className="col-lg-2 content-list">
            <h6>Customer Service</h6>
            <ul>
              <li
                onClick={() =>
                  handleNavigate("https://exclolab.com/exclodemy/faq/")
                }
              >
                FAQ
              </li>
              <li
                onClick={() =>
                  handleNavigate(
                    "https://exclolab.com/exclodemy/terms-and-condition/"
                  )
                }
              >
                Terms and Condition
              </li>
            </ul>
          </div>
        </div>
        <div className="copyright">
          <span>Copyright ©2023 Exclodemy. All Rights Reserved</span>
        </div>
      </FooterCustom>
    </ContainerCustom>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0;
  background-color: rgba(241, 244, 247, 1);

  .top-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0px;
    background-color: #0f999b;
    top: 0;
    margin-top: 0;
    transition: all 1s;
    position: relative;

    .content-center {
      display: flex;
      align-items: center;
      gap: 100px;
      h2 {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 27px;
      }
    }
  }

  .hero {
    background: url(${Bg});
    box-shadow: inset 0 0 0 2000px rgba(34, 34, 34, 0.5);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 120px 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      color: #fff;
      text-align: center;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 52px;
    }

    .desc {
      width: 50%;
      color: #fff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
  }
`;

const ContainerCenter = styled.div`
  padding: 100px 300px;
  position: relative;
  background-color: #fff;

  .content-left {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .title-top {
      color: #000;
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
      text-align: justify;
    }

    .desc-top,
    .desc-bottom {
      color: #6e6e6e;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      text-align: justify;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        .list-content {
          display: flex;
          align-items: center;
          gap: 10px;

          .text-list {
            color: var(--text-400, #6e6e6e);
            text-align: justify;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
          }
        }
      }
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const FooterCustom = styled.div`
  padding: 50px 300px;
  background-color: #f9fbfe;
  display: flex;
  flex-direction: column;
  color: #64758c;

  .content-left {
    display: flex;
    flex-direction: column;
    padding: 0;
    img {
      width: 110px;
      height: 100px;
    }
  }

  .content-list {
    padding-top: 20px;
    h6 {
      color: #222;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
    }

    ul {
      padding: 0;
      color: #acacac;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;

      li {
        margin: 10px 0;
        cursor: pointer;
      }
      li:hover {
        color: #0f999b;
      }
    }
  }

  .copyright {
    padding-top: 30px;

    span {
      color: #6e6e6e;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
    }
  }
`;

const CustomImage = styled.img`
  width: 100%;
  height: 300px;
  border-radius: 10px;
  object-fit: cover;
`;

const FormLabel = styled(Form.Label)`
  color: #303030;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;

  .req {
    color: #fb6056;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;

const CardCustom = styled.div`
  display: flex;
  padding: 40px 20px;
  flex-direction: column;
  gap: 20px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid #0f999b;
  background: #9fd6d7;

  .card-top {
    color: #0f999b;
    border-bottom: 1px solid #0f999b;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;

    h4 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }

    span {
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
  }

  .total-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    h6 {
      color: #0f999b;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
    }

    .discount {
      color: #0f999b;
      font-size: 18px;
      font-style: italic;
      font-weight: 600;
      text-decoration-line: line-through;
      padding: 0 !important;
      margin: 0 !important;
    }

    .gt {
      padding: 0 !important;
      margin: 0 !important;
      color: #0f999b;
      font-size: 27px;
      font-style: normal;
      font-weight: 700;
      line-height: 36px;
    }
  }
`;

const PaymentContent = styled.div`
  margin-top: 20px;

  h5 {
    color: #303030;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }

  .list-content {
    ul {
      padding: 0;

      li {
        margin-bottom: 20px;
      }
    }
  }
`;

const ImagePayment = styled.img`
  height: 20px;
`;

const ImagePaymentBank = styled.img`
  height: 20px;
`;

const ImagePaymentAtm = styled.img`
  height: 20px;
`;

const ButtonSubmit = styled.button`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: #0f999b;
  gap: 10px;

  span {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
  }

  img {
    height: 75px;
  }
`;
