import { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Bars, TailSpin } from "react-loader-spinner";
import { useForm, FormProvider } from "react-hook-form";

import { useSelector, useDispatch } from "react-redux";

import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import { updateAccount, getMyAccount } from "./redux/actions";
import Layout from "components/Organism/Layouts";
import ModalCustom from "./updateImage";
import InputBasic from "components/Atoms/Inputs/InputBasic";
import ChangePasswordComponent from "./components/ChangePasswordComponents";

export default function MyAccount(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState("");
  const [imgUrl, setImgUrl] = useState("");

  const data_account = useSelector((state: any) => state.my_account);

  useEffect(() => {
    dispatch(getMyAccount());
  }, [dispatch]);

  useEffect(() => {
    if (!data_account.loading && data_account.status === 200) {
      handleGetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_account]);

  const handleGetData = () => {
    setValue("first_name", data_account.response.data.first_name);
    setValue("last_name", data_account.response.data.last_name);
    setValue("email", data_account.response.data.email);
    setValue(
      "address",
      data_account.response.data.address === "null"
        ? ""
        : data_account.response.data.address
    );

    setImgUrl(
      data_account.response.data.image ?? data_account.response.data.image_url
    );
  };

  const onSubmit = async (event: any) => {
    setLoading(true);
    let payload = event;
    const res = await updateAccount(payload);
    if (res.status) {
      setMessageSuccess(res.message);
      dispatch(getMyAccount());
      setLoading(false);
      setSuccess(true);
      setShow(true);
    }
    if (!res.status) {
      setLoading(false);
      setSuccess(false);
      setShow(true);
      setMessageSuccess(res.message);
    }
    setLoading(false);
  };

  const handleResendEmail = async () => {
    const payload = {
      first_name: watch("first_name"),
      last_name: watch("last_name"),
      email: watch("email"),
      address: watch("address"),
    };
    const res = await updateAccount(payload);
    if (res.status) {
      setMessageSuccess("Successfully resend email");
      setLoading(false);
      setSuccess(true);
      setShow(true);
    }
    if (!res.status) {
      setLoading(false);
      setSuccess(false);
      setShow(true);
      setMessageSuccess(res.message);
    }
  };

  const handleNavigate = (key: string): void => {
    if (key === "back") {
      return navigate(-1);
    }
    navigate(key);
  };

  const handleModal = () => setShowModal(!showModal);

  const handleSuccessUpload = () => {
    dispatch(getMyAccount());
    handleModal();
  };
  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin />

        <ContainerCenter>
          {data_account.loading ? (
            <div className="h-100 d-flex justify-center align-items-center">
              <TailSpin
                height="200"
                width="200"
                color="#0F999B"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <div className="user-info">
                <BoxUser>
                  <img src={imgUrl} alt="" />
                </BoxUser>
                <div className="title-info">
                  <span className="change-photo" onClick={handleModal}>
                    Change Photo
                  </span>
                </div>
              </div>
              <div className="form-content">
                <FormProvider {...methods}>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="d-flex flex-column gap-3"
                  >
                    <Row className="mb-3">
                      <InputBasic
                        id="first_name"
                        type="text"
                        placeholder="Enter first name..."
                        md="6"
                        isRequired={true}
                        label="First Name"
                        outerClassName="mb-3"
                        rules={{
                          function: register,
                          name: "first_name",
                          rules: {
                            required: "First name is required",
                          },
                          errors,
                        }}
                      />
                      <InputBasic
                        id="name"
                        type="text"
                        placeholder="Enter Last name..."
                        md="6"
                        isRequired={true}
                        label="Last Name"
                        outerClassName="mb-3"
                        rules={{
                          function: register,
                          name: "last_name",
                          rules: {
                            required: "Last name is required",
                          },
                          errors,
                        }}
                      />
                      <InputBasic
                        id="email"
                        type="text"
                        placeholder="Enter email address..."
                        md="6"
                        isRequired={true}
                        label="Email"
                        outerClassName="mb-3"
                        rules={{
                          function: register,
                          name: "email",
                          rules: {
                            required: "Email is required",
                          },
                          errors,
                        }}
                      />

                      {window.location.pathname.includes("admin") ? null : (
                        <InputBasic
                          id="address"
                          type="text"
                          placeholder="Enter your address..."
                          md="6"
                          label="Address"
                          textArea="textarea"
                          rules={{
                            function: register,
                            name: "address",
                            rules: {},
                            errors,
                          }}
                          className="d-flex flex-column my-3"
                        />
                      )}

                      {show ? (
                        <Alert
                          variant={`${success ? "success" : "danger"} w-75`}
                          onClose={() => setShow(!show)}
                          dismissible
                          className="mx-3 mt-2 mb-0 d-flex flex-column"
                        >
                          <p>{messageSuccess}</p>
                          {messageSuccess.includes("email") && (
                            <button
                              onClick={handleResendEmail}
                              type="button"
                              className="w-25 text-start"
                            >
                              Resend Email
                            </button>
                          )}
                        </Alert>
                      ) : null}
                    </Row>

                    <div className="d-flex gap-3">
                      <ButtonSubmit type={loading ? "button" : "submit"}>
                        {loading ? (
                          <Bars
                            height="30"
                            width="30"
                            color="#a3cfbb"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        ) : (
                          "Save Changes"
                        )}
                      </ButtonSubmit>
                      <ButtonCancel
                        type="button"
                        onClick={() => handleNavigate("back")}
                      >
                        Back
                      </ButtonCancel>
                    </div>
                  </form>
                </FormProvider>
              </div>

              <ChangePasswordComponent />
            </>
          )}
        </ContainerCenter>
      </ContainerCustom>

      <ModalCustom
        show={showModal}
        onHide={handleModal}
        onConfirm={handleSuccessUpload}
        title="Upload Photo"
      />
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0;
`;

const ContainerCenter = styled.div`
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .user-info {
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 230px;
    .title-info {
      .change-photo {
        color: #0f999b;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 27px;
        text-decoration-line: underline;
        cursor: pointer;
      }
      .title {
        text-transform: capitalize;
        color: #303030;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0px;
      }

      .message {
        color: #6e6e6e;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: justify;
      }
    }
  }

  .form-content {
    width: 100%;
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const BoxUser = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  background: #0f999b;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ButtonSubmit = styled.button`
  display: flex;
  minwidth: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  &:hover {
    background: transparent;
    color: #0f999b;
    border: 1px solid #0f999b;
  }
`;

const ButtonCancel = styled.button`
  display: flex;
  width: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #0f999b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #0f999b;

  &:hover {
    background: #0f999b;
    color: #fff;
  }
`;
