import { axiosClient } from 'services/GatewayService';
import { AppDispatch } from 'redux/store';

import {
    GET_ALL_ASK_REQUEST,
    GET_ALL_ASK_SUCCESS,
    GET_ALL_ASK_FAIL,
} from '../reducers';
import { toast } from 'react-toastify';

/**
 * Function to get all my courses
 *
 * @param boolean param.with_pagination - pagination
 * @param string params.search - search of courses
 * @return Promise<void>
 */
export const getComments = (params: {
    course_content_id: string;
    parent_id?: string;
    limit: number;
    search?: string;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: GET_ALL_ASK_REQUEST });
        try {
            const res = await axiosClient.get('/api/comments/view', {
                params: {
                    ...params
                }
            });

            dispatch({
                type: GET_ALL_ASK_SUCCESS,
                payload: res.data,
            });

        } catch (error: any) {
            dispatch({
                type: GET_ALL_ASK_FAIL,
                payload: error?.response?.data?.message,
            });
        }
    };
};

export const addAsk = async (params: {
    comment: string;
    course_content_id: string;
    comment_parent_id?: string | undefined;
}) => {
    try {
        const res = await axiosClient.post('/api/comments/send', {
            ...params
        });
        toast.success(res.data.message);
        return true;
    } catch (error: any) {
        return false;
    }
}
