/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  Controller,
  useForm,
  FormProvider,
} from "react-hook-form";
import { CSSObjectWithLabel } from "react-select";
import { Bars } from "react-loader-spinner";
import { ErrorMessage } from "@hookform/error-message";
import { toast } from "react-toastify";

import { getAllMentors } from "pages/Admin/MentorManagement/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { createCourse, getAllClassesAdmin } from "pages/Home/redux/actions";
import { getCredential } from "utility/Utils";
import { RootState } from "redux/store";

import InputBasic from "components/Atoms/Inputs/InputBasic";
import Layout from "components/Organism/Layouts";
import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import InputSearchSelect from "components/Atoms/Inputs/InputSearchSelect";
import InputCurrency from "components/Atoms/Inputs/InputCurrency";
import SectionVideos from "./components/sectionVideos";

type UserType = {
  id: string;
  name: string;
};

export default function CreateCourse(): JSX.Element {
  const defaultValues = {
    "class": "",
    "mentor_id": "",
    "name": "",
    "description": "",
    "price": '',
    "sections": [
        {
            "name": "",
            "contents": [
                {
                    "name": "",
                    "video": ""
                },
            ]
        },
    ]
}
  const methods = useForm({
    mode: "onChange",
    defaultValues
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    setValue,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [mentorLists, setMentorLists] = useState<any[]>([]);
  const [classesLists, setClassesLists] = useState<any[]>([]);

  const dataMentor = useSelector((state: any) => state.mentors);
  const allClasses = useSelector((state: RootState) => state.classess_courses);

  useEffect(() => {
    dispatch(getAllClassesAdmin())
    dispatch(
      getAllMentors({
        limit: 9999,
        with_pagination: false,
        page: 1,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dataMentor.loading && dataMentor.status === 200) {
      handleGetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMentor]);

  useEffect(() => {
    if (!allClasses.loading && allClasses.status === 200) {
      const temp: any[] = [];
      allClasses.response.map((d: string) => {
        temp.push({
          label: d,
          value: d,
        })
      })
      setClassesLists(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allClasses]);

  const handleGetData = () => {
    const user: any = getCredential();
    let temp: any[] = [];
    // eslint-disable-next-line array-callback-return
    dataMentor.response.data.map((d: UserType, i: number) => {
      temp.push({
        value: d.id,
        label: d.name,
      });
    });
    setMentorLists(temp);
    if (user !== null) {
      setValue("mentor_id", user.id)
    }
  };

  const convertPrice = (value: any): any => {
    const numericString = value.replace(/[^0-9]/g, "");

    // Parse the numeric string as an integer
    const result = parseInt(numericString, 10);
    return result;
  };

  const onSubmit = async (value: any) => {
    setLoading(true);
    const payload = new FormData();
    payload.append("class", value.class);
    payload.append("mentor_id", value.mentor_id);
    payload.append("name", value.name);
    payload.append("description", value.description);
    payload.append("price", convertPrice(value.price));
    // eslint-disable-next-line array-callback-return
    value.sections.map((d: { name: string; contents: {
      name: string; 
      video: File;
    }[] }, i: number) => {
      payload.append(`sections[${i}][name]`, d.name);
      d.contents.map((item, index) => {
        payload.append(`sections[${i}][contents][${index}][name]`, item.name);
        payload.append(`sections[${i}][contents][${index}][video]`, item.video);
      })
    });

    const res = await createCourse(payload);
    if (res.status) {
      toast.success(res.message);
      setLoading(false);
      navigate("/admin/course");
    }
    if (!res.status) {
      setLoading(false);
      Object.entries(res.errors).map((d: any) => {
        let message: string = d[1][0];
        setError(d[0], { message: message }, {
          shouldFocus: true
        });
      })
    }
  };

  const handleNavigate = (key: string): void => {
    if (key === "back") {
      return navigate(-1);
    }
    navigate(key);
  };

  const basicStyles = {
    control: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      borderRadius: "10px",
      boxShadow: "none",
      padding: "0 10px",
      margin: 0,
    }),
    valueContainer: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      padding: "10px 0px",
      margin: 0,
      fontSize: "1rem",
    }),
    option: (
      baseStyles: CSSObjectWithLabel,
      state: { isFocused: boolean; isHover: boolean }
    ) => ({
      ...baseStyles,
      backgroundColor: state.isFocused || state.isHover ? "#0F999B" : "",
      color: state.isFocused ? "white" : "black",
      fontSize: "14px",
    }),
  };

  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Create New Course" />

        <ContainerCenter>
          <div className="form-content">
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="d-flex flex-column gap-3"
              >
                <Row className="mb-3 border-bottom pb-5">
                  <div className="title-form">
                    <h2>General Information</h2>
                  </div>
                  <div className="col-lg-6">
                    <Controller
                      control={control}
                      name="class"
                      rules={{
                        required: "Class is required",
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        const checkError = !!error && !value;

                        return (
                        <div className="text-start">
                          <FormLabel>
                            Class
                            <small className="req">*</small>
                          </FormLabel>
                          <InputSearchSelect
                            placeholder="Select Class"
                            innerClassName="mb-1 "
                            styles={basicStyles}
                            options={classesLists}
                            isMulti={false}
                            withCreatable
                            errors={checkError}
                            onChange={(value: {
                              value: string;
                              label: string;
                            }) => {
                              setValue("class", value.value);
                              onChange(value.value);
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="class"
                            render={({ message }) => (
                              <span
                                className="invalid-feedback d-block text-start"
                                style={{
                                  fontSize: "0.75rem",
                                }}
                              >
                                {message}
                              </span>
                            )}
                          />
                        </div>
                      )
                      }}
                    />
                  </div>
                  <InputBasic
                    id="name"
                    type="text"
                    placeholder="Enter course name..."
                    md="6"
                    label="Course Name"
                    isRequired={true}
                    outerClassName="mb-3"
                    rules={{
                      function: register,
                      name: "name",
                      rules: {
                        required: "Course Name is required",
                      },
                      errors,
                    }}
                  />

                  <div className="col-lg-6">
                    <Controller
                      control={control}
                      name="mentor_id"
                      rules={{
                        required: "Mentor is required",
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => {
                        const checkError = !!error && !value;

                        return (
                        <div className="text-start mt-2">
                          <FormLabel>
                            Mentor
                            <small className="req">*</small>
                          </FormLabel>
                          <InputSearchSelect
                            placeholder="Select Mentor"
                            innerClassName="mb-1 "
                            styles={basicStyles}
                            options={mentorLists}
                            isMulti={false}
                            errors={checkError}
                            value={mentorLists.filter(
                              (item) => item.value === watch("mentor_id")
                            )}
                            onChange={(value: {
                              value: string;
                              label: string;
                            }) => {
                              onChange(value.value);
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="mentor_id"
                            render={({ message }) => (
                              <span
                                className="invalid-feedback d-block text-start"
                                style={{
                                  fontSize: "0.75rem",
                                }}
                              >
                                {message}
                              </span>
                            )}
                          />
                        </div>
                      )
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <InputCurrency
                      id="price"
                      label="Course Price"
                      placeholder="Enter course price..."
                      className="mt-2"
                      isRequired={true}
                      rules={{
                        function: register,
                        name: "price",
                        rules: {
                          required: "Course Price is required",
                        },
                        errors,
                      }}
                      prefix="Rp "
                    />
                  </div>

                  <InputBasic
                    id="description"
                    type="text"
                    placeholder="Enter course description..."
                    md="6"
                    label="Course Description"
                    isRequired={true}
                    textArea="textarea"
                    rules={{
                      function: register,
                      name: "description",
                      rules: {
                        required: "Course Description is required",
                        isMaxLength: (value: string) =>
                                value.length <= 1000 ||
                                'Less than 1000 characters',
                      },
                      errors,
                    }}
                    className="d-flex flex-column"
                  />
                </Row>

                <Row className="mb-3 border-bottom pb-5">
                  <div className="title-form">
                    <h2>Course Video</h2>
                  </div>
                  <SectionVideos control={control} watch={watch} register={register} errors={errors} />
                </Row>

                <div className="d-flex gap-3 justify-content-end">
                  <ButtonCancel
                    type="button"
                    onClick={() => handleNavigate("back")}
                  >
                    Back
                  </ButtonCancel>
                  <ButtonSubmit type={loading ? "button" : "submit"}>
                    {loading ? (
                      <Bars
                        height="30"
                        width="30"
                        color="#fff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      "Create Course"
                    )}
                  </ButtonSubmit>
                </div>
              </form>
            </FormProvider>
          </div>
        </ContainerCenter>
      </ContainerCustom>
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0;
`;

const ContainerCenter = styled.div`
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .form-content {
    width: 100%;

    .title-form {
      margin-bottom: 30px;
      h2 {
        color: #303030;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const FormLabel = styled(Form.Label)`
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  .req {
    color: #fb6056;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;

const ButtonSubmit = styled.button`
  display: flex;
  width: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

`;

const ButtonCancel = styled.button`
  display: flex;
  width: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #0f999b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #0f999b;

`;