/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/promise-function-async */
import Cookies from "js-cookie";

import { axiosClient } from "services/GatewayService";
import { AppDispatch } from "redux/store";
import { encryptData } from "utility/Utils";

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_REQUEST,
} from "../reducers";

/**
 * Function login user
 *
 * @param {FormData} formData
 * @return {Promise<void>}
 */
export const login = (formData: FormData): any => {
  return async (dispatch: AppDispatch): Promise<boolean> => {
    dispatch({ type: LOGIN_REQUEST });
    try {
      let url = "/api/auth/login";
      if ((formData.get("type") as any) === "admin") {
        url = "/api/admin/auth/login";
      }

      const res = await axiosClient.post(url, formData);

      if ((formData.get("remember") as any) === "false") {
        Cookies.set("user", encryptData(JSON.stringify(res.data.data)));
        Cookies.set(
          "role",
          encryptData(JSON.stringify(res.data.data.user.role_id))
        );
        if (res.data.data.user.role_id === 1) {
          Cookies.set("auth_1", encryptData(res.data.data.token));
          setTimeout(() => {
            window.location.href = "/admin/course";
          }, 1000);
        }

        if (res.data.data.user.role_id === 2) {
          Cookies.set("auth_2", encryptData(res.data.data.token));
          setTimeout(() => {
            window.location.href = "/course";
          }, 1000);
        }
      }

      if ((formData.get("remember") as any) === "true") {
        Cookies.set("user", encryptData(JSON.stringify(res.data.data)), {
          expires: 30,
        });
        Cookies.set(
          "role",
          encryptData(JSON.stringify(res.data.data.user.role_id)), {
            expires: 30,
          }
        );

        if (res.data.data.user.role_id === 1) {
          Cookies.set("auth_1", encryptData(res.data.data.token), {
            expires: 30
          });
          window.location.href = "/admin/course";
        } 
        if (res.data.data.user.role_id === 2) {
          Cookies.set("auth_2", encryptData(res.data.data.token), {
            expires: 30
          });
          window.location.href = "/course";
        }
      }

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });

      return true;
    } catch (error: any) {
      dispatch({
        type: LOGIN_FAIL,
        payload: error?.response?.data?.message,
      });
      return false;
    }
  };
};

/**
 * Function get csrf token
 *
 * @return {Promise<void>}
 */
export const getCSRF = (): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: LOGIN_REQUEST });
    try {
      // get csrf token
      await axiosClient.get("sanctum/csrf-cookie");
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
};

/**
 * Function get token forgot password
 *
 */
export const forgotPassword = async (payload: any) => {
  try {
    const res = await axiosClient.post("/api/auth/send-forgot-password", {
      ...payload,
    });
    return res.data;
  } catch (error: any) {
    return false;
  }
};

/**
 * Function get change password
 *
 */
export const changePassword = async (payload: any) => {
  try {
    // get csrf token
    const res = await axiosClient.post("/api/auth/change-password", {
      ...payload,
    });
    return res.data;
  } catch (error: any) {
    return false;
  }
};

/**
 * Function get change password admin
 *
 */
export const changePasswordAdmin = async (payload: any) => {
  try {
    // get csrf token
    const res = await axiosClient.post("/api/admin/auth/change-password", {
      ...payload,
    });
    return res.data;
  } catch (error: any) {
    return false;
  }
};

/**
 * Function logout
 *
 * @return {Promise<void>}
 */
export const logout = (): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: LOGOUT_REQUEST });

    try {
      if (window.location.pathname.includes('admin')) {
        const res = await axiosClient.delete("/api/admin/auth/logout");

        Cookies.remove("user");
        Cookies.remove("role");
        Cookies.remove("auth_1");
        Cookies.remove("auth_2");
  
  
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: res.data,
        });
        window.location.href = '/admin/login';
      }
      if (!window.location.pathname.includes('admin')) {
        const res = await axiosClient.delete("/api/auth/logout");

        Cookies.remove("auth_2");
  
  
        dispatch({
          type: LOGOUT_SUCCESS,
          payload: res.data,
        });
        window.location.href = '/login';
      }
    } catch (error: any) {
      Cookies.remove("user");
      Cookies.remove("role");
      Cookies.remove("auth_1");
      Cookies.remove("auth_2");

      dispatch({
        type: LOGIN_FAIL,
        payload: error?.response?.data?.message,
      });

      if (window.location.pathname.includes('admin')) {
        window.location.href = '/admin/login';
      }
      if (!window.location.pathname.includes('admin')) {
        window.location.href = '/login';
      }
    }
  };
};
