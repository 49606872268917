export const GET_ALL_CONSULTATIONS_REQUEST = "GET_ALL_CONSULTATIONS_REQUEST";
export const GET_ALL_CONSULTATIONS_SUCCESS = "GET_ALL_CONSULTATIONS_SUCCESS";
export const GET_ALL_CONSULTATIONS_FAIL = "GET_ALL_CONSULTATIONS_FAIL";

export const GET_DETAILS_CONSULTATIONS_REQUEST =
  "GET_DETAILS_CONSULTATIONS_REQUEST";
export const GET_DETAILS_CONSULTATIONS_SUCCESS =
  "GET_DETAILS_CONSULTATIONS_SUCCESS";
export const GET_DETAILS_CONSULTATIONS_FAIL = "GET_DETAILS_CONSULTATIONS_FAIL";
export const GET_DETAILS_CONVERTATION_REQUEST =
  "GET_DETAILS_CONVERTATION_REQUEST";
export const GET_DETAILS_CONVERTATION_SUCCESS =
  "GET_DETAILS_CONVERTATION_SUCCESS";
export const GET_DETAILS_CONVERTATION_FAIL = "GET_DETAILS_CONVERTATION_FAIL";

export const RESET_CONVERTATION_SUCCESS = "RESET_CONVERTATION_SUCCESS";

export const GET_ALL_CONVERTATIONS_ROOM_REQUEST =
  "GET_ALL_CONVERTATIONS_ROOM_REQUEST";
export const GET_ALL_CONVERTATIONS_ROOM_SUCCESS =
  "GET_ALL_CONVERTATIONS_ROOM_SUCCESS";
export const GET_ALL_CONVERTATIONS_ROOM_FAIL =
  "GET_ALL_CONVERTATIONS_ROOM_FAIL";

export const ConsultationsReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_ALL_CONSULTATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_CONSULTATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_ALL_CONSULTATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};

export const DetailsConvertationReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_DETAILS_CONVERTATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_DETAILS_CONVERTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_DETAILS_CONVERTATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    case RESET_CONVERTATION_SUCCESS:
      return {
        loading: false,
        response: undefined,
      };

    default:
      return state;
  }
};

export const DetailsConsultationsReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_DETAILS_CONSULTATIONS_REQUEST:
      return {
        response: undefined,
        loading: true,
      };

    case GET_DETAILS_CONSULTATIONS_SUCCESS:
      return {
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_DETAILS_CONSULTATIONS_FAIL:
      return {
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};

export const AllConvertationRoomReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_ALL_CONVERTATIONS_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_CONVERTATIONS_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_ALL_CONVERTATIONS_ROOM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};
