import { axiosClient } from "services/GatewayService";
import { AppDispatch } from "redux/store";

import {
  GET_ALL_STUDENTS_REQUEST,
  GET_ALL_STUDENTS_SUCCESS,
  GET_ALL_STUDENTS_FAIL,
  GET_DETAILS_STUDENT_REQUEST,
  GET_DETAILS_STUDENT_SUCCESS,
  GET_DETAILS_STUDENT_FAIL,
} from "../reducers";

/**
 * Function to get all mentor
 *
 */
export const getAllStudents = (params: {
  limit?: number;
  with_pagination?: boolean;
  search?: string;
  page: number;
}): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: GET_ALL_STUDENTS_REQUEST });
    try {
      const res = await axiosClient.get("api/admin/students", {
        params: {
          ...params,
        },
      });

      dispatch({
        type: GET_ALL_STUDENTS_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_ALL_STUDENTS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};

/**
 * Function to get details student
 *
 */
export const getDetailsStudent = (params: {
  id: string;
}): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: GET_DETAILS_STUDENT_REQUEST });
    try {
      const res = await axiosClient.get("api/admin/students/view", {
        params: {
          user_id: params.id,
        },
      });

      dispatch({
        type: GET_DETAILS_STUDENT_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_DETAILS_STUDENT_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};

/**
 * Function to create mentor
 *
 * @param formData FormData - data of project
 */
export const createStudent = async (formData: FormData) => {
  try {
    const res = await axiosClient.post("api/admin/students", formData);
    const result = {
      message: res.data.message,
      errors: null,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.message,
      errors: error.response.data.errors,
      status: false,
    };
    return res;
  }
};

/**
 * Function to upload certificate
 *
 * @param formData FormData - data of certificate
 */
export const uploadCertStudent = async (formData: FormData) => {
  try {
    const res = await axiosClient.post("api/admin/students/manual-certificates", formData);
    const result = {
      message: res.data,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.message,
      status: false,
    };
    return res;
  }
};

/**
 * Function to upload manual payment
 *
 * @param formData FormData - data of project
 */
export const uploadManualPayment = async (formData: FormData) => {
  try {
    const res = await axiosClient.post("api/admin/students/manual-payment", formData);
    const result = {
      message: res.data,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.message,
      status: false,
    };
    return res;
  }
};

/**
 * Function to delete course
 *
 * @param formData FormData - data of course student
 */
export const deleteStudent = async (params: { id: string }) => {
  try {
    const res = await axiosClient.delete("api/admin/students", {
      params: {
        user_id: params.id
      }
    });
    const result = {
      message: res.data,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.message,
      status: false,
    };
    return res;
  }
};

/**
 * Function to delete consultation
 *
 * @param formData FormData - data of consultation student
 */
export const deleteConsultationStudent = async (params: any) => {
  try {
    const res = await axiosClient.delete("api/admin/students/remove-subscription", {
      params: {
        ...params
      }
    });
    const result = {
      message: res.data,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.message,
      status: false,
    };
    return res;
  }
};

/**
 * Function to login as student
 *
 * @param formData FormData - data of student
 */
export const loginAsStudent = async (formData: FormData) => {
  try {
    const res = await axiosClient.post("/api/admin/auth/login-as-user", formData);
    const result = {
      message: res.data,
      token: res.data.data.token,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.message,
      token: null,
      status: false,
    };
    return res;
  }
};