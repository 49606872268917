import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import { convertSecondstoTime } from "utility/Utils";
import {
  updateWatched,
  getCoursesDetails,
} from "pages/CourseDetails/redux/actions";
import InputCheckbox from "./InputChecked";
import Play from "assets/icons/play.svg";
import PlayWhite from "assets/icons/icon-play-white.svg";

type IconProps = {
  id: number;
  open: number;
  data: CountContent;
};

type PropsAccordion = {
  data: DataContent[];
};

type DataContent = {
  id: string;
  name: string;
  contents: Contents[];
  count_contents: CountContent;
};
type Contents = {
  authenticated_user_watched: any;
  description: string;
  duration: number;
  id: string;
  is_user_watched: boolean;
  name: string;
  play_count: number;
  video_url: string;
};

type CountContent = {
  count_all_contents: number;
  count_watched_contents: number;
};

function Icon({ id, open, data }: IconProps) {
  return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className={`${id === open ? "rotate-180" : ""} w-5 transition-transform`}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
        />
      </svg>
  );
}

// function Icon({ id, open, data }: IconProps) {
//   return id === open ? (
//     <div className="d-flex">
//       <div className="content-sum me-4">
//         <span>{data.count_watched_contents}</span>
//         <span>/</span>
//         <span>{data.count_all_contents}</span>
//       </div>

//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         fill="none"
//         viewBox="0 0 24 24"
//         strokeWidth={2}
//         stroke="currentColor"
//         className={`rotate-180 w-5 transition-transform`}
//       >
//         <path
//           strokeLinecap="round"
//           strokeLinejoin="round"
//           d="M19.5 8.25l-7.5 7.5-7.5-7.5"
//         />
//       </svg>
//     </div>
//   ) : (
//     <div className="d-flex">
//       <div className="content-sum me-4">
//         <span>{data.count_watched_contents}</span>
//         <span>/</span>
//         <span>{data.count_all_contents}</span>
//       </div>
//       <svg
//         xmlns="http://www.w3.org/2000/svg"
//         fill="none"
//         viewBox="0 0 24 24"
//         strokeWidth={2}
//         stroke="currentColor"
//         className={`h-5 transition-transform`}
//       >
//         <path
//           strokeLinecap="round"
//           strokeLinejoin="round"
//           d="M19.5 8.25l-7.5 7.5-7.5-7.5"
//         />
//       </svg>
//     </div>
//   );
// }

export default function AccordionCustom(props: PropsAccordion) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { namecourse, sections, contents } = useParams();
  const [open, setOpen] = React.useState(0);

  useEffect(() => {
    if (sections) {
      setOpen(parseInt(sections)+1);
    }
  }, [sections]);

  const handleOpen = (value: number) =>
    setOpen(open === value ? 0 : value);

  const handleNavigate = (index_bab: number, index_sub_bab: number) => {
    navigate(`/course/${namecourse}/${index_bab}/lessons/${index_sub_bab}`);
  };

  const handleOnchangeChecked = async (checked: boolean, id: string) => {
    const res = await updateWatched(id, checked);
    if (res && namecourse) {
      dispatch(getCoursesDetails(namecourse));
    }
  };

  const handleActive = (
    index_bab: number,
    index_sub_bab: number,
    key: string
  ) => {
    if (sections && contents) {
      if (
        index_bab === parseInt(sections) &&
        index_sub_bab === parseInt(contents)
      ) {
        return key;
      }
    }
  };

  const handleActiveCheck = (
    index_bab: number,
    index_sub_bab: number
  ) => {
    if (sections && contents) {
      if (
        index_bab === parseInt(sections) &&
        index_sub_bab === parseInt(contents)
      ) {
        return true;
      }
    }
  };

  return (
    <>
      {props.data.map((d, i) => (
        <Accordion
          key={i}
          open={open === i+1}
          icon={<Icon id={i+1} open={open} data={d.count_contents} />}
          className="my-3 rounded-lg border-0 border-blue-gray-100 px-4 bg-white"
        >
          <AccordionHeaderCustom
            onClick={() => handleOpen(i+1)}
            className="border-b-0"
          >
            <h3>{d.name}</h3>
          </AccordionHeaderCustom>
          <AccordionBodyCustom>
            <ul>
              {d.contents.map((item, index) => (
                <li
                  key={item.id}
                  className={handleActive(i, index, "playlist-content-active")}
                >
                  <div
                    className={`playlist-content cursor-pointer`}
                    onClick={() => handleNavigate(i, index)}
                  >
                    <div className="content-list-left">
                      {handleActiveCheck(i, index ) ? (
                        <img src={PlayWhite} alt="play" className="play-icon" />
                      ) : (
                        <img src={Play} alt="play" className="play-icon" />
                      )}
                      <h4>{item.name}</h4>
                    </div>
                    <div className="content-list-right">
                      <div className="content-time-list">
                        <span>{convertSecondstoTime(item.duration)}</span>
                        {/* <AiOutlineLock size={21} /> */}
                      </div>
                    </div>
                  </div>
                  <div className="content-input">
                    <InputCheckbox
                      key={item.id}
                      id={item.id}
                      check={item.is_user_watched}
                      onChange={handleOnchangeChecked}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </AccordionBodyCustom>
        </Accordion>
      ))}
    </>
  );
}

const AccordionHeaderCustom = styled(AccordionHeader)`
  display: flex;
  justify-content: space-beetwen;
  h3 {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px; 
    color: #303030;
  }
`;

const AccordionBodyCustom = styled(AccordionBody)`
  padding: 0px !important;
  ul {
    padding-left: 0 !important;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid #e8e8e8;

      .playlist-content {
        display: flex;
        justify-content: space-between !important;
        align-items: center;
        width: 100%;
        padding: 0px 10px 0px 0px;

        .content-list-left {
          display: flex;
          align-items: center;
          gap: 5px;

          .play-icon {
            width: 20px;
            height: 20px;
            object-fit: cover;
          }

          h4 {
            color: #303030;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 27px;
            margin-bottom: 0px;
          }
        }

        .content-list-right {
          .content-time-list {
            display: flex;
            align-items: center;
            gap: 10px;

            color: #303030;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 27px;
          }
        }
      }

      &.playlist-content-active {
        display: flex;
        align-items: center;
        background-color: #0F999B;
        color: #fff;
        border-radius: 20px 20px 20px 20px;

        .content-list-left {
          display: flex;
          align-items: center;
          
          h4 {
            color: #fff;
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            margin-bottom: 0px;
          }
        }

        .content-list-right {
          .content-time-list {
            color: #fff;
          }
        }
      }

      .content-input {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .checkbox-input {
          cursor: pointer;
        }
      }
    }
  }
`;
