import { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Container, Form, InputGroup, Modal } from "react-bootstrap";
import { BiLogoLinkedin } from "react-icons/bi";
import { BsFileEarmarkArrowDown, BsCalendar2 } from "react-icons/bs";
import { AiOutlineLink, AiOutlineShareAlt } from "react-icons/ai";

import { toast } from "react-toastify";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "next-share";
import { TailSpin } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";

import { getMyCertificates } from "./redux/actions";
import { getCredential } from "utility/Utils";
import { axiosClient } from "services/GatewayService";
import { getAllClasses } from "pages/Home/redux/actions";

import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import Layout from "components/Organism/Layouts";
import Pagination from "components/Atoms/Paginations";
import EllipseDanger from "assets/icons/ellipse-danger.svg";
import EllipseSuccess from "assets/icons/ellipse-success.svg";

type DataType = {
  id: string;
  name: string;
  image_url: string;
  my_certificate: {
    completed: number;
    completed_description: string;
    id: string;
    month: string;
    year: string;
  };
};

type DataButton = {
  label: string;
  value: string | null;
};

export default function MyCertificates(): JSX.Element {
  const dispatch = useDispatch();

  const [idCourse, setIdCourse] = useState("");
  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState("");
  const [active, setActive] = useState<string|null>(null);
  const [classes, setClasses] = useState<any>();
  const [params, setParams] = useState({
    with_pagination: true,
    limit: "3",
    page: 1,
  });

  const certificatesData = useSelector(
    (state: RootState) => state.certificates
  );
  const allClasses = useSelector((state: RootState) => state.classess_courses);

  useEffect(() => {
    const user = getCredential() as any;
    if (user !== null) {
      setUserId(user.id);
    }
  }, []);

  useEffect(() => {
    dispatch(getAllClasses({ limit: 9999 }))
    dispatch(getMyCertificates(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!allClasses.loading && allClasses.status === 200) {
      const temp: DataButton[] = [
        {
          label: "All Certificates",
          value: null
        }
      ];
      // eslint-disable-next-line array-callback-return
      allClasses.response.data.map((d: string) => {
        temp.push({
          label: d,
          value: d,
        })
      })
      setClasses(temp);
    }
  }, [allClasses])


  // const handleOpen = (value: string) => setOpen(open === value ? "" : value);

  // const handleCopy = (id: string) => {
  //   navigator.clipboard.writeText(
  //     `${window.location.origin}/certificate-proof/${userId}/${id}`
  //   );
  //   toast.success(`Link copied to clipboard`, {
  //     position: "bottom-center",
  //     autoClose: 5000,
  //     hideProgressBar: true,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "light",
  //   });
  // };

  const handleCopyModal = () => {
    navigator.clipboard.writeText(`${idCourse}`);
    toast.success(`Link copied to clipboard`, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  type PropsHandlePage = {
    selected: number;
  };

  const handlePage = async ({ selected }: PropsHandlePage) => {
    setParams((prev) => ({
      ...prev,
      page: selected + 1,
    }));

    const payload = {
      ...params,
      page: selected + 1,
    };

    await dispatch(getMyCertificates(payload));
  };

  const handleShareLinkedin = (title: string, id: string) => {
    window.open(
      `https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=${title}&organizationName=ExcloDemy&certUrl=${window.location.origin}/certificate-proof/${userId}/${id}
    `,
      "blank"
    );
  };

  const handleShare = (id: string) => {
    setIdCourse(`${window.location.origin}/certificate-proof/${userId}/${id}`);
    setShow(!show);
  };

  const handleCloseShare = () => {
    setIdCourse("");
    setShow(!show);
  };

  const handleDownload = async (id: string) => {
    try {
      const response = await axiosClient.get(
        `/api/my-certificates/download?course_id=${id}`,
        { responseType: "blob" }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "certificate.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error: any) {
      
    }
  };

  const handleClass = async (value: string | null) => {
    setParams((prev) => ({
      ...prev,
      class: value,
    }));
    setActive(value);

    const payload = {
      ...params,
      class: value,
    };
    await dispatch(getMyCertificates(payload));
  };


  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Course" />

        <ContainerCenter>
          <div className="center-content">
            <div className="top-title">
              <h3>Your Certificate Access</h3>
              <h2>Certificate List</h2>
            </div>

            <div className="content-button-group mt-3">
            {!allClasses.loading && classes && classes.map((d: DataButton, i: number) => (
                <TabCustom
                  key={i}
                  className={`${active === d.value ? "active" : ""} text-capitalize`}
                  onClick={() => handleClass(d.value)}
                >
                  {d.label}
                </TabCustom>
              ))}
            </div>

            <div className="course-list row d-flex justify-content-between my-4">
              {certificatesData.loading && (
                <div className="h-100 d-flex justify-center align-items-center">
                  <TailSpin
                    height="200"
                    width="200"
                    color="#0F999B"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              )}
              {!certificatesData.loading &&
                certificatesData.status === 200 &&
                certificatesData.response.data.map(
                  (data: DataType, i: number) => (
                    <div className="col-lg-12 card d-flex mb-5 border-0">
                      <div className="row">
                        <div className="col-lg-2 content-img-left">
                          <img src={data.image_url} alt="img" />
                        </div>
                        <div className="col-lg-9 content-card">
                          <h4>{data.name}</h4>
                          <div className="time">
                            <BsCalendar2 size={18} />
                            <small>
                              {data.my_certificate.month}{" "}
                              {data.my_certificate.year}
                            </small>
                          </div>
                          <div className="status">
                            <img
                              src={
                                !data.my_certificate.completed
                                  ? EllipseDanger
                                  : EllipseSuccess
                              }
                              alt="ellipse"
                            />
                            <small>
                              {!data.my_certificate.completed
                                ? "Certificate not Completed"
                                : "Certificate Completed"}
                            </small>
                          </div>
                          <div className="btn-content-view">
                            <ButtonDownload
                              disabled={
                                !data.my_certificate.completed
                              }
                              className={`${
                                !data.my_certificate.completed
                                  ? "disable"
                                  : ""
                              }`}
                              onClick={() => handleDownload(data.id)}
                            >
                              <BsFileEarmarkArrowDown size={18} />
                              <span>Download PDF</span>
                            </ButtonDownload>
                            <ButtonLinkedIn
                              disabled={
                                !data.my_certificate.completed
                              }
                              className={`${
                                !data.my_certificate.completed
                                  ? "disable"
                                  : ""
                              }`}
                              onClick={() =>
                                handleShareLinkedin(data.name, data.id)
                              }
                            >
                              <BiLogoLinkedin size={32} />
                              <span>Add To Profile</span>
                            </ButtonLinkedIn>
                            <ButtonDownload
                              onClick={() => handleShare(data.id)}
                              disabled={
                                !data.my_certificate.completed
                              }
                              className={`${
                                !data.my_certificate.completed
                                  ? "disable"
                                  : ""
                              }`}
                            >
                              <AiOutlineShareAlt size={21} />
                              <span>Share</span>
                            </ButtonDownload>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              {!certificatesData.loading && certificatesData.status === 200 && (
                <Pagination
                  pageCount={certificatesData.response.meta.last_page}
                  forcePage={certificatesData.response.meta.current_page}
                  onPageChange={handlePage}
                />
              )}
            </div>
          </div>
        </ContainerCenter>

        <Modal show={show} onHide={handleCloseShare} centered>
          <Modal.Header closeButton className="border-bottom-0">
            <Modal.Title>Share My Certificate</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column h-75 gap-3">
              <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Recipient's username"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  defaultValue={idCourse}
                />
                <ButtonCopyModal onClick={handleCopyModal}>
                  <AiOutlineLink size={18} />
                  <span>Copy Link</span>
                </ButtonCopyModal>
              </InputGroup>
            </div>
            <ContentShare>
              <div className="title-share">Shared On:</div>
              <div className="list-social">
                <FacebookShareButton url={idCourse} blankTarget>
                  <FacebookIcon size={62} round />
                </FacebookShareButton>
                <LinkedinShareButton url={idCourse} blankTarget>
                  <LinkedinIcon size={62} round />
                </LinkedinShareButton>
                <TwitterShareButton url={idCourse} blankTarget>
                  <TwitterIcon size={62} round />
                </TwitterShareButton>
              </div>
            </ContentShare>
          </Modal.Body>
          <Modal.Footer className="border-top-0">
            <ButtonClose onClick={handleCloseShare}>Close</ButtonClose>
          </Modal.Footer>
        </Modal>
      </ContainerCustom>
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0;
`;

const ContainerCenter = styled.div`
  padding: 30px 40px;
  position: relative;
  min-height: 90vh;

  .center-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;

    .top-title {
      display: flex;
      flex-direction: column;
      color: #303030;
      // font-family: Montserrat;

      h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }

      h2 {
        font-size: 27px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
      }
    }

    .filter-content {
      display: flex;
      align-items: center;
      justify-content: end;
      padding: 1.5rem 0;
    }

    .content-button-group {
      width: 1550px;
      display: flex;
      gap: 0.5rem;
      overflow: auto;
      padding-right: 30px;

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }

    .course-list {
      padding-left: 30px;
      .card {
        padding: 40px 0px;
        border-bottom: 1px solid #dbdbdb !important;

        .row {
          gap: 20px;

          .content-img-left {
            border-radius: 14px;
            background: #3fadaf;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 100%;
              // height: 170px;
              // object-fit: cover;
            }
          }

          .content-card {
            display: flex;
            flex-direction: column;
            gap: 10px;

            h4 {
              color: #303030;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 30px;
            }

            span {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px;
            }

            .time {
              display: flex;
              align-items: center;

              small {
                margin-left: 6px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
              }
            }

            .status {
              display: flex;
              align-items: center;
              gap: 5px;
            }

            .btn-content-view {
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const ButtonDownload = styled.button`
  display: flex;
  align-items: center;
  background-color: #0f999b;
  padding: 18px 20px;
  border-radius: 12px;
  color: #fff !important;
  gap: 0.2rem;

  span {
    margin-left: 0.15rem;
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
  }

  &.disable {
    background-color: rgb(174, 186, 202);
    color: #fff;
  }
`;

const ButtonLinkedIn = styled.button`
  display: flex;
  align-items: center;
  background: #0077b5;
  border-color: transparent;
  border-radius: 12px;
  padding: 18px 20px;
  color: #fff;
  gap: 0.2rem;

  span {
    font-weight: 600;
    font-size: 14px;
    text-transform: capitalize;
    padding: 0;
    margin: 0;
  }

  &.disable {
    background-color: rgb(174, 186, 202);
    color: #fff;
  }
`;

const ButtonClose = styled.button`
  border: 1px solid #0f999b !important;
  color: #0f999b;
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 12px 20px !important;

  span {
    margin-left: 0.15rem;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
`;

const ButtonCopyModal = styled(Button)`
  border: 1px solid #0f999b !important;
  color: #fff;
  background-color: #0f999b;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 12px 20px !important;

  span {
    margin-left: 0.15rem;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }

  &:hover {
    color: #fff;
    background-color: #0f999b;
  }

  &:focus {
    color: #fff !important;
    background-color: #0f999b !important;
  }
`;

const ContentShare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  .title-share {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #303030;
    overflow-wrap: anywhere;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-transform: capitalize;
    margin-right: 8px;
  }

  .list-social {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

const TabCustom = styled.button`
  display: flex;
  min-width: 200px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #e0e0e0;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  &.active {
    background: #0f999b;
  }
`;
