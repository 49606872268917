import { useFieldArray } from "react-hook-form";
import { FaRegTrashAlt } from "react-icons/fa";

import InputBasic from "components/Atoms/Inputs/ControllerInput";
import InputFile from "components/Atoms/Inputs/InputFile";
import styled from "styled-components";

export default function ListVideos(params: any): JSX.Element {
  const { control, watch, register, errors, index } = params;
  const { fields, append, remove } = useFieldArray({
    control,
    name: `sections.${index}.contents`,
  });

  const handleAdd = () => {
    append({
        name: "",
        video: ""
    })
  }

  const handleRemove = (index: number) => {
    remove(index);
  };

  return (
  <div className="col-12 mt-3">
    {fields.map((item, i) => (
      <div key={item.id} className="row mx-0 mb-3">
        <InputBasic
          id="video_title"
          type="text"
          placeholder="Enter video title..."
          md="5"
          label="Video Title"
          name={`sections.${index}.contents[${i}.name`}
          rules={{
            name: `sections.${index}.contents[${i}.name`,
            rules: {
              required: "Video Title is required",
            },
            errors,
          }}
        />
        
        <div className="col-lg-5">
          <InputFile
            accepted=".mp4,.mkv"
            name={`sections.${index}.contents[${i}.video`}
            label="Upload Video"
            watch={watch}
            placeholder="Upload video (MP4, MKV)"
            rules={{
              function: register,
              name: `sections.${index}.contents[${i}.video`,
              rules: {
                required: "File video is required",
              },
              errors,
            }}
          />
        </div>
        <div className="col-lg-2 pt-2 d-flex gap-2 align-items-center text-red-500">
            <FaRegTrashAlt size={20} style={{ cursor: "pointer" }} onClick={() => handleRemove(i)} />
        </div>
      </div>
    ))}
    <div className="">
        <CustomButtonAdd type="button" onClick={handleAdd}>+ List Video</CustomButtonAdd>
    </div>
  </div>
  )
}


const CustomButtonAdd = styled.button`
  border: none;
  background: transparent;
  color: #4070ff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
`;
