import { axiosClient } from 'services/GatewayService';
import { AppDispatch } from 'redux/store';

import {
  GET_MY_ACCOUNT_REQUEST,
  GET_MY_ACCOUNT_SUCCESS,
  GET_MY_ACCOUNT_FAIL
} from "../reducers/index";
import { encryptData, getAuth } from 'utility/Utils';
import Cookies from 'js-cookie';
import axios from 'axios';


const apiUrl = process.env.REACT_APP_BASE_URL;

/**
 * Function update data account
 *
 * @return {Promise<void>}
 */
export const updateAccount = async (params: any) => {
  try {
    let url = '/api/my-account/update';

    if (window.location.pathname.includes("admin")) {
      url = 'api/admin/my-account/update'
    }
    
    const res = await axiosClient.post(url, params);

    const result = {
      message: res.data.message,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.message,
      status: false,
    };
    return res;
  }
};

/**
 * Function update password
 *
 * @return {Promise<void>}
 */
export const updatePassword = async (params: any) => {
  try {
    let url = 'api/my-account/update-password';

    if (window.location.pathname.includes("admin")) {
      url = 'api/admin/my-account/update-password'
    }
    
    const res = await axiosClient.post(url, params);

    const result = {
      message: res.data.message,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.message,
      status: false,
    };
    return res;
  }
};

/**
 * Function to get my account
 *
 * @return Promise<void>
 */
export const getMyAccount = (): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
      dispatch({ type: GET_MY_ACCOUNT_REQUEST });
      try {
          let url = '/api/my-account';
          const token = getAuth();
          
          if (window.location.pathname.includes("admin")) {
            url = '/api/admin/my-account'
          }
          
          const res = await axios.get(`${apiUrl+url}`,{
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            },
          })

          Cookies.set("user", encryptData(JSON.stringify(res.data.data)));

          dispatch({
              type: GET_MY_ACCOUNT_SUCCESS,
              payload: res.data,
          });

      } catch (error: any) {
          dispatch({
              type: GET_MY_ACCOUNT_FAIL,
              payload: error?.response?.data?.message,
          });
      }
  };
};
