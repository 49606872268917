import { useState } from "react";
import styled from "styled-components";
import { Alert } from "react-bootstrap";
import { Bars } from "react-loader-spinner";
import { useForm, FormProvider } from "react-hook-form";
import { AiOutlineLock } from "react-icons/ai";

import { updatePassword } from "../redux/actions";
import InputCustom from "components/Atoms/Inputs/InputCustom";

export default function ChangePasswordComponent(): JSX.Element {
  
  const methods = useForm({
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods;

  const [loadingPwd, setLoadingPwd] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [successPwd, setSuccessPwd] = useState(false);
  const [messageSuccessPwd, setMessageSuccessPwd] = useState("");

  const onSubmitPwd = async (event: any) => {
    setLoadingPwd(true);
    let payload = event;
    const res = await updatePassword(payload);
    if (res.status) {
      setMessageSuccessPwd(res.message);
      setLoadingPwd(false);
      setSuccessPwd(true);
      setShowPwd(true);
    }
    if (!res.status) {
      setLoadingPwd(false);
      setSuccessPwd(false);
      setShowPwd(true);
      setMessageSuccessPwd(res.message);
    }
    setLoadingPwd(false);
  };

  return (
    <div className="w-50">
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmitPwd)}>
      <div className="mb-3">
        <InputCustom
          id="old_password"
          type="password"
          placeholder="Enter default password..."
          label="Old Password"
          isRequired={true}
          rules={{
            function: register,
            name: "old_password",
            rules: {
              required: "Old Password is required",
            },
            errors,
          }}
        />
      </div>

      <div className="mb-3">
        <InputCustom
          id="password"
          type="password"
          placeholder="Enter default password..."
          label="Default Password"
          isRequired={true}
          rules={{
            function: register,
            name: "password",
            rules: {
              required: "Default Password is required",
              validate: {
                isMaxLength: (value: string) =>
                  value.length >= 8 ||
                  "At least 10 characters required",
                isUppercase: (value: string) =>
                  /[A-Z]/.test(value) ||
                  "An uppercase character required",
                isLowercase: (value: string) =>
                  /[a-z]/.test(value) ||
                  "A lowercase character required",
                isNumber: (value: string) =>
                  /[0-9]/.test(value) || "A number required",
                isSpecialCharacter: (value: string) =>
                  /[^a-zA-Z0-9]/.test(value) ||
                  "A special character required",
              },
            },
            errors,
          }}
        />
      </div>

      <div className="mb-3">
        <InputCustom
          id="password_confirmation"
          isRequired={true}
          type="password"
          placeholder="Re-enter password"
          label="Confirm Password"
          icon={<AiOutlineLock size={20} />}
          rules={{
            function: register,
            name: "password_confirmation",
            rules: {
              required: "Confirm Password is required",
              validate: {
                isMatch: (value: string) =>
                  value === watch("password") ||
                  "Password does not match",
              },
            },
            errors,
          }}
        />
      </div>


        {showPwd ? (
          <Alert
            variant={`${successPwd ? "success" : "danger"}`}
            onClose={() => setShowPwd(!showPwd)}
            dismissible
            className="d-flex align-items-center w-75 mb-3"
          >
            <p>{messageSuccessPwd}</p>
          </Alert>
        ) : null}

        <ButtonSubmit type={loadingPwd ? "button" : "submit"}>
          {loadingPwd ? (
            <Bars
              height="30"
              width="30"
              color="#a3cfbb"
              ariaLabel="bars-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          ) : (
            "Change Password"
          )}
        </ButtonSubmit>
      </form>
    </FormProvider>
  </div>
  );
}

const ButtonSubmit = styled.button`
  display: flex;
  minwidth: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  &:hover {
    background: transparent;
    color: #0f999b;
    border: 1px solid #0f999b;
  }
`;
