import { axiosClient } from "services/GatewayService";
import { AppDispatch } from "redux/store";

import {
  GET_ALL_MY_COURSE_REQUEST,
  GET_ALL_MY_COURSE_SUCCESS,
  GET_ALL_MY_COURSE_FAIL,
  GET_ALL_COURSE_REQUEST,
  GET_ALL_COURSE_SUCCESS,
  GET_ALL_COURSE_FAIL,
  GET_DETAILS_COURSE_REQUEST,
  GET_DETAILS_COURSE_SUCCESS,
  GET_DETAILS_COURSE_FAIL,
  GET_ALL_CLASSESS_REQUEST,
  GET_ALL_CLASSESS_SUCCESS,
  GET_ALL_CLASSESS_FAIL,
} from "../reducers";

/**
 * Function to get all classes
 *
 */
export const getAllClasses = (params: {
  limit: number;
}): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: GET_ALL_CLASSESS_REQUEST });
    try {
      const res = await axiosClient.get("/api/courses/classes", {
        params: {
          ...params,
        },
      });

      dispatch({
        type: GET_ALL_CLASSESS_SUCCESS,
        payload: res.data,
      });

    } catch (error: any) {
      dispatch({
        type: GET_ALL_CLASSESS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};

/**
 * Function to get all classes admin
 *
 */
export const getAllClassesAdmin = (): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: GET_ALL_CLASSESS_REQUEST });
    try {
      const res = await axiosClient.get("api/admin/common/classes");

      dispatch({
        type: GET_ALL_CLASSESS_SUCCESS,
        payload: res.data,
      });

    } catch (error: any) {
      dispatch({
        type: GET_ALL_CLASSESS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};

/**
 * Function to get all my courses
 *
 */
export const getMyCourses = (params: {
  limit?: string;
  with_pagination?: boolean;
  search?: string;
  page: number;
}): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: GET_ALL_MY_COURSE_REQUEST });
    try {
      const res = await axiosClient.get("/api/my-courses", {
        params: {
          ...params,
        },
      });

      dispatch({
        type: GET_ALL_MY_COURSE_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_ALL_MY_COURSE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};

/**
 * Function to get all courses
 *
 */
export const getAllCourses = (params: {
  limit?: string;
  with_pagination?: boolean;
  search?: string;
  page: number;
}): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: GET_ALL_COURSE_REQUEST });
    try {
      const res = await axiosClient.get("/api/admin/courses", {
        params: {
          ...params,
        },
      });

      dispatch({
        type: GET_ALL_COURSE_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_ALL_COURSE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};

/**
 * Function to get one courses
 *
 */
export const getOneCourse = (params: {
  id: string;
}): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: GET_DETAILS_COURSE_REQUEST });
    try {
      const res = await axiosClient.get("/api/admin/courses/view", {
        params: {
          course_id: params.id,
        },
      });

      dispatch({
        type: GET_DETAILS_COURSE_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_DETAILS_COURSE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};

/**
 * Function to create course
 *
 * @param formData FormData - data of course
 */
export const createCourse = async (formData: any) => {
  try {
    const res = await axiosClient.post("api/admin/courses", formData);
    const result = {
      message: res.data.message,
      errors: null,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.message,
      errors: error.response.data.errors,
      status: false,
    };
    return res;
  }
};

/**
 * Function to update course
 *
 * @param formData FormData - data of course
 */
export const updateCourse = async (formData: FormData) => {
  try {
    const res = await axiosClient.post("api/admin/courses/update", formData);
    const result = {
      message: res.data.message,
      errors: null,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.message,
      errors: error.response.data.errors,
      status: false,
    };
    return res;
  }
};

/**
 * Function to delete course
 *
 * @param formData FormData - data of course
 */
export const deleteCourse = async (params: { id: string }) => {
  try {
    const res = await axiosClient.delete("api/admin/courses", {
      params: {
        course_id: params.id
      }
    });
    
    const result = {
      message: res.data.message,
      status: true,
    };
    return result;
  } catch (error: any) {
    const res = {
      message: error.message,
      status: false,
    };
    return res;
  }
};
