export const GET_DETAILS_COURSE_REQUEST = "GET_DETAILS_COURSE_REQUEST";
export const GET_DETAILS_COURSE_SUCCESS = "GET_DETAILS_COURSE_SUCCESS";
export const GET_DETAILS_COURSE_FAIL = "GET_DETAILS_COURSE_FAIL";

export const VIEW_DETAILS_COURSE_REQUEST = "VIEW_DETAILS_COURSE_REQUEST";
export const VIEW_DETAILS_COURSE_SUCCESS = "VIEW_DETAILS_COURSE_SUCCESS";
export const VIEW_DETAILS_COURSE_FAIL = "VIEW_DETAILS_COURSE_FAIL";

export const courseDetailsReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_DETAILS_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_DETAILS_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_DETAILS_COURSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};

export const viewCourseDetailsReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case VIEW_DETAILS_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case VIEW_DETAILS_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case VIEW_DETAILS_COURSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};
