import styled from "styled-components";
import { Container } from "react-bootstrap";

import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import Layout from "components/Organism/Layouts";
import AdminComponent from "./Table";

import { checkRoles } from "utility/Utils";

export default function MentorManagement(): JSX.Element {
  const role = checkRoles();

  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Consultation List" />
        {role === 1 ? (
          <AdminComponent />
        ) : (
          <></>
        )}
      </ContainerCustom>
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0px !important;
`;
