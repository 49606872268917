/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { CSSObjectWithLabel } from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { getAllCourses } from "pages/Home/redux/actions";
import { createStudent } from "../redux/actions";

import InputBasic from "components/Atoms/Inputs/InputBasic";
import InputSearchSelect from "components/Atoms/Inputs/InputSearchSelect";
import InputCustom from "components/Atoms/Inputs/InputCustom";
import Layout from "components/Organism/Layouts";
import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import { Bars } from "react-loader-spinner";
import { toast } from "react-toastify";

export default function CreateStudentPage(): JSX.Element {
  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [courseLists, setCourseLists] = useState<any[]>([]);

  const data_course = useSelector((state: any) => state.courses)

  useEffect(() => {
    dispatch(getAllCourses({
      limit: '9999',
      with_pagination: false,
      page: 1,
    }))
  }, [dispatch]);

  useEffect(() => {
    if (!data_course.loading && data_course.status === 200) {
      handleGetData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_course])

  const handleGetData = () => {
    const temp: any[] = [];
    data_course.response.data.map((d: {
      id: string;
      name: string;
    // eslint-disable-next-line array-callback-return
    }) => {
      temp.push({
        label: d.name,
        value: d.id
      })
    });
    setCourseLists(temp);
  };

  const onSubmit = async (value: any) => {
    setLoading(true);
    const payload = new FormData();
    payload.append("first_name", value.first_name);
    payload.append("last_name", value.last_name);
    payload.append("course_id", value.course_id);
    payload.append("email", value.email);
    payload.append("password", value.password);
    const res = await createStudent(payload);
    if (res.status) {
      setLoading(false);
      toast.success(res.message);
      navigate("/admin/students");
    }
    if (!res.status) {
      setLoading(false);
      Object.entries(res.errors).map((d: any) => {
        let message: string = d[1][0];
        setError(d[0], { message: message }, {
          shouldFocus: true
        });
      })
    }
  };

  const handleNavigate = (key: string): void => {
    if (key === "back") {
      return navigate(-1);
    }
    navigate(key);
  };

  const basicStyles = {
    control: (
      baseStyles: CSSObjectWithLabel,
      state: { isFocused: boolean }
    ) => ({
      ...baseStyles,
      borderRadius: "10px",
      boxShadow: "none",
      padding: "0 10px",
      margin: 0,
    }),
    valueContainer: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      padding: "10px 0px",
      margin: 0,
      fontSize: "1rem",
    }),
    option: (
      baseStyles: CSSObjectWithLabel,
      state: { isFocused: boolean; isHover: boolean }
    ) => ({
      ...baseStyles,
      backgroundColor: state.isFocused || state.isHover ? "#0F999B" : "",
      color: state.isFocused ? "white" : "black",
      fontSize: "14px",
    }),
  };

  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Create New Student" />

        <ContainerCenter>
          <div className="form-content">
            <Form
              onSubmit={handleSubmit(onSubmit)}
              className="d-flex flex-column gap-3"
            >
              <Row className="mb-3 border-bottom pb-5">
                <div className="title-form">
                  <h2>General Information</h2>
                </div>
                <InputBasic
                  id="first_name"
                  type="text"
                  placeholder="Enter first name..."
                  md="6"
                  isRequired={true}
                  label="First Name"
                  outerClassName="mb-3"
                  rules={{
                    function: register,
                    name: "first_name",
                    rules: {
                      required: "Student name is required",
                    },
                    errors,
                  }}
                />
                <InputBasic
                  id="last_name"
                  type="text"
                  placeholder="Enter last name..."
                  md="6"
                  isRequired={true}
                  label="Last Name"
                  outerClassName="mb-3"
                  rules={{
                    function: register,
                    name: "last_name",
                    rules: {
                      required: "Last name is required",
                    },
                    errors,
                  }}
                />
                <div className="col-lg-6">
                  <Controller
                    control={control}
                    name="course_id"
                    rules={{
                      required: "Course is required",
                    }}
                    render={({ field: { onChange } }) => (
                      <div className="text-start mt-3">
                        <FormLabel>
                          Starter Class
                          <small className="req">*</small>
                        </FormLabel>
                        <InputSearchSelect
                          placeholder="Select Class"
                          innerClassName="mb-1 "
                          styles={basicStyles}
                          options={courseLists}
                          isMulti={false}
                          onChange={(value: {
                            value: string;
                            label: string;
                          }) => {
                            onChange(value.value);
                          }}
                        />
                      </div>
                    )}
                  />
                </div>
              </Row>

              <Row className="mb-3 border-bottom pb-5">
                <div className="title-form">
                  <h2>Login Information</h2>
                </div>
                <InputBasic
                  id="email"
                  type="text"
                  placeholder="Enter student email address..."
                  md="6"
                  isRequired={true}
                  label="Student Email Address"
                  outerClassName="mb-3"
                  rules={{
                    function: register,
                    name: "email",
                    rules: {
                      required: "Student Email is required",
                    },
                    errors,
                  }}
                />

                <div className="col-lg-6">
                  <InputCustom
                    id="password"
                    type="password"
                    placeholder="Enter default password..."
                    label="Default Password"
                    isRequired={true}
                    rules={{
                      function: register,
                      name: "password",
                      rules: {
                        required: "Default Password is required",
                        validate: {
                          isMaxLength: (value: string) =>
                            value.length >= 8 || "At least 8 characters required",
                          isUppercase: (value: string) =>
                            /[A-Z]/.test(value) || "An uppercase character required",
                          isLowercase: (value: string) =>
                            /[a-z]/.test(value) || "A lowercase character required",
                          isNumber: (value: string) =>
                            /[0-9]/.test(value) || "A number required",
                          isSpecialCharacter: (value: string) =>
                            /[^a-zA-Z0-9]/.test(value) ||
                            "A special character required",
                        },
                      },
                      errors,
                    }}
                  />
                </div>
              </Row>

              <div className="d-flex gap-3 justify-content-end">
                <ButtonCancel
                  type="button"
                  onClick={() => handleNavigate("back")}
                >
                  Back
                </ButtonCancel>
                <ButtonSubmit type={loading ? "button" : "submit"}>
                    {loading ? (
                      <Bars
                        height="30"
                        width="30"
                        color="#fff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      "Create Student"
                    )}
                  </ButtonSubmit>
              </div>
            </Form>
          </div>
        </ContainerCenter>
      </ContainerCustom>
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0;
`;

const ContainerCenter = styled.div`
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .form-content {
    width: 100%;

    .title-form {
      margin-bottom: 30px;
      h2 {
        color: #303030;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const ButtonSubmit = styled.button`
  display: flex;
  width: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  &:hover {
    background: transparent;
    color: #0f999b;
    border: 1px solid #0f999b;
  }
`;

const ButtonCancel = styled.button`
  display: flex;
  width: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #0f999b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #0f999b;

  &:hover {
    background: #0f999b;
    color: #fff;
  }
`;

const FormLabel = styled(Form.Label)`
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  .req {
    color: #fb6056;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;
