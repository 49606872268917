import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Container, Form } from "react-bootstrap";
import { AiOutlineLock } from "react-icons/ai";

import { useForm } from "react-hook-form";
import { changePassword } from "../redux/actions";
import { toast } from "react-toastify";
import InputBasic from "components/Atoms/Inputs/InputBasic";
import { RiLockPasswordLine } from "react-icons/ri";

export default function ForgotPasswordForm() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const navigate = useNavigate();
  let params = new URLSearchParams(document.location.search);
  let token = params.get("token");

  const onSubmit = async (value: any) => {
    const payload = {
      password: value.password,
      password_confirmation: value.confirm_password,
      token: token,
    };

    const res = await changePassword(payload);

    if (typeof res !== "boolean") {
      toast.success(res.message);
      navigate("/login");
    }
  };

  return (
    <ContainerCustom>
      <div className="card">
        <div className="content-top">
          <RiLockPasswordLine size={40} className="icon-lock" />
          <h2 className="title-card">Reset Password</h2>
          <span className="text-desc">
            We sent the verification code to your mail, please check your inbox
            and enter the code to the form below
          </span>
        </div>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <InputBasic
            id="password"
            type="password"
            placeholder="New Password"
            outerClassName="mb-3"
            icon={<AiOutlineLock size={20} />}
            rules={{
              function: register,
              name: "password",
              rules: {
                required: "Password is required",
                validate: {
                  isMaxLength: (value: string) =>
                    value.length >= 10 || "At least 10 characters required",
                  isUppercase: (value: string) =>
                    /[A-Z]/.test(value) || "An uppercase character required",
                  isLowercase: (value: string) =>
                    /[a-z]/.test(value) || "A lowercase character required",
                  isNumber: (value: string) =>
                    /[0-9]/.test(value) || "A number required",
                  isSpecialCharacter: (value: string) =>
                    /[^a-zA-Z0-9]/.test(value) ||
                    "A special character required",
                },
              },
              errors,
            }}
          />

          <InputBasic
            id="confirm_password"
            isRequired={true}
            type="password"
            placeholder="Re-enter password"
            icon={<AiOutlineLock size={20} />}
            rules={{
              function: register,
              name: "confirm_password",
              rules: {
                required: "Confirm Password is required",
                validate: {
                  isMatch: (value: string) =>
                    value === watch("password") || "Password does not match",
                },
              },
              errors,
            }}
          />

          <ButtonCustom type="submit">Change Password</ButtonCustom>
        </Form>
      </div>
    </ContainerCustom>
  );
}

const ContainerCustom = styled(Container)`
  width: 100wh;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    width: 400px;
    padding: 3rem 2rem;

    .content-top {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .icon-lock {
        color: #0f999b;
      }
      .title-card {
        color: #303030;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }

      .text-desc {
        color: #6e6e6e;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }
`;

const ButtonCustom = styled.button`
  width: 100%;
  background-color: #0f999b;
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  padding: 15px 20px;
  border-radius: 12px;
  margin-top: 15px;
`;
