import { axiosClient } from 'services/GatewayService';
import { AppDispatch } from 'redux/store';

import {
    GET_ALL_MY_CERTIFICATES_REQUEST,
    GET_ALL_MY_CERTIFICATES_SUCCESS,
    GET_ALL_MY_CERTIFICATES_FAIL,
    GET_DETAILS_CERTIFICATES_REQUEST,
    GET_DETAILS_CERTIFICATES_SUCCESS,
    GET_DETAILS_CERTIFICATES_FAIL
} from '../reducers';

/**
 * Function to get all my courses
 *
 * @return Promise<void>
 */
export const getMyCertificates = (params: any): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: GET_ALL_MY_CERTIFICATES_REQUEST });
        try {
            const res = await axiosClient.get('api/my-courses', {
                params: {
                    ...params
                }
            });

            dispatch({
                type: GET_ALL_MY_CERTIFICATES_SUCCESS,
                payload: res.data,
            });

        } catch (error: any) {
            dispatch({
                type: GET_ALL_MY_CERTIFICATES_FAIL,
                payload: error?.response?.data?.message,
            });
        }
    };
};

/**
 * Function to get details certificate
 *
 * @return Promise<void>
 */
export const getDetailsCertificate = (user_id: string, course_id: string): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: GET_DETAILS_CERTIFICATES_REQUEST });
        try {
            const res = await axiosClient.get(`/api/certificates/${user_id}/${course_id}`);

            dispatch({
                type: GET_DETAILS_CERTIFICATES_SUCCESS,
                payload: res.data.data,
            });

        } catch (error: any) {
            dispatch({
                type: GET_DETAILS_CERTIFICATES_FAIL,
                payload: error?.response?.data?.message,
            });
        }
    };
};
