import { axiosClient } from 'services/GatewayService';
import { AppDispatch } from 'redux/store';

import {
    GET_MY_CONSULTATIONS_REQUEST,
    GET_MY_CONSULTATIONS_SUCCESS,
    GET_MY_CONSULTATIONS_FAIL,
    GET_DETAILS_MY_CONSULTATIONS_REQUEST,
    GET_DETAILS_MY_CONSULTATIONS_SUCCESS,
    GET_DETAILS_MY_CONSULTATIONS_FAIL,
    GET_MY_CONVERTATIONS_REQUEST,
    GET_MY_CONVERTATIONS_SUCCESS,
    GET_MY_CONVERTATIONS_FAIL,
    GET_ALL_CLASS_REQUEST,
    GET_ALL_CLASS_SUCCESS,
    GET_ALL_CLASS_FAIL
} from '../reducers';

/**
 * Function to get all consultations
 *
 */
export const getMyConsultations = (params: {
    limit: number;
    with_pagination: boolean;
    page: number;
    class: string | null;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: GET_MY_CONSULTATIONS_REQUEST });
        try {
            const res = await axiosClient.get('/api/consultations/my', {
                params: {
                    ...params
                }
            });

            dispatch({
                type: GET_MY_CONSULTATIONS_SUCCESS,
                payload: res.data,
            });

        } catch (error: any) {
            dispatch({
                type: GET_MY_CONSULTATIONS_FAIL,
                payload: error?.response?.data?.message,
            });
        }
    };
};

/**
 * Function to get details consultations
 *
 */
export const getDetailsMyConsultations = (params: {
    id: string;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: GET_DETAILS_MY_CONSULTATIONS_REQUEST });
        try {
            const res = await axiosClient.get('/api/consultations/my', {
                params: {
                    ...params
                }
            });

            dispatch({
                type: GET_DETAILS_MY_CONSULTATIONS_SUCCESS,
                payload: res.data,
            });

        } catch (error: any) {
            dispatch({
                type: GET_DETAILS_MY_CONSULTATIONS_FAIL,
                payload: error?.response?.data?.message,
            });
        }
    };
};

/**
 * Function to get details convertations
 *
 */
export const getDetailsConvertation = (params: {
    id: string;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: GET_MY_CONVERTATIONS_REQUEST });
        try {
            const res = await axiosClient.get('api/consultations/conversations', {
                params: {
                    consultation_id: params.id
                }
            });

            dispatch({
                type: GET_MY_CONVERTATIONS_SUCCESS,
                payload: res.data,
            });

        } catch (error: any) {
            dispatch({
                type: GET_MY_CONVERTATIONS_FAIL,
                payload: error?.response?.data?.message,
            });
        }
    };
};

/**
 * Function to send message
 *
 * @param formData FormData - data of course
 */
export const sendMessage = async (formData: FormData) => {
    try {
      const res = await axiosClient.post("api/consultations/send-conversations", formData);
      const result = {
        message: res.data.message,
        status: true,
      };
      return result;
    } catch (error: any) {
      const res = {
        message: error.message,
        status: false,
      };
      return res;
    }
};  

/**
 * Function to get all classes
 *
 */
export const getAllClasses = (params: {
    limit?: number;
}): any => {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch({ type: GET_ALL_CLASS_REQUEST });
        try {
            const res = await axiosClient.get('/api/consultations/classes', {
                params: {
                    ...params
                }
            });

            dispatch({
                type: GET_ALL_CLASS_SUCCESS,
                payload: res.data,
            });

        } catch (error: any) {
            dispatch({
                type: GET_ALL_CLASS_FAIL,
                payload: error?.response?.data?.message,
            });
        }
    };
};