import styled from "styled-components";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { toast } from "react-toastify";

import Pagination from "components/Atoms/Paginations";
import { TableHeader } from "components/Atoms/Tables/header";
import { TableRow } from "components/Atoms/Tables/row";
import InputCustom from "components/Atoms/Inputs/InputCustom";
import ModalCustom from "components/Organism/Modal";

import { RootState } from "redux/store";
import { getAllCourses, deleteCourse } from "pages/Home/redux/actions";
import { fCurrency } from "utility/Utils";

type PropsHandlePage = {
  selected: number;
};

type DataCourse = {
  id: string;
  name: string;
  class?: string;
  description: string;
  author: Author;
  created_at: Date;
  count_videos: number;
  price_idr: Price;
};

type Author = {
  full_name: string;
};

type Price = {
  id: string;
  amount: string;
  currency_iso3: string;
};

let tableTitle = [
  "Course Name",
  "Class",
  "Created Date",
  "Created by",
  "Price",
  "Total Video",
  "Action",
];

export default function HomeAdmin(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState("")
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [datas, setDatas] = useState({});
  const [searchValue, setSearchValue] = useState("");

  const [params, setParams] = useState({
    with_pagination: true,
    limit: "10",
    page: 1,
    search: "",
  });

  const allCourses = useSelector((state: RootState) => state.courses);

  useEffect(() => {
    dispatch(getAllCourses(params));
  }, [dispatch, params]);

  useEffect(() => {
    if (!allCourses.loading && allCourses.status === 200) {
      formatData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCourses]);

  /**
   * Function to handle search with callback
   *
   * @returns void
   */
  const handleDebouncedSearch = useCallback(() => {
    setParams({
      ...params,
      search: searchValue,
    });

    const payload = {
      ...params,
      search: searchValue,
    };

    dispatch(getAllCourses(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // Function for handle debounce search
  useEffect(() => {
    const debounce = setTimeout(() => {
      handleDebouncedSearch();
    }, 500);

    return () => {
      clearTimeout(debounce);
    };
  }, [handleDebouncedSearch]);

  const formatData = () => {
    let obj = {};
    // eslint-disable-next-line array-callback-return
    allCourses.response.data.map((d: DataCourse, i: number) => {
      let oneRow = {
        id: d.id,
        name: d.name,
        class: d.class,
        created_at: moment(d.created_at).format("DD/MM/YYYY"),
        created: d.author.full_name,
        prices: fCurrency(d.price_idr.amount),
        total_video: d.count_videos,
        action: "",
      };

      obj = { ...obj, [i]: oneRow };
    });
    setDatas(obj);
  };

  const handlePage = async ({ selected }: PropsHandlePage) => {
    setParams((prev) => ({
      ...prev,
      page: selected + 1,
    }));

    const payload = {
      ...params,
      page: selected + 1,
    };
    await dispatch(getAllCourses(payload));
  };

  const handleNavigate = (key: string) => {
    navigate(`${key}`);
  };

  /**
   * Function to handle search
   *
   * @param string value
   * @returns void
   */
  const handleSearch = useCallback((value: string): void => {
    setSearchValue(value);
  }, []);

  const handleShowModal = () => setShowModal(!showModal);

  const openModal = (key: string) => {
    setSelected(key);
    handleShowModal();
  };
  
  const closeModal = (): void => {
    setSelected("");
    handleShowModal();
  };

  const confirmDelete = async () => {
    setLoading(true);
    const res = await deleteCourse({ id: selected })
    if (res.status) {
      setSuccess(true);
      setLoading(false);
      closeModal();
      dispatch(getAllCourses(params));
    }
    if (!res.status) {
      setLoading(false);
      toast.error(res.message);
    }
  }

  return (
    <ContainerCenter>
      <div className="content-search d-flex justify-content-end">
        <div className="w-25">
          <InputCustom
            id="email"
            type="text"
            placeholder="Search course..."
            icon={<IoSearchOutline size={20} />}
            onChange={(e) => {
              handleSearch?.(e.target.value);
            }}
          />
        </div>
      </div>

      {allCourses.loading && (
        <div className="h-100 d-flex justify-center align-items-center">
          <TailSpin
            height="200"
            width="200"
            color="#0F999B"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}

      {!allCourses.loading && allCourses.status === 200 && (
        <table className="table mt-4">
          <TableHeader data={tableTitle} />
          <tbody>
            {datas
              ? Object.values(datas).map((columns: any) => (
                  <TableRow
                    key={columns.id}
                    columns={columns}
                    editTitle="Edit Course"
                    editAction={() =>
                      handleNavigate(`/admin/course/edit/${columns.id}`)
                    }
                    deleteTitle="Delete Course"
                    deleteAction={() => openModal(columns.id)}
                  />
                ))
              : ""}
          </tbody>
        </table>
      )}

      {!allCourses.loading && allCourses.status === 200 && (
        <Pagination
          pageCount={allCourses.response.meta.last_page}
          forcePage={allCourses.response.meta.current_page}
          onPageChange={handlePage}
        />
      )}

      <ModalCustom
        show={showModal}
        onHide={closeModal}
        onConfirm={confirmDelete}
        loading={loading}
        type="delete"
        key_component="Course"
        title="Delete Course?"
        desc="Are you sure want to delete this course?, you will not have access to this course anymore."
      />
      
      <ModalCustom
        show={success}
        key_component="Course"
        onHide={() => setSuccess(!success)}
        type="success"
        title="Success"
        desc="Successfully delete Course"
      />
    </ContainerCenter>
  );
}

const ContainerCenter = styled.div`
  padding: 30px 40px;
  margin-bottom: 50px;
  position: relative;
  min-height: 90vh;

  .center-content {
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;
