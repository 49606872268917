import CurrencyInput from "react-currency-input-field";
import { Form, Col } from "react-bootstrap";
import styled from "styled-components";
import { Controller, useFormContext } from "react-hook-form";

interface ParamsProps {
  id?: string;
  type?: string;
  label?: string;
  className?: string;
  placeholder?: string;
  md?: string;
  icon?: JSX.Element | string;
  isRequired?: boolean;
  value?: number;
  name?: string;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  rules: {
    function: (name: any, rules: any) => any;
    name: string;
    rules: any;
    errors?: any;
  };
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  autoComplete?: string;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  format?: {
    locale: string;
    currency: string;
  };
  decimalsLimit?: number;
  prefix?: string;
  suffix?: string;
  onValueChange?: (value: number | undefined) => void;
}

export default function InputCurrency(props: ParamsProps): JSX.Element {
  const {
    id,
    placeholder,
    label,
    isRequired,
    disabled,
    rules,
    md,
    format,
    decimalsLimit,
    prefix,
    suffix,
  } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={rules.name}
      rules={rules.rules}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const checkError = !!error && !field.value;

        return (
          <Form.Group as={Col} md={md} {...props}>
            <FormLabel>
              {label}
              {isRequired && <small className="req">*</small>}
            </FormLabel>
            <Input
              id={id}
              name="input-name"
              placeholder={placeholder}
              value={field.value}
              decimalsLimit={decimalsLimit ?? 2}
              className="form-control"
              intlConfig={format}
              prefix={prefix}
              suffix={suffix}
              disabled={disabled}
              errors={checkError}
              onValueChange={(value, name) => field.onChange(value)}
              maxLength={9}
            />
            {checkError && (
              <span
                className="invalid-feedback d-block text-start"
                style={{
                  fontSize: "0.75rem",
                }}
              >
                {error.message}
              </span>
            )}
          </Form.Group>
        );
      }}
    />
  );
}

const FormLabel = styled(Form.Label)`
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  .req {
    color: #fb6056;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;

const Input = styled(CurrencyInput)<{ errors?: boolean }>`
  background: #fff;
  border-radius: 10px;
  border: 1px solid
    ${(props) => (props.errors ?? false ? "#FB6056" : "#e6e6e6")};

  padding: 11px 22px;
`;
