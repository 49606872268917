import { useFieldArray } from "react-hook-form";
import { FaRegTrashAlt } from "react-icons/fa";
import styled from "styled-components";

import InputBasic from "components/Atoms/Inputs/ControllerInput";
import ListVideos from "./listVideos";

export default function SectionVideos(params: any): JSX.Element {
  const { control, watch, register, errors } = params;
  const { fields, append, remove } = useFieldArray({
    control,
    name: "sections",
  });


  const handleAdd = () => {
    append({
        name: "",
        contents: [
            {
                name: "",
                video: ""
            }
        ]
    })
  }

  const handleRemove = (index: number) => {
    remove(index);
  };


  return (
  <div className="col-12">
    {fields.map((item, i) => (
      <div key={item.id} className="row mx-0 mb-3 border-bottom pb-3">
        <InputBasic
          id="name_section"
          type="text"
          placeholder="Enter group name..."
          md="6"
          isRequired={true}
          label="Group Name"
          name={`sections.[${i}].name`}
          rules={{
            name: `sections.[${i}].name`,
            rules: {
              required: "Name section is required",
            },
            errors,
          }}
        />
        <div className="col-lg-2 pt-3 d-flex gap-2 align-items-center text-red-500">
            <FaRegTrashAlt size={20} style={{ cursor: "pointer" }} onClick={() => handleRemove(i)} />
        </div>
        <ListVideos index={i} control={control} watch={watch} register={register} errors={errors} />
      </div>
    ))}
    <div className="mt-5">
        <CustomButtonAdd type="button" onClick={handleAdd}>+ Add more Group</CustomButtonAdd>
    </div>
  </div>
  )

}

const CustomButtonAdd = styled.button`
  border: none;
  background: transparent;
  color: #4070ff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
`;