import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { IoClose } from "react-icons/io5";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { Bars } from "react-loader-spinner";

type PropsType = {
  title: string;
  desc: string;
  type: string;
  key_component: string;
  onHide: () => void;
  onConfirm?: () => void;
  show: boolean;
  loading?: boolean;
};

const ItemModal = ({ onHide, key_component, title, desc, show, type, loading, onConfirm }: PropsType) => {

  return (
    <Modal show={show} centered>
      <ModalBody>
        <div className="d-flex flex-column gap-4">
          <div className="content-top">
            <h2 className={`title ${type === "delete" || type === "close" ? "danger" : "success"}`}>
              {title}
            </h2>
            <IoClose size={24} className="icon-close" onClick={onHide} />
          </div>
          <div className="content-center">
            {type === "delete" ? (
              <FiTrash2 size={50} className="icon-trash" />
            ) : type === "success" ? (
              <AiOutlineCheckCircle size={50} className="icon-success" />
            ) : null}
            <span className="text-desc">{desc}</span>
          </div>
          <div className="content-bottom">
            {type === "delete" || type === "close" ? (
              <div className="d-flex gap-3 justify-content-center">
                <ButtonCancel type="button" onClick={onHide}>
                  Close
                </ButtonCancel>
                <ButtonSubmit type="button" onClick={onConfirm}>
                  {loading ? (
                    <Bars
                      height="30"
                      width="30"
                      color="#fff"
                      ariaLabel="bars-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : type === "delete" ? (
                    `Delete ${key_component}`
                  ) : type === "close" ? (
                    `Close Consultation`
                  ) : null}
                </ButtonSubmit>
              </div>
            ) : type === "open" ? (
              <div className="d-flex gap-3 justify-content-center">
              <ButtonReturn type="button" onClick={onHide}>
                Close
              </ButtonReturn>
              <ButtonSubmitOpen type="button" onClick={onConfirm}>
                {loading ? (
                  <Bars
                    height="30"
                    width="30"
                    color="#fff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  `Open Consultation`
                )}
              </ButtonSubmitOpen>
            </div>
            ) : (
              <ButtonReturn type="button" onClick={onHide}>
                Return
              </ButtonReturn>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

const ModalBody = styled(Modal.Body)`
  padding: 35px 20px;

  .content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 0;

      &.danger {
        color: #fb6056;
      }
      &.success {
        color: #06bd80;
      }
    }

    .icon-close {
      cursor: pointer;
    }
  }

  .content-center {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .icon-trash {
      color: #fb6056 !important;
    }
    .icon-success {
      color: #06bd80;
    }

    .text-desc {
      color: #303030;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
    }
  }
`;

const ButtonSubmit = styled.button`
  display: flex;
  width: 50%;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #fb6056;

  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  text-transform: capitalize;
`;

const ButtonCancel = styled.button`
  display: flex;
  width: 50%;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #fb6056;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #fb6056;
`;

const ButtonReturn = styled.button`
  display: flex;
  width: 100%;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;
  border: 1px solid #0f999b;

  color: #0f999b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
`;

const ButtonSubmitOpen = styled.button`
  display: flex;
  width: 100%;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
`;

export default ItemModal;
