import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import { Bars } from "react-loader-spinner";
import { TfiEmail } from "react-icons/tfi";
import { useForm } from "react-hook-form";

import InputBasic from "components/Atoms/Inputs/InputBasic";
import { forgotPassword } from "pages/Authentication/redux/actions";
import { toast } from "react-toastify";
import { RiLockPasswordLine } from "react-icons/ri";

interface PropsTypes {
  type: string;
}

export default function VerifyPage(props: PropsTypes) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (value: any) => {
    setLoading(true);
    
    const payload = {
      email: value.email
    };

    const res = await forgotPassword(payload);

    if (typeof res !== "boolean") {
      setLoading(false);
      toast.success(res.message);
    }
    setLoading(false);
  };

  const handleSignIn = () => {
    navigate(-1);
  };

  return (
    <Fragment>
        <Card>
          <div className="content-top">
            <RiLockPasswordLine size={40} className="icon-lock" />
            <h2 className="title-card">Reset Password</h2>
            <span className="text-desc">
              Enter your email and we will send you a link to reset your password.
            </span>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputBasic
                id="email"
                type="email"
                placeholder="Enter email address"
                outerClassName="mb-3"
                icon={<TfiEmail size={20} />}
                rules={{
                  function: register,
                  name: "email",
                  rules: {
                    required: 'Email is required',
                    pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: 'Entered value does not match email format',
                    },
                },
                errors,
                }}
              />

            <ButtonCustomSend type="submit">
              {loading ? (
                <Bars
                  height="40"
                  width="40"
                  color="#fff"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                "Confirm"
              )}
            </ButtonCustomSend>
            <LinkRegister>
              Already have an account? {" "}
              <span className="text" onClick={handleSignIn}>
                Sign In
              </span>
            </LinkRegister>
          </Form>
        </Card>
    </Fragment>
  );
}

const Card = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  display: flex;
  padding: 50px 30px;
  flex-direction: column;
  gap: 10px;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid #EFEFEF;
  background: #FFF;
  z-index: 2;

  .content-top {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .icon-lock {
      color: #0f999b;
    }
    .title-card {
      color: #303030;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
    }

    .text-desc {
      color: #6e6e6e;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }
  }
`;

const ButtonCustomSend = styled.button`
  display: flex;
  height: 50px;
  margin-top: 10px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: #0f999b;
  border: none;
  width: 100%;

  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const LinkRegister = styled.div`
  margin-top: 10px;
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;

  .text {
    color: #0f999b;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    cursor: pointer;
  }
`;
