import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { BiUser } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";

import { getMyAccount } from "pages/Settings/MyAccount/redux/actions";

type PropsNavbar = {
  title?: string;
};

export default function NavbarLogin(props: PropsNavbar) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [avatar, setAvatar] = useState("");

  const data_account = useSelector((state: any) => state.my_account);

  useEffect(() => {
    // if (getCredential() !== null) {
      // if (data_account.status !== undefined && data_account.status === 200) {
      //   setEmail(data_account.response.data.email);
      //   setEmail(data_account.response.data.image ?? data_account.response.data.image_url);
      // }
      if (data_account.status === undefined || data_account.status === 400) {
        dispatch(getMyAccount());
      }
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!data_account.loading && data_account.status === 200) {
      setEmail(data_account.response.data.email);
      setAvatar(data_account.response.data.image ?? data_account.response.data.image_url);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_account]);

  const handleNavigateSettings = (key: string) => {
    if (window.location.pathname.includes("admin")) {
      navigate("/admin/course");
    }
    if (!window.location.pathname.includes("admin")) {
      navigate("/course");
    }
  };

  const handleNavigateProfile = () => {
    if (window.location.pathname.includes("admin")) {
      return "/admin/my-account";
    }
    if (!window.location.pathname.includes("admin")) {
      return "/my-account";
    }
  };

  function ProfileMenu() {
    return (
      <Dropdown>
        <ContentUserProfile id="dropdown-autoclose-true" avatar={avatar}>
        </ContentUserProfile>

        <DropdownMenuCustom>
          <DropdownItem href={handleNavigateProfile()}>
            <BiUser size={18} />
            Profile
          </DropdownItem>
        </DropdownMenuCustom>
      </Dropdown>
    );
  }

  return (
    <ContentTop>
      <div className="d-flex align-items-center">
        <h1 onClick={() => handleNavigateSettings("/")}>{props.title}</h1>
      </div>
      <div className="content-nav-right d-flex justify-content-center align-items-center g-1">
        <h6
          style={{
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "27px",
            marginRight: "6px",
          }}
        >
          {email}
        </h6>
        <ProfileMenu />
      </div>
    </ContentTop>
  );
}

const ContentTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  background-color: #fff;
  border-bottom: 1px solid #dbdbdb;

  h1 {
    color: #303030;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;

const ContentUserProfile = styled(Dropdown.Toggle)<{ avatar: string }>`
  border: none;
  width: 50px;
  height: 50px;
  border: none;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background: center / cover no-repeat url(${props => props.avatar}) #0f999b !important;

  &:focus,
  &:hover,
  &:active,
  &:focus-visible {
    box-shadow: none;
    background: center / cover no-repeat url(${props => props.avatar}) #0f999b !important;
    border: none;
  }

  &:after {
    display: none;
  }
`;

const DropdownMenuCustom = styled(Dropdown.Menu)`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e6e6e6 !important;
  background: #fff;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
`;

const DropdownItem = styled(Dropdown.Item)`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #303030 !important;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.24px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    background: #cfebeb !important;
    border-radius: 5px;
    color: #303030 !important;
  }
`;
