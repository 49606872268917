export const GET_MY_ACCOUNT_REQUEST = 'GET_MY_ACCOUNT_REQUEST';
export const GET_MY_ACCOUNT_SUCCESS = 'GET_MY_ACCOUNT_SUCCESS';
export const GET_MY_ACCOUNT_FAIL = 'GET_MY_ACCOUNT_FAIL';

export const MyAccountReducer = (state = {}, action: any): any => {
    switch (action.type) {
        case GET_MY_ACCOUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case GET_MY_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                status: 200,
            };

        case GET_MY_ACCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: 400,
            };

        default:
            return state;
    }
};
