export const GET_ALL_ASK_REQUEST = "GET_ALL_ASK_REQUEST";
export const GET_ALL_ASK_SUCCESS = "GET_ALL_ASK_SUCCESS";
export const GET_ALL_ASK_FAIL = "GET_ALL_ASK_FAIL";

export const commentsReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_ALL_ASK_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_ASK_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_ALL_ASK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};
