import rootReducer from './rootReducer';
import { configureStore } from '@reduxjs/toolkit';

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({
            serializableCheck: false,
        });
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export interface AppState {
    login: {
        isAuthenticated: boolean;
        token: string | null;
        email: string | null;
        id: string | null;
        loading: boolean;
    };
    notificationsToast: {
        notifications: Array<{
            id: string;
            title: string;
            description: string;
            date: string;
            read: boolean;
        }>;
    };
}
