import { useEffect } from 'react';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

/**
 * Function for use script
 *
 * @returns {string} user id
 */
export const useScript = (url: string): void => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
};

/**
 * Function for detect outside click
 *
 * @param ref : React.RefObject<HTMLElement>
 * @param callback : () => void
 * @returns void
 */
export function outsideClick(
    ref: React.RefObject<HTMLElement>,
    callback: () => void,
): void {
    /**
     * Function for handle click outside
     */
    function handleClickOutside(event: MouseEvent): void {
        if (ref.current != null && !ref.current.contains(event.target as Node)) {
            callback();
        }
    }
    // Bind the event listener
    if (ref.current != null) {
        document.addEventListener('mousedown', handleClickOutside);
    } else {
        document.removeEventListener('mousedown', handleClickOutside);
    }
}

/**
 * Function for check only input number
 *
 * @param e : React.KeyboardEvent<HTMLInputElement>
 * @returns void
 */
export function onlyNumber(e: React.KeyboardEvent<HTMLInputElement>): void {
    // only number and delete key
    const number = /^[0-9\b]+$/;
    if (!number.test(e.key) && e.key !== 'Backspace') {
        e.preventDefault();
    }
}

/**
 * Function for get token from cookie
 *
 * @returns {string} token
 */
export function getId(): any {
    if (getCredential() !== null) {
        const {
            id,
        }: {
            id: string;
        } = getCredential() as any;

        return id;
    }
}

/**
 * Function for get token from cookie
 *
 * @returns {string} token
 */
export function getToken(): any {
    if (getCredential() !== null) {
        const {
            token,
        }: {
            token: string;
        } = getCredential() as any;

        return token;
    }
}

/**
 * Function for get initial name
 *
 * @returns string
 * @param name
 */
export const getInitialName = (name: string): string => {
    // if name include space
    if (name?.includes(' ')) {
        const splitName = name.split(' ');
        return `${splitName[0].charAt(0)}${splitName[1].charAt(0)}`;
    }
    // if name not include space
    return name?.charAt(0);
};

/**
 * Function for format string to kebab case
 *
 * @returns string
 * @param str
 */
export const formatKebabCase = (str: string): string => {
    return str.replace(/\s+/g, '-').toLowerCase();
};

/**
 * Function for capitalize first letter.
 *
 * @returns string
 * @param str
 */
export const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

// Key of encryption
export const keyEnum = process.env.REACT_APP_ENCRYPT_KEY;

/**
 * Function to encrypt data
 *
 * @param data string
 */
export const encryptData = (data: string): string => {
    return CryptoJS.AES.encrypt(data, keyEnum as string).toString();
};

/**
 * Function to encrypt data
 *
 * @param data string
 */
export const decryptData = (data: string): any => {
    const decryptedBytes = CryptoJS.AES.decrypt(data, keyEnum as string);

    return decryptedBytes?.toString(CryptoJS.enc.Utf8);
};

/**
 * Function to get credential as object
 *
 * @returns void
 */
export const getCredential = (): object | null => {
    const user = Cookies.get('user');

    if (user !== undefined) {
        const data = decryptData(user);
        return JSON.parse(data);
    }
    return null;
};

/**
 * Function to get credential as object
 *
 * @returns void
 */
export const getAuth = (): string | null => {
    if (window.location.pathname.includes("admin")) {
        const token = Cookies.get("auth_1")
        if (token !== undefined) {
            const data = decryptData(token);
            return data;
        }
    }
    if (!window.location.pathname.includes("admin")) {
        const token = Cookies.get("auth_2")
        if (token !== undefined) {
            const data = decryptData(token);
            return data;
        }
    }
    return null;
};

/**
 * Function to currency format price
 *
 * @returns void
 */
export function fCurrency(number: string | number) {
    const reverse: string = number.toString().split('').reverse().join('');
    let ribuan: any = reverse.match(/\d{1,3}/g);
    const result = `Rp. ${ribuan.join(',').split('').reverse().join('')}`;
    return result;
}

/**
 * Function to currency format price
 *
 * @returns void
 */
export function convertSecondstoTime(number: number) {
    let timeString = '';
    const dateObj = new Date(number * 1000);
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getSeconds();

    timeString = hours !== 0 ? hours.toString().padStart(2, '0')
        + ':' : '';
    timeString = minutes.toString().padStart(2, '0')
        + ':' + seconds.toString().padStart(2, '0');

    return timeString;
}

/**
 * Function for get roles from cookie
 *
 * @returns {string} token
 */
export function checkRoles(): number | null{
    const role = Cookies.get("role");
    if (role !== undefined) {
        const data = decryptData(role);
        return parseInt(data);
    }
    return null;
}

export const generateInitialImageUrl = (name: string): string => {
    return `https://ui-avatars.com/api/?name=${name}&background=5648fb&color=fff&size=128`;
};

type DataCurrency = {
    amount: string;
    currency_iso3: string;
};

export const filterDataPrice = (data: DataCurrency[]) => {
    let result: string = "";
    // eslint-disable-next-line array-callback-return
    data.filter((item) => {
      if (item.currency_iso3.toLowerCase() === "idr") {
        result = item.amount;
      }
    });

    return result;
  };

export function bytesToMB(sizeBytes: number): number {
    return sizeBytes / (1024 ** 2);
}