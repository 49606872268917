export const GET_ALL_CLASSESS_REQUEST = "GET_ALL_CLASSESS_REQUEST";
export const GET_ALL_CLASSESS_SUCCESS = "GET_ALL_CLASSESS_SUCCESS";
export const GET_ALL_CLASSESS_FAIL = "GET_ALL_CLASSESS_FAIL";

export const GET_ALL_CONSULTATIONS_REQUEST = "GET_ALL_CONSULTATIONS_REQUEST";
export const GET_ALL_CONSULTATIONS_SUCCESS = "GET_ALL_CONSULTATIONS_SUCCESS";
export const GET_ALL_CONSULTATIONS_FAIL = "GET_ALL_CONSULTATIONS_FAIL";

export const classessReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_ALL_CLASSESS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_CLASSESS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_ALL_CLASSESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};

export const consultationsReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_ALL_CONSULTATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_CONSULTATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_ALL_CONSULTATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};
