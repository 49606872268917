import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import { getAllMentors, deleteMentor } from "../redux/actions";

import Pagination from "components/Atoms/Paginations";
import { TableHeader } from "components/Atoms/Tables/header";
import { TableRow } from "components/Atoms/Tables/row";
import InputCustom from "components/Atoms/Inputs/InputCustom";
import ModalCustom from "components/Organism/Modal";

type PropsHandlePage = {
  selected: number;
};

type DataMentor = {
  id: string;
  name: string;
  email: string;
  class: string;
  description: string;
  is_consultation_session: string;
};

let tableTitle = ["Mentor Name", "Class", "Consultation Session", "Action"];

export default function TableMentorManagement(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selected, setSelected] = useState("")
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [datas, setDatas] = useState({});
  const [params, setParams] = useState({
    with_pagination: true,
    limit: 10,
    page: 1,
    search: "",
  });

  const data_mentors = useSelector((state: any) => state.mentors);

  useEffect(() => {
    dispatch(getAllMentors(params));
  }, [dispatch, params]);

  useEffect(() => {
    if (!data_mentors.loading && data_mentors.status === 200) {
      formatData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_mentors]);

  /**
   * Function to handle search with callback
   *
   * @returns void
   */
  const handleDebouncedSearch = useCallback(() => {
    setParams({
      ...params,
      search: searchValue,
    });

    const payload = {
      ...params,
      search: searchValue,
    };

    dispatch(getAllMentors(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // Function for handle debounce search
  useEffect(() => {
    const debounce = setTimeout(() => {
      handleDebouncedSearch();
    }, 500);

    return () => {
      clearTimeout(debounce);
    };
  }, [handleDebouncedSearch]);

  const formatData = () => {
    let obj = {};
    // eslint-disable-next-line array-callback-return
    data_mentors.response.data.map((d: DataMentor, i: number) => {
      let oneRow = {
        id: d.id,
        created: d.name,
        class: d.class,
        session: d.is_consultation_session,
        action: "",
      };

      obj = { ...obj, [i]: oneRow };
    });
    setDatas(obj);
  };

  const handlePage = async ({ selected }: PropsHandlePage) => {
    setParams((prev) => ({
      ...prev,
      page: selected + 1,
    }));

    const payload = {
      ...params,
      page: selected + 1,
    };
    await dispatch(getAllMentors(payload));
  };

  const handleNavigate = (key: string) => {
    navigate(`${key}`);
  };

  /**
   * Function to handle search
   *
   * @param string value
   * @returns void
   */
  const handleSearch = useCallback((value: string): void => {
    setSearchValue(value);
  }, []);


  const handleShowModal = () => setShowModal(!showModal);

  const openModal = (key: string) => {
    setSelected(key);
    handleShowModal();
  };
  
  const closeModal = (): void => {
    setSelected("");
    handleShowModal();
  };

  const confirmDelete = async () => {
    setLoading(true);
    const res = await deleteMentor({ id: selected })
    if (res.status) {
      setSuccess(true);
      setLoading(false);
      closeModal();
      dispatch(getAllMentors(params));
    }
    if (!res.status) {
      setLoading(false);
      toast.error(res.message);
    }
  }

  return (
    <ContainerCenter>
      <div className="content-search d-flex justify-content-end">
        <div className="w-25">
          <InputCustom
            id="email"
            type="text"
            placeholder="Search mentor..."
            icon={<IoSearchOutline size={20} />}
            onChange={(e) => {
              handleSearch?.(e.target.value);
            }}
          />
        </div>
      </div>

      {data_mentors.loading && (
        <div className="h-100 d-flex justify-center align-items-center">
          <TailSpin
            height="200"
            width="200"
            color="#0F999B"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}

      {!data_mentors.loading && data_mentors.status === 200 && (
        <table className="table mt-4">
          <TableHeader data={tableTitle} />
          <tbody>
            {datas
              ? Object.values(datas).map((columns: any) => (
                  <TableRow
                    key={columns.id}
                    columns={columns}
                    editTitle="Edit Mentor"
                    editAction={() =>
                      handleNavigate(`/admin/mentor/edit/${columns.id}`)
                    }
                    deleteTitle="Delete Mentor"
                    deleteAction={() => openModal(columns.id)}
                  />
                ))
              : ""}
          </tbody>
        </table>
      )}

      {!data_mentors.loading && data_mentors.status === 200 && (
        <Pagination
          pageCount={data_mentors.response.meta.last_page}
          forcePage={data_mentors.response.meta.current_page}
          onPageChange={handlePage}
        />
      )}

      <ModalCustom
        show={showModal}
        onHide={closeModal}
        onConfirm={confirmDelete}
        loading={loading}
        type="delete"
        key_component="Mentor"
        title="Delete Mentor?"
        desc="Are you sure want to delete this Mentor?, This Mentor will not have a access anymore."
      />
      
      <ModalCustom
        show={success}
        key_component="Course"
        onHide={() => setSuccess(!success)}
        type="success"
        title="Success"
        desc="Successfully delete Mentor"
      />

    </ContainerCenter>
  );
}

const ContainerCenter = styled.div`
  padding: 30px 40px;
  margin-bottom: 50px;
  position: relative;
  min-height: 90vh;

  .center-content {
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;
