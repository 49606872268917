/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { CSSObjectWithLabel } from "react-select";
import { Bars, TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";

import { RootState } from "redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetailsConsul,
  updateConsul,
  deleteConsul,
  closeConsul,
  openConsul,
} from "../redux/actions";
import { getAllMentors } from "pages/Admin/MentorManagement/redux/actions";
import { getAllClassesAdmin } from "pages/Home/redux/actions";

import InputBasic from "components/Atoms/Inputs/InputBasic";
import InputCustom from "components/Atoms/Inputs/InputCustom";
import Layout from "components/Organism/Layouts";
import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import InputSearchSelect from "components/Atoms/Inputs/InputSearchSelect";
import InputCurrency from "components/Atoms/Inputs/InputCurrency";
import ModalCustom from "components/Organism/Modal";

type MentorType = {
  id: string;
  name: string;
};

export default function CreateMentorPage(): JSX.Element {
  const methods = useForm({
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [modalDelete, setModalDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingOpen, setLoadingOpen] = useState(false);
  const [mentorLists, setMentorLists] = useState<any[]>([]);
  const [classesLists, setClassesLists] = useState<any[]>([]);

  const data_consul = useSelector(
    (state: any) => state.details_consultation_admin
  );
  const dataMentor = useSelector((state: any) => state.mentors);
  const allClasses = useSelector((state: RootState) => state.classess_courses);

  useEffect(() => {
    dispatch(getAllClassesAdmin());
    dispatch(
      getAllMentors({
        limit: 9999,
        with_pagination: false,
        page: 1,
      })
    );
    if (id) {
      dispatch(getDetailsConsul({ id: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!data_consul.loading && data_consul.status === 200) {
      handleGetData();
    }
  }, [data_consul]);

  useEffect(() => {
    if (!dataMentor.loading && dataMentor.status === 200) {
      handleGetDataMentor();
    }
  }, [dataMentor]);

  useEffect(() => {
    if (!allClasses.loading && allClasses.status === 200) {
      const temp: any[] = [];
      allClasses.response.map((d: string) => {
        temp.push({
          label: d,
          value: d,
        });
      });
      setClassesLists(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allClasses]);

  const handleGetDataMentor = () => {
    let temp: any[] = [];
    // eslint-disable-next-line array-callback-return
    dataMentor.response.data.map((d: MentorType) => {
      temp.push({
        value: d.id,
        label: d.name,
      });
    });
    setMentorLists(temp);
  };

  const handleGetData = () => {
    const item = data_consul.response.data;
    setValue("id", item.id);
    setValue("amount", item.amount);
    setValue("name", item.name);
    setValue("class", item.class);
    setValue("mentor_id", item.mentor.id);
    setValue("duration", item.duration);
    setValue("description", item.description);
  };

  const convertPrice = (value: any): any => {
    const numericString = value.replace(/[^0-9]/g, "");

    // Parse the numeric string as an integer
    const result = parseInt(numericString, 10);
    return result;
  };


  const onSubmit = async (value: any) => {
    setLoading(true);
    const payload = new FormData();
    payload.append("_method", "PUT");
    payload.append("consultation_id", value.id);
    payload.append("name", value.name);
    payload.append("class", value.class);
    payload.append("mentor_id", value.mentor_id);
    payload.append("amount", typeof value.amount === "number" ? value.amount : convertPrice(value.amount));
    payload.append("duration", value.duration);
    payload.append("description", value.description);

    const res = await updateConsul(payload);
    if (res.status) {
      toast.success(res.message);
      setLoading(false);
      navigate("/admin/consultation-management");
    }
    if (!res.status) {
      setLoading(false);
      Object.entries(res.errors).map((d: any) => {
        let message: string = d[1][0];
        setError(d[0], { message: message }, {
          shouldFocus: true
        });
      })
    }
    setLoading(false);
  };

  const handleNavigate = (key: string): void => {
    if (key === "back") {
      return navigate(-1);
    }
    navigate(key);
  };

  const confirmDelete = async () => {
    setLoading(true);
    if (id) {
      const res = await deleteConsul({ id: id });
      if (res.status) {
        toast.success(res.message);
        setLoading(false);
        setTimeout(() => {
          navigate("/admin/consultation-management");
        }, 500);
      }
      if (!res.status) {
        setLoading(false);
        toast.error(res.message);
      }
    }
  };

  const confirmClose = async () => {
    setLoadingOpen(true);
    if (id) {
      let res;
      const payload = new FormData();
      payload.append("consultation_id", id);
      if (data_consul?.response?.data?.status) {
        res = await closeConsul(payload);
      } else {
        res = await openConsul(payload);
      }
      if (res.status) {
        setLoadingOpen(false);
        toast.success(res.message);
        dispatch(getDetailsConsul({ id: id }));
      }
      if (!res.status) {
        setLoadingOpen(false);
        toast.error(res.message);
      }
    }
  };

  const handleShowModalDelete = () => setModalDelete(!modalDelete);

  const basicStyles = {
    control: (
      baseStyles: CSSObjectWithLabel,
      state: { isFocused: boolean }
    ) => ({
      ...baseStyles,
      borderRadius: "10px",
      boxShadow: "none",
      padding: "0 10px",
      margin: 0,
    }),
    valueContainer: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      padding: "10px 0px",
      margin: 0,
      fontSize: "1rem",
    }),
    option: (
      baseStyles: CSSObjectWithLabel,
      state: { isFocused: boolean; isHover: boolean }
    ) => ({
      ...baseStyles,
      backgroundColor: state.isFocused || state.isHover ? "#0F999B" : "",
      color: state.isFocused ? "white" : "black",
      fontSize: "14px",
    }),
  };

  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Edit Consultation" />

        <ContainerCenter>
          {data_consul.loading ? (
            <div className="h-100 d-flex justify-center align-items-center">
              <TailSpin
                height="200"
                width="200"
                color="#0F999B"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            data_consul.status === 200 &&
            data_consul.response !== undefined && (
              <div className="form-content">
                <FormProvider {...methods} >
                  <Form
                    onSubmit={handleSubmit(onSubmit)}
                    className="d-flex flex-column gap-3"
                  >
                    <Row className="mb-3 border-bottom pb-5">
                      <div className="title-form">
                        <h2>General Information</h2>
                      </div>
                      <InputBasic
                        id="name"
                        type="text"
                        placeholder="Enter consultation name..."
                        md="6"
                        isRequired={true}
                        label="Consultation Name"
                        outerClassName="mb-3"
                        rules={{
                          function: register,
                          name: "name",
                          rules: {
                            required: "Consultation Name is required",
                          },
                          errors,
                        }}
                      />

                      <div className="col-lg-6">
                      <Controller
                        control={control}
                        name="class"
                        rules={{
                          required: "Class is required",
                        }}
                        render={({
                          field: { onChange, onBlur, value },
                          fieldState: { error },
                        }) => {
                          const checkError = !!error && !value;

                          return (
                            <div className="text-start">
                              <FormLabel>
                                Class
                                <small className="req">*</small>
                              </FormLabel>
                              <InputSearchSelect
                                placeholder="Select Class"
                                innerClassName="mb-1 "
                                styles={basicStyles}
                                options={classesLists}
                                isMulti={false}
                                onBlur={onBlur}
                                withCreatable
                                value={
                                  classesLists.filter(
                                    (d) => d.value === watch("class")
                                  )
                                }
                                errors={checkError}
                                onChange={(value: {
                                  value: string;
                                  label: string;
                                }) => {
                                  setValue("class", value.value)
                                  onChange(value.value);
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="class"
                                render={({ message }) => (
                                  <span
                                    className="invalid-feedback d-block text-start"
                                    style={{
                                      fontSize: "0.75rem",
                                    }}
                                  >
                                    {message}
                                  </span>
                                )}
                              />
                            </div>
                          );
                        }}
                      />
                    </div>

                      <div className="col-lg-6">
                        <Controller
                          control={control}
                          name="mentor_id"
                          rules={{
                            required: "Mentor is required",
                          }}
                          render={({ field: { onChange } }) => (
                            <div className="text-start mt-2">
                              <FormLabel>
                                Mentor
                                <small className="req">*</small>
                              </FormLabel>
                              <InputSearchSelect
                                placeholder="Select Mentor"
                                innerClassName="mb-1 "
                                styles={basicStyles}
                                options={mentorLists}
                                isMulti={false}
                                value={mentorLists.filter(
                                  (item) => item.value === watch("mentor_id")
                                )}
                                onChange={(value: {
                                  value: string;
                                  label: string;
                                }) => {
                                  onChange(value.value);
                                }}
                              />
                            </div>
                          )}
                        />
                      </div>
                      <div className="col-lg-6">
                        <InputCurrency
                          id="amount"
                          label="Consultation Price"
                          placeholder="Enter consultation price..."
                          className="mt-2"
                          isRequired={true}
                          // value={watch("amount") ? watch("amount") : 0}
                          rules={{
                            function: register,
                            name: "amount",
                            rules: {
                              required: "Consultation Price is required",
                            },
                            errors,
                          }}
                          prefix="Rp "
                        />
                      </div>

                      <InputBasic
                        id="description"
                        type="text"
                        placeholder="Enter consultation description..."
                        md="6"
                        label="Consultation Description"
                        isRequired={true}
                        textArea="textarea"
                        rules={{
                          function: register,
                          name: "description",
                          rules: {
                            required: "Consultation Description is required",
                            validate: {
                              isMaxLength: (value: string) =>
                                    value.length <= 1000 ||
                                    'Less than 1000 characters',
                            }
                          },
                          errors,
                        }}
                        className="d-flex flex-column"
                      />
                    </Row>

                    <Row className="mb-3 border-bottom pb-5">
                      <div className="title-form">
                        <h2>Consultation Settings</h2>
                      </div>

                      <div className="col-lg-6">
                        <div>
                          <FormLabel>
                            Consultation Duration
                            <span className="req">*</span>
                          </FormLabel>
                        </div>
                        <InputCustom
                          id="duration"
                          type="text"
                          placeholder="Enter duration (in number)"
                          icon="hours"
                          isRequired={true}
                          rules={{
                            function: register,
                            name: "duration",
                            rules: {
                              required: "Consultation Duration is required",
                            },
                            errors,
                          }}
                        />
                      </div>
                    </Row>

                    <div className="d-flex gap-3 justify-content-end">
                      <ButtonDelete type="button" onClick={handleShowModalDelete}>
                        Delete Consultation
                      </ButtonDelete>
                      <ButtonCancel type="button" onClick={confirmClose}>
                        {loadingOpen ? (
                          <Bars
                            height="30"
                            width="30"
                            color="#0f999b"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        ) : data_consul?.response?.data?.status ? (
                          "Close Consultation"
                        ) : (
                          "Open Consultation"
                        )}
                      </ButtonCancel>
                      <ButtonCancel
                        type="button"
                        onClick={() => handleNavigate("back")}
                      >
                        Back
                      </ButtonCancel>

                      <ButtonSubmit type={loading ? "button" : "submit"}>
                        {loading ? (
                          <Bars
                            height="30"
                            width="30"
                            color="#fff"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        ) : (
                          "Update Consultation"
                        )}
                      </ButtonSubmit>
                    </div>
                  </Form>
                </FormProvider>
              </div>
            )
          )}
        </ContainerCenter>
      </ContainerCustom>

      <ModalCustom
        show={modalDelete}
        onHide={handleShowModalDelete}
        onConfirm={confirmDelete}
        loading={loading}
        type="delete"
        title="Delete Consultation?"
        key_component="Consultation"
        desc="Are you sure want to delete this consultation?, you will not have access to this consultation anymore."
      />
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0;
`;

const ContainerCenter = styled.div`
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .form-content {
    width: 100%;

    .title-form {
      margin-bottom: 30px;
      h2 {
        color: #303030;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const ButtonSubmit = styled.button`
  display: flex;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
`;

const ButtonCancel = styled.button`
  display: flex;
  min-width: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #0f999b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #0f999b;
`;

const FormLabel = styled(Form.Label)`
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  .req {
    color: #fb6056;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;

const ButtonDelete = styled.button`
  display: flex;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #fb6056;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #fb6056;
`;
