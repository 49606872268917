import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { IoClose } from "react-icons/io5";

import Figma from "assets/images/figma.svg";

type DataType = {
  slug: string;
  name: string;
  class: string;
};

type PropsType = {
  title: string;
  desc: string;
  data: DataType[];
  onHide: () => void;
  show: boolean;
};

const ItemModal = ({ data, onHide, title, desc, show }: PropsType) => {
  function checkTextEnding(text: string, keyword: string): boolean {
    const endIndex = text.length - keyword.length;
    return text.indexOf(keyword, endIndex) !== -1;
  }

  const handleNavigate = (link: string) => {
    if (checkTextEnding(link, 'consul')) {
      window.location.replace(`https://staging-courses.exclolab.com/checkout/${link}`);
    }
    if (!checkTextEnding(link, 'consul')) {
      window.location.replace(`https://exclolab.com/exclodemy/${link}`);
    }
  };

  const handleCol = () => {
    if (data.length === 9) {
      return "col-lg-4 col-md-4 col-12";
    }
    if (data.length === 6) {
      return "col-lg-4 col-md-4 col-12";
    }
    if (data.length === 4) {
      return "col-lg-6 col-md-6 col-12";
    }

    return "col-lg-4 col-md-4 col-12";
  };
  return (
    <Modal show={show} fullscreen>
      <ModalBody>
        <div className="row mx-0">
          <div className="col-lg-4 content-left">
            <IoClose size={40} className="icon" onClick={onHide} />
            <div className="title">
              <h2>{title}</h2>
              <span>{desc}</span>
            </div>
          </div>
          <div className="col-lg-8 content-list">
            <div className="row mx-0">
              {data !== undefined && data.map((d, i) => (
                <div
                  key={i}
                  className={`${handleCol()} card-content`}
                  onClick={() => handleNavigate(d.slug)}
                >
                  <img src={Figma} alt="logo" />
                  <h2>{d.class} :</h2>
                  <h2>{d.name}</h2>
                </div>
              ))}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

const ModalBody = styled(Modal.Body)`
  background-color: #0f999b;
  padding: 0;

  .content-left {
    background-color: #3fadaf;
    color: #ffffff !important;
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 100vh;

    .icon {
      cursor: pointer;
    }

    .title {
      h2 {
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 42px;
      }

      span {
        text-align: justify;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
      }
    }
  }

  .content-list {
    padding: 0;
    .card-content {
      padding: 72px 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      cursor: pointer;
      gap: 10px;

      h2 {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        text-align: center;
        margin-bottom: 0;
      }
    }

    .card-content:hover {
      background-color: #0c7a7c;
    }
  }
`;

export default ItemModal;
