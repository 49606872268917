import { Fragment, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { useFormContext, Controller } from "react-hook-form";
import { bytesToMB } from "utility/Utils";
interface InputFileProps {
  name: string;
  label: string;
  isRequired?: boolean;
  watch?: any;
  placeholder: string;
  accepted: string;
  rules?: {
    function: (name: any, rules: any) => any;
    name: string;
    rules: any;
    errors?: any;
  };
}

/**
 * Input File component boilerplate.
 *
 * @param props
 * @constructor
 */
export default function InputFile(props: InputFileProps): JSX.Element {
  const { name, label, isRequired, placeholder, accepted, rules, watch } = props;

  const inputFileRef = useRef<HTMLInputElement>(null);
  const { control } = useFormContext();

  const [nameFile, setNameFIle] = useState("");
  const [sizeFile, setSizeFile] = useState("");
  useEffect(() => {
    const val = watch(name)
    if (val !== undefined) {
      setNameFIle(val.split('/').pop())
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name])
  /**
   * Function to handle the upload of the file.
   *
   * @returns void
   */
  const handleUpload = (): void => {
    if (inputFileRef.current !== undefined && inputFileRef.current !== null) {
      inputFileRef.current.click();
    }
  };

  return (
    <Fragment>
      <Controller
        name={name}
        control={control}
        rules={rules?.rules}
        render={({ field, fieldState: { error } }) => {
          const checkError = !!error && !field.value;
          const handleAddFile = (e: any) => {
            const target = e.target as HTMLInputElement;
            const file = target.files;
            if (file) {
              setSizeFile(`File Size: ${bytesToMB(file[0].size).toFixed(2)} Mb`);
            }

            field.onChange(file ? file[0] : "");
        
            setNameFIle(file ? file[0].name : "");
          };
          return (
            <>
              <BoxSendMessage errors={checkError}>
                <FormLabel>
                  {label}
                  {isRequired && <small className="req">*</small>}
                </FormLabel>
                <StyledInputFile
                  type="file"
                  accept={accepted}
                  ref={inputFileRef}
                  onChange={handleAddFile}
                />
                <div className="content-form">
                  <FormMessage
                    placeholder={placeholder}
                    aria-label="message"
                    aria-describedby="message"
                    value={nameFile}
                    disabled
                    readOnly
                  />
                  <ButtonSend type="button" onClick={handleUpload}>
                    Select From Files
                  </ButtonSend>
                </div>
              </BoxSendMessage>
              <span
                  className="d-block text-start"
                  style={{
                    fontSize: "0.75rem",
                  }}
                >{sizeFile}</span>
              {checkError && (
                <span
                  className="invalid-feedback d-block text-start"
                  style={{
                    fontSize: "0.75rem",
                  }}
                >
                  {error.message}
                </span>
              )}
            </>
          );
        }}
      />
    </Fragment>
  );
}

const StyledInputFile = styled.input`
  display: none;
`;

const BoxSendMessage = styled.div<{ errors?: boolean }>`
  .content-form {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    bottom: 0;
    margin-bottom: 20px;
    padding: 7px;

    border-radius: 10px;
    border: 1px solid
    ${(props) => (props.errors ?? false ? "#FB6056" : "#e6e6e6")};

    background: #fff;

    &.close {
      border: 1px solid #0f999b;
      background: #cfebeb;
      padding: 20px;

      color: #0f999b;
      text-align: justify;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }
  }

  .desc-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .icon-flash {
      color: #0f999b;
    }

    span {
      color: #303030;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
`;

const FormMessage = styled(Form.Control)`
  border: none;
  background: transparent;

  &::placeholder {
    color: #acacac;
    text-align: justify;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
  &:disabled {
    background: transparent;
  }
`;

const ButtonSend = styled.button`
  width: 250px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #f0f3ff;
  border: none;

  color: #303030;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const FormLabel = styled(Form.Label)`
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  .req {
    color: #fb6056;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;
