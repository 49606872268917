import styled from "styled-components";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

import ItemModal from "../ModalFullScreen";
import Logo from "assets/images/exclodemy.png";
import { useState } from "react";

function ColorSchemesExample() {
  const [showModalClass, setShowModalClass] = useState(false);
  const [showModalBooster, setShowModalBooster] = useState(false);

  const courses = useSelector((state: RootState) => state.all_classes_data);
  const consultations = useSelector((state: RootState) => state.all_consultations_data);

  const handleModalHide = () => setShowModalClass(!showModalClass);
  const handleModalHideBooster = () => setShowModalBooster(!showModalBooster);

  const handleBackToExclo = (key: string) => {
    window.location.replace(key);
  };

  return (
    <>
      <Navbar bg="light" data-bs-theme="light">
        <Container style={{ maxHeight: '60px' }}>
          <Navbar.Brand href="" onClick={() => handleBackToExclo('https://exclolab.com/exclodemy')} className="me-5">
            <ImgCustom src={Logo} alt="logo" />
          </Navbar.Brand>
          <Nav className="me-auto">
            <NavLink href="https://exclolab.com/exclodemy/class/">
              Classes
            </NavLink>
            <NavLink href="https://exclolab.com/exclodemy/consultations/">
              Consultations
            </NavLink>
            <NavLink href="https://exclolab.com/exclodemy/blog-and-tips/">
              Blog and Tips
            </NavLink>
            <NavLink href="https://exclolab.com/exclodemy/about-exclodemy/">
              About
            </NavLink>
          </Nav>
          <ButtonCustom type="button" onClick={() => handleBackToExclo('https://exclolab.com')}>
            Return to Exclolab
          </ButtonCustom>
        </Container>
      </Navbar>

      <ItemModal
        show={showModalClass}
        onHide={handleModalHide}
        data={courses?.response?.data}
        title="Our Classes"
        desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu orci at magna bibendum gravida nec ut augue. Maecenas bibendum arcu at arcu ultrices, egestas volutpat purus aliquam."
      />
      <ItemModal
        show={showModalBooster}
        onHide={handleModalHideBooster}
        data={consultations?.response?.data}
        title="Consultations"
        desc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin eu orci at magna bibendum gravida nec ut augue. Maecenas bibendum arcu at arcu ultrices, egestas volutpat purus aliquam."
      />
    </>
  );
}

const ImgCustom = styled.img`
  width: 110px;
  // height: 40px;
  object-fit: cover;
`;

const NavLink = styled(Nav.Link)`
  color: #303030;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin: 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #0f999b !important;
    background-color: transparent !important;
  }
`;

const ButtonCustom = styled.button`
  display: inline-flex;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
  background-color: #0f999b;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
`;

export default ColorSchemesExample;
