import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { IoClose } from "react-icons/io5";
import { Bars } from "react-loader-spinner";

type PropsType = {
  title: string;
  desc: string;
  onHide: () => void;
  onConfirm?: () => void;
  show: boolean;
  loading?: boolean;
};

const ItemModal = ({ onHide, title, show, loading, onConfirm, desc }: PropsType) => {

  return (
    <Modal show={show} centered>
      <ModalBody>
      <div className="d-flex flex-column gap-4">
          <div className="content-top">
            <h2 className={`title`}>
              {title}
            </h2>
            <IoClose size={24} className="icon-close" onClick={onHide} />
          </div>
          <div className="content-center">
            <span className="text-desc">{desc}</span>
          </div>
          <div className="content-bottom">
                <div className="d-flex gap-3 justify-content-end">
                  <ButtonCancel type="button" onClick={onHide}>
                    Cancel
                  </ButtonCancel>
                  <ButtonSubmit type={loading ? "button" : "submit"} onClick={onConfirm}>
                    {loading ? (
                      <Bars
                        height="30"
                        width="30"
                        color="#fff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      "Login"
                    )}
                  </ButtonSubmit>
                </div>
              </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

const ModalBody = styled(Modal.Body)`
  padding: 35px 20px;

  .content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 0;
    }

    .icon-close {
      cursor: pointer;
    }
  }

  .content-center {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .text-desc {
      color: #303030;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
    }
  }
`;

const ButtonSubmit = styled.button`
  display: flex;
  width: 50%;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  text-transform: capitalize;

  &:hover {
    background: transparent;
    color: #0f999b;
    border: 1px solid #0f999b;
  }
`;

const ButtonCancel = styled.button`
  display: flex;
  width: 50%;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #0f999b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #0f999b;

  &:hover {
    background: #0f999b;
    color: #fff;
  }
`;

export default ItemModal;
