import CreatableSelect from "react-select/creatable";
import Select, { CSSObjectWithLabel, InputActionMeta } from "react-select";

import styled from "styled-components";

/**
 * Input search select component boilerplate.
 *
 * @param props
 * @return
 */
export default function InputSearchSelect(props: {
  options: object[];
  isMulti: boolean;
  onChange?: (value: any) => void;
  outerClassName?: string;
  innerClassName?: string;
  placeholder?: string;
  defaultValue?: any;
  withCreatable?: boolean;
  disabled?: boolean;
  placeholderCreatable?: string;
  errors?: boolean;
  value?: any;
  styles?: any;
  isClearable?: boolean;
  onBlur?: (value: any) => void;
  onMenuClose?: () => void;
  id?: string;
  loading?: boolean;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
}): JSX.Element {
  const {
    options,
    onChange,
    outerClassName,
    innerClassName,
    placeholder,
    isMulti,
    withCreatable,
    defaultValue,
    disabled,
    placeholderCreatable,
    value,
    isClearable,
    id,
    onBlur,
    onMenuClose,
    loading,
    onInputChange,
    errors,
  } = props;

  return (
    <InputWrapper className={outerClassName}>
      {withCreatable ?? false ? (
        <div className="d-flex">
          <CreatableSelect
            id={id}
            isMulti={isMulti}
            options={options}
            onChange={onChange}
            className={`${innerClassName as string} w-100`}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            isDisabled={disabled}
            formatCreateLabel={(inputValue: string) =>
              `${placeholderCreatable ?? "Create"} "${inputValue}"`
            }
            onMenuClose={onMenuClose}
            onInputChange={onInputChange}
            isClearable={isClearable}
            styles={{
              control: (baseStyles: CSSObjectWithLabel) => ({
                ...baseStyles,
                borderRadius: "10px",
                boxShadow: "none",
                padding: "0 10px",
                margin: 0,
                borderColor: errors ? "#FB6056" : "#e6e6e6",
              }),
              valueContainer: (baseStyles: CSSObjectWithLabel) => ({
                ...baseStyles,
                padding: "10px 0px",
                margin: 0,
                fontSize: "1rem",
              }),
              option: (
                baseStyles: CSSObjectWithLabel,
                state: { isFocused: boolean }
              ) => ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#0F999B" : "white",
                color: state.isFocused ? "white" : "black",
                fontSize: "14px",
              }),
            }}
          />
          {(loading ?? false) && (
            <div
              className="spinner-border spinner-border-sm position-absolute text-primary"
              role="status"
              style={{
                right: "75px",
                top: "46px",
              }}
            ></div>
          )}
        </div>
      ) : (
        <Select
          id={id}
          isMulti={isMulti}
          options={options}
          onChange={onChange}
          className={innerClassName}
          placeholder={placeholder}
          defaultValue={defaultValue}
          isDisabled={disabled}
          value={value}
          onBlur={onBlur}
          isClearable={isClearable}
          styles={{
            control: (baseStyles: CSSObjectWithLabel) => ({
              ...baseStyles,
              borderRadius: "10px",
              boxShadow: "none",
              padding: "0 10px",
              margin: 0,
              borderColor: errors ? "#FB6056" : "#e6e6e6",
            }),
            valueContainer: (baseStyles: CSSObjectWithLabel) => ({
              ...baseStyles,
              padding: "10px 0px",
              margin: 0,
              fontSize: "1rem",
            }),
            option: (
              baseStyles: CSSObjectWithLabel,
              state: { isFocused: boolean }
            ) => ({
              ...baseStyles,
              backgroundColor: state.isFocused ? "#0F999B" : "white",
              color: state.isFocused ? "white" : "black",
              fontSize: "14px",
            }),
          }}
        />
      )}
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  text-align: left;

  /* .css-13cymwt-control {
        border: none;
        border-bottom: 1px solid #e0e0e0;
        border-radius: 0;
    }

    .css-art2ul-ValueContainer2 {
        padding: 0;
    }

    .css-1u9des2-indicatorSeparator {
        display: none;
    }

    .css-t3ipsp-control {
        &:hover {
            box-shadow: none;
            border: none;
            outline: none;
        }
    } */
`;
