import { Fragment } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

interface InputBasicProps {
  id: string;
  label: string;
  rules: {
    function: (name: any) => any;
    name: string;
  };
}

/**
 *
 * @returns
 * @param props
 */
export default function InputCustom(props: InputBasicProps): JSX.Element {
  const { id, label, rules } = props;

  return (
    <Fragment>
      <Form>
        <FormLabel>
            {label} <span className="req">*</span>
        </FormLabel>
        <Form.Check
          type="switch"
          id={id}
          {...rules.function(rules.name)}
        />
      </Form>
    </Fragment>
  );
}


const FormLabel = styled(Form.Label)`
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  .req {
    color: #fb6056;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;
