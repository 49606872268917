import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { IoClose } from "react-icons/io5";
import { Bars } from "react-loader-spinner";
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { uploadCertStudent } from "../../../redux/actions";

import UploadSingleFile from "components/Atoms/Inputs/UploadSingleFile";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

type PropsType = {
  title: string;
  selected: string;
  onHide: () => void;
  onConfirm: () => void;
  show: boolean;
};

const ItemModal = ({ onHide, title, show, onConfirm, selected }: PropsType) => {
  const {
    handleSubmit,
    control,
    clearErrors,
    resetField,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!show) {
      resetField("file")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const onSubmit = async (value: any) => {
    setLoading(true);
    const payload = new FormData();
    payload.append("user_course_id", selected);
    payload.append("file", value.file);
    const res = await uploadCertStudent(payload)
    if (res.status) {
      setLoading(false);
      onConfirm();
    }
    if (!res.status) {
      setLoading(false);
      toast.error(res.message);
    }
  }
  return (
    <Modal show={show} centered>
      <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex flex-column gap-4">
                <div className="content-top">
                  <h2 className={`title`}>{title}</h2>
                  <IoClose size={24} className="icon-close" onClick={onHide} />
                </div>
                <div className="content-center">
                  <Controller
                    name="file"
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      const checkError = !!error && !field.value;
                      const handleDropPdf = (acceptedFiles: any) => {
                        const file = acceptedFiles[0];

                        if (file) {
                          clearErrors("file");
                          field.onChange(
                            Object.assign(file, {
                              preview: URL.createObjectURL(file),
                            })
                          );
                        }
                      };
                      const handleRemovePdf = () => {
                        field.onChange("");
                      };
                      return (
                        <UploadSingleFile
                          file={field.value}
                          accept={{
                            "application/pdf": []
                          }}
                          error={checkError}
                          helperText={
                            checkError && (
                              <ErrorMessage
                                errors={errors}
                                name="content"
                                render={({ message }) => (
                                  <span
                                    className="invalid-feedback d-block text-start"
                                    style={{
                                      fontSize: "0.75rem",
                                    }}
                                  >
                                    {message}
                                  </span>
                                )}
                              />
                            )
                          }
                          onDrop={(e) => handleDropPdf(e)}
                          onRemove={() => handleRemovePdf()}
                        />
                      );
                    }}
                  />
                </div>
                <div className="content-bottom">
                  <div className="d-flex gap-3 justify-content-center">
                    <ButtonCancel type="button" onClick={onHide}>
                      Cancel
                    </ButtonCancel>
                    <ButtonSubmit type={loading ? "button" : "submit"}>
                      {loading ? (
                        <Bars
                          height="30"
                          width="30"
                          color="#fff"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      ) : (
                        "Upload Certificate"
                      )}
                    </ButtonSubmit>
                  </div>
                </div>
            </div>
          </form>
      </ModalBody>
    </Modal>
  );
};

const ModalBody = styled(Modal.Body)`
  padding: 35px 20px;

  .content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 0;
    }

    .icon-close {
      cursor: pointer;
    }
  }

  .content-center {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .text-desc {
      color: #303030;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
    }
  }
`;

const ButtonSubmit = styled.button`
  display: flex;
  width: 50%;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  text-transform: capitalize;

  &:hover {
    background: transparent;
    color: #0f999b;
    border: 1px solid #0f999b;
  }
`;

const ButtonCancel = styled.button`
  display: flex;
  width: 50%;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #0f999b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #0f999b;

  &:hover {
    background: #0f999b;
    color: #fff;
  }
`;

export default ItemModal;
