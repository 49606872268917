/* eslint-disable array-callback-return */
import { Form, Modal } from "react-bootstrap";
import styled from "styled-components";
import { IoClose } from "react-icons/io5";
import { Bars } from "react-loader-spinner";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { CSSObjectWithLabel } from "react-select";

import { createConsulRoom, getAllRoomConvertations } from "../../redux/actions";

import InputSearchSelect from "components/Atoms/Inputs/InputSearchSelect";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

type PropsType = {
  onHide: () => void;
  params: any;
  show: boolean;
};

const ModalCreate = ({ onHide, show, params }: PropsType) => {
  const dispatch = useDispatch();
  const method = useForm({
    mode: "onChange",
  });

  const { handleSubmit, control, watch, setError } = method;

  const data_students = useSelector((state: RootState) => state.students);
  const all_consultations = useSelector(
    (state: RootState) => state.consultations_admin
  );
  const [loading, setLoading] = useState(false);
  const [studentLists, setStudentLists] = useState<any[]>([]);
  const [consultationsLists, setConsultationsLists] = useState<any[]>([]);

  useEffect(() => {
    if (!data_students.loading && data_students.status === 200) {
      let temp: any[] = [];
      data_students.response.data.map((d: {
        id: string;
        full_name: string;
      }) => {
        temp.push({
          value: d.id,
          label: d.full_name,
        });
      });
      setStudentLists(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_students]);

  useEffect(() => {
    if (!all_consultations.loading && all_consultations.status === 200) {
      let temp: any[] = [];
      all_consultations.response.data.map((d: {
        id: string;
        name: string;
      }) => {
        temp.push({
          value: d.id,
          label: d.name,
        });
      });
      setConsultationsLists(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_consultations]);

  const onSubmit = async (value: any) => {
    setLoading(true);
    const payload = new FormData();
    payload.append("user_id", value.user_id);
    payload.append("consultation_id", value.consultation_id);

    const res = await createConsulRoom(payload);
    if (res.status) {
      setLoading(false);
      toast.success(res.message);
      dispatch(getAllRoomConvertations(params));
      onHide();
    }
    if (!res.status) {
      setLoading(false);
      Object.entries(res.errors).map((d: any) => {
        let message: string = d[1][0];
        setError(d[0], { message: message }, {
          shouldFocus: true
        });
      })
    }
    setLoading(false);
  };

  const basicStyles = {
    control: (
      baseStyles: CSSObjectWithLabel,
      state: { isFocused: boolean }
    ) => ({
      ...baseStyles,
      borderRadius: "10px",
      boxShadow: "none",
      padding: "0 10px",
      margin: 0,
    }),
    valueContainer: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      padding: "10px 0px",
      margin: 0,
      fontSize: "1rem",
    }),
    option: (
      baseStyles: CSSObjectWithLabel,
      state: { isFocused: boolean; isHover: boolean }
    ) => ({
      ...baseStyles,
      backgroundColor: state.isFocused || state.isHover ? "#0F999B" : "",
      color: state.isFocused ? "white" : "black",
      fontSize: "14px",
    }),
  };

  return (
    <Modal show={show} centered>
      <ModalBody>
        <div className="d-flex flex-column gap-4">
          <FormProvider {...method}>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="d-flex flex-column gap-3"
            >
              <div className="content-top">
                <h2 className={`title`}>Create Room Consultations</h2>
                <IoClose size={24} className="icon-close" onClick={onHide} />
              </div>
              <div className="content-center">
                <Controller
                  control={control}
                  name="user_id"
                  rules={{
                    required: "Students is required",
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    const checkError = !!error && !value;
                    return (
                      <div className="text-start mt-2">
                        <FormLabel>
                          Students
                          <small className="req">*</small>
                        </FormLabel>
                        <InputSearchSelect
                          placeholder="Select Students"
                          innerClassName="mb-1 "
                          styles={basicStyles}
                          options={studentLists}
                          isMulti={false}
                          value={studentLists.filter(
                            (item) => item.value === watch("user_id")
                          )}
                          onChange={(value: {
                            value: string;
                            label: string;
                          }) => {
                            onChange(value.value);
                          }}
                        />
                        {checkError && (
                          <span
                            className="invalid-feedback d-block text-start"
                            style={{
                              fontSize: "0.75rem",
                            }}
                          >
                            {error.message}
                          </span>
                        )}
                      </div>
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="consultation_id"
                  rules={{
                    required: "Consultations is required",
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    const checkError = !!error && !value;
                    return (
                      <div className="text-start mt-2">
                        <FormLabel>
                          Consultations
                          <small className="req">*</small>
                        </FormLabel>
                        <InputSearchSelect
                          placeholder="Select Consultations"
                          innerClassName="mb-1 "
                          styles={basicStyles}
                          options={consultationsLists}
                          isMulti={false}
                          value={consultationsLists.filter(
                            (item) => item.value === watch("consultation_id")
                          )}
                          onChange={(value: {
                            value: string;
                            label: string;
                          }) => {
                            onChange(value.value);
                          }}
                        />
                        {checkError && (
                          <span
                            className="invalid-feedback d-block text-start"
                            style={{
                              fontSize: "0.75rem",
                            }}
                          >
                            {error.message}
                          </span>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
              <div className="content-bottom">
                <div className="d-flex gap-3 justify-content-center">
                  <ButtonCancel type="button" onClick={onHide}>
                    Close
                  </ButtonCancel>
                  <ButtonSubmit type={loading ? "button" : "submit"}>
                    {loading ? (
                      <Bars
                        height="30"
                        width="30"
                        color="#fff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      `Create`
                    )}
                  </ButtonSubmit>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalCreate;

const ModalBody = styled(Modal.Body)`
  padding: 35px 20px;

  .content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 0;

      &.danger {
        color: #fb6056;
      }
      &.success {
        color: #06bd80;
      }
    }

    .icon-close {
      cursor: pointer;
    }
  }

  .content-center {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .icon-trash {
      color: #fb6056 !important;
    }
    .icon-success {
      color: #06bd80;
    }

    .text-desc {
      color: #303030;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
    }
  }
`;

const ButtonSubmit = styled.button`
  display: flex;
  width: 50%;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  text-transform: capitalize;
`;

const ButtonCancel = styled.button`
  display: flex;
  width: 50%;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #0f999b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #0f999b;
`;

const FormLabel = styled(Form.Label)`
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  .req {
    color: #fb6056;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;
