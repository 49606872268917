import { ReactNode } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';// @mui
import styled from 'styled-components';
import { AiOutlineFile } from "react-icons/ai";
import { bytesToMB } from 'utility/Utils';

// ----------------------------------------------------------------------
interface CustomFile extends File {
  path?: string;
  preview?: string;
}

interface UploadProps extends DropzoneOptions {
  error?: boolean;
  file: CustomFile | string | null;
  helperText?: ReactNode;
  onRemove: any;
}

// ----------------------------------------------------------------------

export default function UploadSingleFile({
  error = false,
  file,
  helperText,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  function showNameFile(fileOrString: File | string) {
    if (typeof fileOrString !== 'string') {
      // Check if it's a File object before accessing the 'name' property
      const fileName = (fileOrString as File).name;
      // Now you can safely use fileName
      return fileName;
    }
  }  

  return (
    <div style={{ width: '100%' }}>
      <DropZoneStyle
        {...getRootProps()}
      >
        <input {...getInputProps()} />

        {!file && (
          <ContentText>
            <AiOutlineFile size={30} />
            <h2>Drop or Choose a File</h2>
          </ContentText>
        )}

        {file && (
          <span>{showNameFile(file)}</span>
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 && fileRejections.map(({errors, file}) => {
        return (
          <>
            {errors.map((err) => (
              <span
                className="invalid-feedback d-block text-start"
                style={{
                  fontSize: "0.75rem",
                }}
              >
                {`File is larger than ${bytesToMB(file.size).toFixed(2)} Mb`}
              </span>
            ))}
          </>
        )
      })}

      {helperText && helperText}
    </div>
  );
}

const DropZoneStyle = styled.div`
  outline: none;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  backgroundColor: #fff;
  border: 1px dashed #0F999B;
  min-height: 250px;
  &:hover { 
    opacity: 0.72; 
    cursor: pointer 
  }
`;

const ContentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #0F999B;

  h2 {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
`;