import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getAuth } from 'utility/Utils';

/**
 * Function to handle private routes
 *
 * @param string title
 * @param JSX.Element children
 * @returns
 */
export const PrivateRoute = ({
    title,
    children,
}: {
    title?: string;
    children: JSX.Element;
    roles?: string[];
}): any => {
    const checkToken: boolean = getAuth() !== null;

    const location = useLocation();

    useEffect(() => {
        document.title = `${title ?? 'Home'} - ExcloDemy`;
    }, [title]);

    if (checkToken) {
        return children;
    }

    if (location.pathname !== undefined) {
        sessionStorage.setItem('redirectUrl', location.pathname + location.search);
    }

    return <Navigate to="/login" />;
};

/**
 * Function to handle public routes
 *
 * @param string title
 * @param JSX.Element children
 * @returns
 */
export const PublicRoute = ({
    title,
    children,
}: {
    title?: string;
    children: JSX.Element;
}): any => {
    useEffect(() => {
        document.title = `${title ?? 'Home'} - ExcloDemy`;
    }, [title]);

    const checkToken: any = getAuth() !== null;

    if (!checkToken) {
        return children;
    }

    return <Navigate to="/" />;
};
