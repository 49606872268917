import { ToastContainer } from 'react-toastify';
import Router from './routes';
import "./app.css";
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-circular-progressbar/dist/styles.css";

import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { encryptData } from "utility/Utils";

function App() {

  const [ searchParams ] = useSearchParams();
  const a = searchParams.get('a');

  useEffect(() => {
    if (a) {
      Cookies.set(
        "auth_2",
        encryptData(a)
      );
    }
  }, [a]);

  return (
    <>
      <ToastContainer />
      <Router />
    </>
  );
}

export default App;
