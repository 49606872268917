import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { IoClose } from "react-icons/io5";
import { Bars } from "react-loader-spinner";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";

import { updateAccount } from "./redux/actions";

import UploadSingleFile from "components/Atoms/Inputs/UploadSingleFile";
import { useSelector } from "react-redux";

type PropsType = {
  title: string;
  onHide: () => void;
  onConfirm: () => void;
  show: boolean;
};

const ItemModal = ({ onHide, title, show, onConfirm }: PropsType) => {
  const method = useForm({
    mode: "onChange",
  });
  
  const {
    handleSubmit,
    control,
    clearErrors,
    resetField,
  } = method;

  const [loading, setLoading] = useState(false);
  const data_account = useSelector((state: any) => state.my_account);

  useEffect(() => {
    if (!show) {
      resetField("file");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const onSubmit = async (value: any) => {
    setLoading(true);
    const payload = new FormData();
    if (window.location.pathname.includes("admin")) {
      payload.append("email", data_account.response.data.email);
      payload.append("first_name", data_account.response.data.first_name);
      payload.append("last_name", data_account.response.data.last_name);
      payload.append("image", value.file);
    }

    if (!window.location.pathname.includes("admin")) {
      payload.append("email", data_account.response.data.email);
      payload.append("first_name", data_account.response.data.first_name);
      payload.append("last_name", data_account.response.data.last_name);
      payload.append("role", data_account.response.data.role_id);
      payload.append("address", data_account.response.data.address);
      payload.append("image", value.file);
    }

    const res = await updateAccount(payload)
    if (res.status) {
      setLoading(false);
      onConfirm();
    }

    if (!res.status) {
      setLoading(false);
      toast.error(res.message);
    }
  };

  return (
    <Modal show={show} centered>
      <ModalBody>
        <FormProvider {...method}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex flex-column gap-4">
              <div className="content-top">
                <h2 className={`title`}>{title}</h2>
                <IoClose size={24} className="icon-close" onClick={onHide} />
              </div>
              <div className="content-center">
                <Controller
                  name="file"
                  control={control}
                  rules={{
                    required: "File is required",
                  }}
                  render={({ field, fieldState: { error } }) => {
                    const checkError = !!error && !field.value;
                    const handleDropPdf = (acceptedFiles: any) => {
                      const file = acceptedFiles[0];

                      if (file) {
                        clearErrors("file");
                        field.onChange(
                          Object.assign(file, {
                            preview: URL.createObjectURL(file),
                          })
                        );
                      }
                    };
                    const handleRemovePdf = () => {
                      field.onChange("");
                    };
                    return (
                      <div className="text-start">
                        <UploadSingleFile
                          file={field.value}
                          error={checkError}
                          accept={{
                            "image/*": []
                          }}
                          maxSize={2045728}
                          helperText={
                            checkError && (
                              <span
                              className="invalid-feedback d-block text-start"
                              style={{
                                fontSize: "0.75rem",
                              }}
                            >
                              {error.message}
                            </span>
                            )
                          }
                          onDrop={(e) => handleDropPdf(e)}
                          onRemove={() => handleRemovePdf()}
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <div className="content-bottom">
                <div className="d-flex gap-3 justify-content-end">
                  <ButtonCancel type="button" onClick={onHide}>
                    Cancel
                  </ButtonCancel>
                  <ButtonSubmit type={loading ? "button" : "submit"}>
                    {loading ? (
                      <Bars
                        height="30"
                        width="30"
                        color="#fff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      "Upload Photo"
                    )}
                  </ButtonSubmit>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

const ModalBody = styled(Modal.Body)`
  padding: 35px 20px;

  .content-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .title {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      margin-bottom: 0;
    }

    .icon-close {
      cursor: pointer;
    }
  }

  .content-center {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .text-desc {
      color: #303030;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 27px;
    }
  }
`;

const ButtonSubmit = styled.button`
  display: flex;
  width: 50%;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  text-transform: capitalize;
`;

const ButtonCancel = styled.button`
  display: flex;
  width: 50%;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #0f999b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #0f999b;

  &:hover {
    background: #0f999b;
    color: #fff;
  }
`;

export default ItemModal;
