import { useEffect } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useParams } from "react-router-dom";

import { AiOutlineClockCircle } from "react-icons/ai";
import { HiOutlineSpeakerWave } from "react-icons/hi2";

import Layout from "components/Organism/Layouts";
import AccordionCustom from "components/Organism/Accordion";
import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import { getCoursesDetails } from "./redux/actions";

import { TailSpin } from "react-loader-spinner";

export default function Home(): JSX.Element {
  const dispatch = useDispatch();
  let { namecourse } = useParams();

  const courseDetails = useSelector((state: RootState) => state.course_details);

  useEffect(() => {
    if (namecourse) {
      dispatch(getCoursesDetails(namecourse));
    }
  }, [dispatch, namecourse])

  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Course" />

        {!courseDetails.loading && courseDetails.status === 200 && (
          <ContainerCenter>
            <div className="content-top">
              <div className="center-content">
                <div className="course-title">
                  <h2>{courseDetails.response.name}</h2>
                  <p>
                  {courseDetails.response.description}
                  </p>
                </div>
                <div className="course-info">
                  <div className="course-info-title">
                    <img src={courseDetails.response.author.image} alt="logo" />
                    <div className="info-author">
                      <small>Created by</small>
                      <h5>{courseDetails.response.author.full_name}</h5>
                    </div>
                  </div>
                  <div className="time">
                    <AiOutlineClockCircle size={20} className="icon" />
                    <span>{courseDetails.response.duration_formatter}</span>
                  </div>
                  <div className="lang">
                    <HiOutlineSpeakerWave size={20} className="icon" />
                    <span>{courseDetails.response.language}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bottom-content">
              <AccordionCustom data={courseDetails.response.sections} />
            </div>
          </ContainerCenter>
        )}

        {courseDetails.loading && (
          <div className="d-flex justify-content-center align-items-center" style={{ padding: '100px 300px', minHeight: '100vh' }}>
                <TailSpin
                  height="200"
                  width="200"
                  color="#0F999B"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
          </div>
        )}
      </ContainerCustom>
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0;
  height: auto;
`;

const ContainerCenter = styled.div`
  position: relative;
  width: 100%;

  .content-top {
    padding: 40px;

    .center-content {
      display: flex;
      flex-direction: column;
  
      .course-title {
        color: #303030;
        h2 {
          font-size: 27px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
        }
  
        p {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 30px; 
        }
      }
  
      .course-info {
        display: flex;
        align-items: center;
        gap: 50px;
        margin-top: 2rem;
  
        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 50%;
        }
  
        .course-info-title {
          display: flex;
          align-items: center;
          .info-author {
            margin-left: 0.875rem;
  
            small {
              color: #6E6E6E;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              margin: 0;
            }
  
            h5 {
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 27px;
            }
          }
        }
  
        .time,
        .lang {
          display: flex;
          align-items: center;
          color: #6E6E6E;
  
          span {
            margin-left: 8px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
          }
        }
      }
    }

        .content-right {
      position: relative;

      .card {
        position: absolute;
        width: 100%;
        top: 100%;

        h3 {
          font-weight: 700;
          font-size: 32px;
          line-height: 30px;
          color: #0f284b;
          margin-bottom: 0.5rem;
        }

        .content-badge {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: center;
          background-color: #eaf3ff;
          border-radius: 6px;
          height: 50px;
          padding: 0px 1rem;
          margin: 10px 0;

          .left-side {
            display: flex;
            align-items: center;
            color: #524c96;

            h4 {
              font-size: 14px;
              font-weight: 600;
              margin-left: 6px;
              text-transform: capitalize;
            }
          }

          span {
            color: #0f284b;
            font-size: 10px;
            font-weight: 600;
            text-transform: uppercase;
          }
        }

        .title-bottom {
          text-align: center;
          margin: 0 0 8px;

          h4 {
            font-size: 1rem;
            font-weight: 600;
            margin-left: 6px;
            text-transform: capitalize;
            color: #0f284b;
          }
        }

        .content-card-list {
          ul {
            li {
              .list-content {
                font-size: 0.875rem;
                line-height: 30px;
                color: #2d343f;
                display: flex;
                align-items: center;

                span {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }


  .bottom-content {
    padding: 0px 40px;
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;
