import { axiosClient } from "services/GatewayService";
import { AppDispatch } from "redux/store";

import {
  GET_DETAILS_COURSE_REQUEST,
  GET_DETAILS_COURSE_SUCCESS,
  GET_DETAILS_COURSE_FAIL,
  VIEW_DETAILS_COURSE_REQUEST,
  VIEW_DETAILS_COURSE_SUCCESS,
  VIEW_DETAILS_COURSE_FAIL,
} from "../reducers";
import { toast } from "react-toastify";

/**
 * Function to get course details
 *
 * @param string id - id course
 * @return Promise<void>
 */
export const getCoursesDetails = (id: string): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: GET_DETAILS_COURSE_REQUEST });
    try {
      const res = await axiosClient.get(`/api/courses/view?course_id=${id}`);
      dispatch({
        type: GET_DETAILS_COURSE_SUCCESS,
        payload: res.data.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_DETAILS_COURSE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};

/**
 * Function to View course details
 *
 * @param string id - id course
 * @return Promise<void>
 */
export const viewCoursesDetails = (id: string): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: VIEW_DETAILS_COURSE_REQUEST });
    function checkTextEnding(text: string, keyword: string): boolean {
      const endIndex = text.length - keyword.length;
      return text.indexOf(keyword, endIndex) !== -1;
    }
    try {
      let url = `/api/courses/show-by-slug?course_slug=${id}`;
      if (checkTextEnding(id, 'consul')) {
        url = `/api/consultations/show-by-slug?consultation_slug=${id}`;
      }
      const res = await axiosClient.get(url);
      dispatch({
        type: VIEW_DETAILS_COURSE_SUCCESS,
        payload: res.data,
      });
    } catch (error: any) {
      dispatch({
        type: VIEW_DETAILS_COURSE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};

/**
 * Function to update watched
 *
 * @param string id - id course
 * @return Promise<void>
 */
export const updateWatched = async (id: string, checked: boolean) => {
  try {
    await axiosClient.post(`/api/watched/mark-content`, {
      course_content_id: id,
      checked: checked,
    });
    return true;
  } catch (error: any) {
    toast.error(error?.message);
    return false;
  }
};

/**
 * Function to create order class
 *
 * @return Promise<void>
 */
export const createOrderClass = async (payload: any) => {
  try {
    const res = await axiosClient.post(`/api/orders`, {
      ...payload,
    });
    return res.data;
  } catch (error: any) {
    if (error.response.data.message === 
      "Failed, user already buy it") {
        toast.error('Kursus sudah pernah dibeli');
      } else {
        toast.error('Gagal Pembelian course silakan tunggu sebentar lagi');
      }
    return false;
  }
};

/**
 * Function to create order consul
 *
 * @return Promise<void>
 */
export const createOrderConsul = async (payload: any) => {
  try {
    const res = await axiosClient.post(`/api/orders/consultation`, {
      ...payload,
    });
    return res.data;
  } catch (error: any) {
    if (error.response.data.message === 
      "Failed, user already buy it") {
        toast.error('Kursus sudah pernah dibeli');
      } else {
        toast.error('Gagal Pembelian course silakan tunggu sebentar lagi');
      }
    return false;
  }
};
