import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { BsTrash, BsChat, BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineCreditCard } from "react-icons/ai";

const handleColor = (key: string) => {
  if (key === "create") {
    return "#06BD80";
  }
  if (key === "view") {
    return "#4070FF";
  }
  if (key === "delete") {
    return "#FB6056";
  }
}

const TableRow = (props: any) => {
  return (
    <tr style={{ padding: "20px 0"}}>
      {props.columns
        ? Object.entries(props.columns).map((value: any) =>
            value[0] !== "action" ? (
              value[0] === "id" || value[0] === "user_course_id" || value[0] === "user_consultation_id" ? null : value[0] === "payment_status" ? (
                <TdStatus key={uuidv4()} value={value[1].toLowerCase()}>
                  {value[1]}
                </TdStatus>
              ) : value[0] === "class_status" ? (
                <TdClass key={uuidv4()} value={value[1]}>
                  {value[1] === 0 ? "On Going" : "Completed"}
                </TdClass>
              ) : value[0] === "consultation_status" ? (
                <TdClass key={uuidv4()} value={value[1]}>
                  {value[1] === 0 ? "On Going" : "Done"}
                </TdClass>
              ) : value[0] === "certificate" ? (
                <Td key={uuidv4()}>
                  {value[1] === "" ? (
                    "-"
                  ) : value[1] === null ? (
                    <ContentUpload onClick={props.handleUpload}>
                      Upload Certificate
                    </ContentUpload>
                  ) : (
                    <A rel="noopener noreferrer" target="_blank" href={value[1].url}>
                      {value[1].name}
                    </A>
                  )}
                </Td>
              ) : (
                <Td key={uuidv4()}>
                  {value[1]}
                </Td>
              )
            ) : value[1] !== null ? (
              <Td key={uuidv4()}>
                <Dropdown>
                  <DropdownToggle id="dropdown-autoclose-true">
                    <BsThreeDotsVertical size={18} />
                  </DropdownToggle>

                  <DropdownMenuCustom>
                    {props.viewAction ? (
                      <DropdownItem
                        href="#"
                        onClick={props.viewAction}
                        type="view"
                      >
                        <BsChat size={18} />
                        {props.viewTitle}
                      </DropdownItem>
                    ) : null}
                    {props.createAction ? (
                      <DropdownItem
                        href="#"
                        onClick={props.createAction}
                        type="create"
                      >
                        <AiOutlineCreditCard size={18} />
                        {props.createTitle}
                      </DropdownItem>
                    ) : null}
                    {props.deleteAction ? (
                      <DropdownItem
                        href="#"
                        onClick={props.deleteAction}
                        type="delete"
                      >
                        <BsTrash size={18} />
                        {props.deleteTitle}
                      </DropdownItem>
                    ) : null}
                  </DropdownMenuCustom>
                </Dropdown>
              </Td>
            ) : null
          )
        : null}
    </tr>
  );
};

export default TableRow;

const Td = styled.td`
  color: #303030 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  padding: 20px 0 !important;
`;

const TdClass = styled.td<{value: number}>`
  color: ${(p) => p.value === 1 ? "#06BD80" : "#FFCB45"} !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  padding: 20px 0 !important;
  text-transform: capitalize;
`;

const TdStatus = styled.td<{value: string}>`
  color: ${(p) => p.value === "active" || p.value === "paid" ? "#06BD80" : "#FB6056"} !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  padding: 20px 0 !important;
  text-transform: capitalize;
`;

const DropdownToggle = styled(Dropdown.Toggle)`
  border: none;
  background: transparent !important;
  color: #303030 !important;

  &:focus,
  &:hover,
  &:active,
  &:focus-visible {
    box-shadow: none;
    background: transparent !important;
    border: none;
    color: #303030 !important;
  }

  &:after {
    display: none;
  }
`;

const DropdownMenuCustom = styled(Dropdown.Menu)`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e6e6e6 !important;
  background: #fff;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
`;

const DropdownItem = styled(Dropdown.Item)<{type: string}>`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(p) => (handleColor(p.type))} !important;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.24px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    border-radius: 5px;
    background: transparent !important;
    color: ${(p) => (handleColor(p.type))} !important;
  }
`;

const ContentUpload = styled.span`
  color: #4070FF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  text-decoration-line: underline;
  cursor: pointer;
`;

const A = styled.a`
  color: #4070FF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  text-decoration-line: underline;
  cursor: pointer;

  &:hover {
    color: #4070FF !important;
    background: transparent !important;
  }
`;