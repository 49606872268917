import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Container, Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { BsChatLeftText, BsEmojiDizzy } from "react-icons/bs";
import { BiCommentAdd, BiChat, BiRefresh } from "react-icons/bi";
import {
  AiOutlineSearch,
  AiOutlineEye,
} from "react-icons/ai";
import { MdArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import AccordionCustom from "components/Organism/Accordion";
import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import Layout from "components/Organism/Layouts";
import Play from "assets/icons/icon-play.svg";
import PlayColor from "assets/icons/play.svg";

import { toast } from "react-toastify";
import { RootState } from "redux/store";

import {
  getCoursesDetails,
  updateWatched,
} from "../CourseDetails/redux/actions";
import { getComments, addAsk } from "./redux/actions";

type DataComment = {
  id: string;
  created_at: Date;
  count_replies: number;
  comment: string;
  user: UserType;
  replies: RepliesType[];
};

type RepliesType = {
  id: string;
  created_at: Date;
  count_replies: number;
  comment: string;
  user: UserType;
};

type UserType = {
  full_name: string;
  image_url: string;
};

const DEBOUNCE_THRESHOLD = 500;

export default function Playlist(): JSX.Element {
  let { namecourse, sections, contents } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const videoRef = useRef<HTMLVideoElement>(null);
  const timeoutHandler: any = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [show, setShow] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showAnswerCard, setShowAnswerCard] = useState(false);
  const [answerCardIndex, setAnswerCardIndex] = useState("");
  const [editorState, setEditorState] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [parrentId, setParrentId] = useState("");
  const [titleLesson, setTitleLesson] = useState("");
  const [autoPlay, setAutoPlay] = useState("0");
  const [params, setParams] = useState({
    limit: 5,
    course_content_id: "",
    parent_id: "",
    search: "",
  });

  const courseDetails = useSelector((state: RootState) => state.course_details);
  const commentsData = useSelector((state: RootState) => state.comments);

  useEffect(() => {
    let valueAutoPlay = Cookies.get("autoplay");
    if (valueAutoPlay) {
      setAutoPlay(valueAutoPlay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sections && contents && courseDetails.status === 200) {
      fetchVideo();
      fetchComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, contents]);
  
  useEffect(() => {
    if (!courseDetails.loading && courseDetails.status === 200 && videoUrl === "") {
      fetchVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseDetails, videoUrl]);

  useEffect(() => {
    if (namecourse && !courseDetails.status) {
      dispatch(getCoursesDetails(namecourse));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [videoUrl]);

  const fetchVideo = async () => {
    try {
      videoRef.current?.load();
      if (sections && contents) {
        const url_video =
          courseDetails.response.sections[parseInt(sections)].contents[
            parseInt(sections)
          ].video_url;
        const title =
          courseDetails.response.sections[parseInt(sections)].contents[
            parseInt(sections)
          ].name;

        setVideoUrl(url_video);
        setTitleLesson(title);
      }
    } catch (error: any) {
      return toast.error(error?.message);
    }
  };

  const fetchComments = async () => {
    try {
      if (sections && contents) {
        const id_course_content =
          courseDetails.response.sections[parseInt(sections)].contents[
            parseInt(sections)
          ].id;
        setParams((prev) => ({
          ...prev,
          course_content_id: id_course_content,
        }));

        const payload = {
          ...params,
          course_content_id: id_course_content,
        };

        await dispatch(getComments(payload));
      }
    } catch (error: any) {
      return toast.error(error?.message);
    }
  };

  // const handleBack = () => {
  //   navigate(`/course/${namecourse}`);
  // };

  const handleClose = () => {
    setEditorState("");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleModalAnswerCard = () => setShowAnswerCard(!showAnswerCard);
  const handleModalCloseAnswer = () => {
    setParrentId("");
    setEditorState("");
    setShowAnswer(!showAnswer);
  };
  const handleModalAnswer = (id: string) => {
    setParrentId(id);
    setShowAnswer(!showAnswer);
  };

  const timeFormat = (time: Date) => {
    const timeData = moment(time);
    return timeData.fromNow();
  };

  /**
   * Function for next button
   *
   */
  const showNextContent = (): void => {
    if (sections && contents) {
      let contentsTemp = parseInt(contents);
      let sectionsTemp = parseInt(sections);

      if (
        parseInt(contents) <
        courseDetails.response.sections[parseInt(sections)].contents.length - 1
      ) {
        contentsTemp = parseInt(contents) + 1;
      } else if (
        parseInt(sections) <
        courseDetails.response.sections.length - 1
      ) {
        sectionsTemp = parseInt(sections) + 1;
        contentsTemp = 0;
      }
      navigate(`/course/${namecourse}/${sectionsTemp}/lessons/${contentsTemp}`);
    }
  };

  /**
   * Function for previous button
   *
   */
  const showPreviousContent = () => {
    if (sections && contents) {
      let contentsTemp = parseInt(contents);
      let sectionsTemp = parseInt(sections);

      if (parseInt(contents) > 0) {
        contentsTemp = parseInt(contents) - 1;
      } else if (parseInt(sections) > 0) {
        sectionsTemp = parseInt(sections) - 1;
        contentsTemp =
          courseDetails.response.sections[parseInt(sections)].contents.length -
          1;
      }
      navigate(`/course/${namecourse}/${sectionsTemp}/lessons/${contentsTemp}`);
    }
  };

  /**
   * Function for next button
   *
   */
  const autoNextContent = async () => {
    if (autoPlay === "1") {
      await handleUpdateWatched();

      if (sections && contents) {
        let contentsTemp = parseInt(contents);
        let sectionsTemp = parseInt(sections);

        if (
          parseInt(contents) <
          courseDetails.response.sections[parseInt(sections)].contents.length -
            1
        ) {
          contentsTemp = parseInt(contents) + 1;
        } else if (
          parseInt(sections) <
          courseDetails.response.sections.length - 1
        ) {
          sectionsTemp = parseInt(sections) + 1;
          contentsTemp = 0;
        }
        navigate(
          `/course/${namecourse}/${sectionsTemp}/lessons/${contentsTemp}`
        );
      }
    } 
    if (autoPlay === "0") {
      await handleUpdateWatched();
    }
  };

  const handleUpdateWatched = async () => {
    if (sections && contents) {
      let contentId = courseDetails.response.sections[parseInt(sections)].contents[parseInt(contents)].id;
      
      const res = await updateWatched(contentId, true);
  
      if (res && namecourse) {
        dispatch(getCoursesDetails(namecourse));
      }
    }
  };

  const playVideo = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      if (video.paused) {
        video.play();
        setIsPlaying(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  }

  const plyrVideo =
    videoUrl && videoRef ? (
      <video
        ref={videoRef}
        controls
        onEnded={autoNextContent}
        playsInline
        controlsList="nodownload"
        style={{ width: "100%", height: "100%" }}
        autoPlay={autoPlay === "1"}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
    ) : null;

  // const handleAutoPlay = (event: React.FormEvent<HTMLInputElement>) => {
  //   const { checked } = event.target as unknown as { checked: boolean };
  //   setAutoPlay(checked ? "1" : "0");
  //   Cookies.set("autoplay", checked ? "1" : "0");
  // };

  const handleLoadMore = async () => {
    try {
      if (sections && contents) {
        setParams((prev) => ({
          ...prev,
          limit: prev.limit + 5,
        }));

        const payload = {
          ...params,
          limit: params.limit + 5,
        };

        await dispatch(getComments(payload));
      }
    } catch (error: any) {
      return toast.error(error?.message);
    }
  };

  const handleAddAsk = async (key: string) => {
    if (editorState !== "") {
      let res;

      if (key === "ask") {
        const payload = {
          comment: editorState,
          course_content_id: params.course_content_id,
        };

        res = await addAsk(payload);
      }
      if (key === "answer") {
        const payload = {
          comment: editorState,
          course_content_id: params.course_content_id,
          comment_parent_id: parrentId,
        };
        res = await addAsk(payload);
      }

      if (res) {
        fetchComments();
        setEditorState("");
      }

      if (key === "ask") {
        handleClose();
      }
      if (key === "answer") {
        handleModalCloseAnswer();
      }
    }

    if (editorState === "") {
      setErrorMessage("This field is required");
    }
  };

  const handleChangeInput = async (value: string) => {
    setParams((prev) => ({
      ...prev,
      search: value,
    }));

    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
    }

    timeoutHandler.current = setTimeout(() => {
      const payload = {
        ...params,
        search: value,
      };

      dispatch(getComments(payload));
    }, DEBOUNCE_THRESHOLD);
  };

  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Course" />
        {courseDetails.status === 200 && (
          <ContainerCenter className="row mx-0">
            <div className="col-lg-3 playlist">
              {/* <div className="card card-top">
                <div className="back-btn" onClick={handleBack}>
                  <FaArrowLeft size={16} />
                  <span className="text">Course</span>
                </div>
                <div className="title-center">
                  <CircularProgressbarWithChildren
                    value={courseDetails.response.percentage_progress}
                    styles={buildStyles({
                      pathColor: "#0F999B",
                    })}
                  >
                    <SlBadge size={24} />
                  </CircularProgressbarWithChildren>
                </div>
                <div className="title-right">
                  <span>Autoplay</span>
                  <Switch
                    className="checked:bg-[#0F999B]"
                    onChange={handleAutoPlay}
                    checked={autoPlay === "1"}
                  />
                </div>
              </div> */}

              <AccordionCustom data={courseDetails.response.sections} />
            </div>
            <div className="col-lg-9 video-panel">
              <div className="video-container min-h-100">
                {plyrVideo}
                {!isPlaying ? null : (
                  <div onClick={playVideo} className="player-buttons"></div>
                )}
              </div>

              <div className="lesson-head">
                <div className="head-left">
                  <img src={PlayColor} alt="play" width={21} height={21}/>
                  <h4>{titleLesson}</h4>
                </div>
                <div className="head-right">
                  <button
                    className="btn-prev"
                    type="button"
                    onClick={showPreviousContent}
                    disabled={sections === "0" && contents === "0"}
                  >
                    <MdOutlineArrowBackIos />
                    <span>Previous</span>
                  </button>

                  <button
                    className="btn-next"
                    type="button"
                    onClick={showNextContent}
                    disabled={
                      parseInt(sections ? sections : "0") ===
                        courseDetails.response.sections.length - 1 &&
                      parseInt(contents ? contents : "0") ===
                        courseDetails.response.sections[
                          parseInt(contents ? contents : "0")
                        ].contents.length -
                          1
                    }
                  >
                    <span>Next</span>
                    <MdArrowForwardIos />
                  </button>
                </div>
              </div>
              <div className="questions p-4 row mx-0 d-flex justify-content-between">
                <div className="col-lg-7">
                  <div className="w-100 d-flex align-items-center">
                    <div className="position-relative">
                      <input
                        placeholder="Search all course questions"
                        className="input-filter"
                        onChange={(e) => handleChangeInput(e.target.value)}
                      />
                      <AiOutlineSearch size={24} className="icon-search-filter" />
                    </div>
                  </div>
                  <div className="d-flex align-items-center w-100"></div>
                </div>
                <div className="col-lg-5 d-flex justify-content-end pe-0">
                  <ButtonCustom onClick={handleShow}>
                    <BiCommentAdd size={21} />
                    <span>Ask a New Question</span>
                  </ButtonCustom>
                </div>

                {!commentsData.loading &&
                commentsData.status === 200 &&
                commentsData.response.data.length > 0 ? (
                  commentsData.response.data.map((d: DataComment) => (
                    <div key={d.id} className="col-lg-12 mt-4 container-ask">
                      <div className="content-ask">
                        <div className="ask-profile">
                          <div>
                            <img
                              src={d.user.image_url}
                              alt="person"
                              className="img-person"
                            />
                          </div>
                          <div className="ask-title">
                            <h4 className="mb-2">{d.user.full_name}</h4>
                            <span
                              className=""
                              dangerouslySetInnerHTML={{
                                __html: d.comment,
                              }}
                            />
                          </div>
                        </div>
                        <div className="ask-info">
                          <small>{timeFormat(d.created_at)}</small>
                          <div className="sum-answer">
                            <BsChatLeftText size={18} className="icon-chat" />
                            <h2>{d.count_replies}</h2>
                          </div>
                        </div>
                      </div>

                      {!showAnswerCard && answerCardIndex !== d.id ? (
                        <div className="card-answer mt-5">
                          {d.replies.length === 0 ? (
                            <div className="not-replies">
                              <BsEmojiDizzy size={21} />
                              <span className="text">Not answered yet.</span>
                            </div>
                          ) : (
                            <div
                              className="btn-left"
                              onClick={() => {
                                setAnswerCardIndex(d.id);
                                handleModalAnswerCard();
                              }}
                            >
                              <AiOutlineEye size={18} />
                              <span>View Details</span>
                            </div>
                          )}
                          <div
                            className="btn-right"
                            onClick={() => handleModalAnswer(d.id)}
                          >
                            <BiChat size={18} />
                            <span>Add Answer</span>
                          </div>
                        </div>
                      ) : (
                        <div className="card-answer-show mt-5 p-4">
                          {d.replies.map((ans, idx) => (
                            <div key={ans.id} className="content-ask">
                              <div className="ask-profile">
                                <div>
                                  <img
                                    src={ans?.user?.image_url}
                                    alt="person"
                                    className="img-person"
                                  />
                                </div>
                                <div className="ask-title">
                                  <h4 className="mb-2">{ans.user.full_name}</h4>
                                  <span
                                    className=""
                                    dangerouslySetInnerHTML={{
                                      __html: ans.comment,
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="ask-info">
                                <small>1 weeks ago</small>
                              </div>
                            </div>
                          ))}
                          <div className="card-footer">
                            <div
                              className="btn-left"
                              onClick={() => {
                                setAnswerCardIndex("");
                                handleModalAnswerCard();
                              }}
                            >
                              <AiOutlineEye size={18} />
                              <span>Hide Details</span>
                            </div>
                            <div
                              className="btn-right"
                              onClick={handleModalCloseAnswer}
                            >
                              <BiChat size={18} />
                              <span>Add Answer</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <CardNotComment className="mt-5 p-4">
                    <BsEmojiDizzy size={23} />
                    <span className="text">No questions were asked yet.</span>
                  </CardNotComment>
                )}
              </div>

              {!commentsData.loading &&
                commentsData.status === 200 &&
                commentsData.response.count_all > 5 && (
                  <div className="d-flex mt-5 justify-content-center align-items-center">
                    <ButtonCustom onClick={handleLoadMore}>
                      <BiRefresh />
                      <span>Load More</span>
                    </ButtonCustom>
                  </div>
                )}
            </div>
          </ContainerCenter>
        )}

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton className="border-bottom-0">
            <Modal.Title>Ask a Question</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column h-75 gap-3">
              <CKEditor
                editor={ClassicEditor}
                data={editorState}
                config={{
                  placeholder: "Type here your question",
                  toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' ],
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorState(data);
                  setErrorMessage("");
                }}
              />
              {errorMessage !== "" && (
                <span
                  style={{
                    color: "#f44336",
                  }}
                >
                  {errorMessage}
                </span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-top-0">
            <ButtonClose onClick={handleClose}>Close</ButtonClose>
            <ButtonCustom onClick={() => handleAddAsk("ask")}>Save</ButtonCustom>
          </Modal.Footer>
        </Modal>

        <Modal show={showAnswer} onHide={handleModalCloseAnswer} centered>
          <Modal.Header closeButton className="border-bottom-0">
            <Modal.Title>Add a New Answer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column h-75 gap-3">
              <CKEditor
                editor={ClassicEditor}
                data={editorState}
                config={{
                  placeholder: "Type here your question",
                  toolbar: [ 'heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote' ],
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setEditorState(data);
                  setErrorMessage("");
                }}
              />
              {errorMessage !== "" && (
                <span
                  style={{
                    color: "#f44336",
                  }}
                >
                  {errorMessage}
                </span>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-top-0">
            <ButtonClose onClick={handleModalCloseAnswer}>Close</ButtonClose>
            <ButtonCustom onClick={() => handleAddAsk("answer")}>
              Save
            </ButtonCustom>
          </Modal.Footer>
        </Modal>
      </ContainerCustom>
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0;
`;

const ContainerCenter = styled.div`
  .playlist {
    height: auto !important;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #DBDBDB;
  }

  .video-panel {
    background: #fff;
    padding: 0;

    .video-container {
      position: relative;
      background: #000000;
      width: 100% !important;
      box-shadow: none !important;

      .player-buttons {
        background: url(${Play}) center center no-repeat;
        height: 128px;
        left: 50%;
        margin: -64px 0 0 -64px;
        position: absolute;
        top: 50%;
        width: 128px;
        z-index: 1; 
      }
    }

    .lesson-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding: 12px 22px;
      align-items: center;

      .head-left {
        display: flex;
        align-items: center;
        color: #303030;

        h4 {
          margin-left: 0.3rem;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
          color: #303030;
          text-transform: capitalize;
          overflow-wrap: anywhere;
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content;
          margin-bottom: 0px;
        }
      }

      .head-right {
        display: flex;

        .btn-prev,
        .btn-next {
          display: flex;
          align-items: center;
          margin: 0 5px;
          background-color: #0f999b;
          padding: 10px;
          border-radius: 5px;
          color: #fff;

          span {
            margin-left: 0.15rem;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            text-transform: capitalize;
          }
        }
      }
    }

    .questions {
      background-color: rgba(255, 255, 255, 1) !important;

      .container-ask {
        .content-ask {
          display: flex;
          justify-content: space-between;

          .ask-profile {
            display: flex;
            gap: 2rem;
            width: 85%;

            img {
              border-radius: 50%;
              height: 50px;
              width: 50px;
              object-fit: cover;
              margin-right: 2rem;
            }

            .ask-title {
              padding: 0px 100px 0px 0px;

              h4 {
                font-style: normal;
                font-weight: 600;
                font-size: 12.5px;
                color: #303030;
              }
            }
          }

          .ask-info {
            padding: 0px 10px;

            small {
              font-style: normal;
              font-weight: 400;
              font-size: 11.5px;
              color: #303030;
            }

            .sum-answer {
              display: flex;
              align-items: center;

              .icon-chat {
                color: #303030;
              }

              h2 {
                margin-left: 0.2rem;
                font-style: normal;
                font-weight: 400;
                font-size: 27.5px;
                text-align: right;
                color: #303030;
              }
            }
          }
        }

        .card-answer {
          width: 95%;
          margin-left: 63px;
          margin-top: 25px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          box-sizing: border-box;
          height: 61px;
          background: #fff;
          box-shadow: 0 1px 3px #11294f80;
          border-radius: 6px;

          .not-replies {
            padding-left: 25px;
            display: flex;
            align-items: center;
            color: #303030;

            .text {
              margin-left: 0.4rem;
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
            }
          }

          .btn-left {
            display: flex;
            flex-direction: row;
            justify-content: center;
            text-decoration: none;
            cursor: pointer;
            align-items: center;
            padding-left: 25px;

            span {
              margin-left: 0.15rem;
              font-style: normal;
              font-weight: 600;
              font-size: 13px;
              color: #303030;
              text-transform: capitalize;
            }
          }

          .btn-right {
            display: flex;
            flex-direction: row;
            justify-content: center;
            text-decoration: none;
            cursor: pointer;
            align-items: center;
            padding-right: 25px;
            color: #0f999b;

            span {
              margin-left: 0.15rem;
              font-style: normal;
              font-weight: 600;
              font-size: 13px;
              text-transform: capitalize;
            }
          }
        }

        .card-answer-show {
          width: 95%;
          margin-left: 63px;
          margin-top: 25px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          box-sizing: border-box;
          height: auto;
          background: #fff;
          box-shadow: 0 1px 3px #11294f80;
          border-radius: 6px;

          .content-ask {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            padding-bottom: 20px;
          }

          .card-footer {
            display: flex;
            justify-content: flex-end;
            padding-top: 20px;
            .btn-left {
              display: flex;
              flex-direction: row;
              justify-content: center;
              text-decoration: none;
              cursor: pointer;
              align-items: center;
              padding-left: 25px;
              margin-right: 20px;

              span {
                margin-left: 0.15rem;
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                color: #303030;
                text-transform: capitalize;
              }
            }

            .btn-right {
              display: flex;
              flex-direction: row;
              justify-content: center;
              text-decoration: none;
              cursor: pointer;
              align-items: center;
              padding-right: 25px;
              color: #0f999b;

              span {
                margin-left: 0.15rem;
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const ButtonCustom = styled.button`
  background-color: #0f999b;
  color: #fff;
  border-radius: 5px;
  border: none !important;
  display: flex;
  align-items: center;
  padding: 12px 20px !important;

  span {
    margin-left: 0.15rem;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }

  &:hover {
    background-color: #0f999b;
  }
`;

const ButtonClose = styled.button`
  border: 1px solid #0f999b !important;
  color: #0f999b;
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 12px 20px !important;

  span {
    margin-left: 0.15rem;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
`;

const CardNotComment = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: auto;
  background: #fff;
  box-shadow: 0 1px 3px #11294f80;
  border-radius: 6px;
  color: #303030;
  min-height: 8rem;

  .text {
    margin-left: 0.4rem;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
  }
`;
