import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

interface PropsTableHeader {
  data: string[];
}

export const TableHeader = (props: PropsTableHeader): JSX.Element => {
  return (
    <thead>
      <tr className="nk-block-head-content">
        {props.data
          ? props.data.map((title) => (
              <Th key={uuidv4()}>
                {title}
              </Th>
            ))
          : ""}
      </tr>
    </thead>
  );
};

const Th = styled.th`
  color: #303030 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  padding-bottom: 15px !important;
  padding-left: 0 !important;
`;