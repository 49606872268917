import styled from "styled-components";
import { useCallback, useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { toast } from "react-toastify";

import { RootState } from "redux/store";
import { useDispatch, useSelector } from "react-redux";
import { getConsultations, deleteConsul } from "../redux/actions";

import Pagination from "components/Atoms/Paginations";
import { TableHeader } from "components/Atoms/Tables/header";
import { TableRow } from "components/Atoms/Tables/row";
import InputCustom from "components/Atoms/Inputs/InputCustom";
import ModalCustom from "components/Organism/Modal";
import { fCurrency } from "utility/Utils";


type PropsHandlePage = {
  selected: number;
};

type TypeDataConsul = {
  id: string;
  name: string;
  class: string;
  mentor: {
    name: string;
  };
  amount: string;
  duration: number;
  status: boolean;
};

let tableTitle = [
  "Consultation Name",
  "Class",
  "Mentor",
  "Price",
  "Active Duration",
  "Status",
  "Action",
];

export default function HomeAdmin(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [datas, setDatas] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [selected, setSelected] = useState("");

  const [params, setParams] = useState({
    with_pagination: true,
    limit: "10",
    page: 1,
    search: "",
  });

  const all_consultations = useSelector(
    (state: RootState) => state.consultations_admin
  );

  useEffect(() => {
    dispatch(getConsultations(params));
  }, [dispatch, params]);

  useEffect(() => {
    if (!all_consultations.loading && all_consultations.status === 200) {
      formatData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_consultations]);

  const formatData = () => {
    let obj = {};
    // eslint-disable-next-line array-callback-return
    all_consultations.response.data.map((d: TypeDataConsul, i: number) => {
      let oneRow = {
        id: d.id,
        name: d.name,
        class: d.class,
        mentor: d.mentor.name,
        prices: fCurrency(d.amount),
        duration: d.duration,
        status: d.status ? "active" : "closed",
        action: "",
      };

      obj = { ...obj, [i]: oneRow };
    })
    setDatas(obj);
  };

  /**
   * Function to handle search with callback
   *
   * @returns void
   */
  const handleDebouncedSearch = useCallback(() => {
    setParams({
      ...params,
      search: searchValue,
    });

    const payload = {
      ...params,
      search: searchValue,
    };

    dispatch(getConsultations(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  // Function for handle debounce search
  useEffect(() => {
    const debounce = setTimeout(() => {
      handleDebouncedSearch();
    }, 500);

    return () => {
      clearTimeout(debounce);
    };
  }, [handleDebouncedSearch]);

  const handlePage = async ({ selected }: PropsHandlePage) => {
    setParams((prev) => ({
      ...prev,
      page: selected + 1,
    }));

    const payload = {
      ...params,
      page: selected + 1,
    };
    await dispatch(getConsultations(payload));
  };

    /**
   * Function to handle search
   *
   * @param string value
   * @returns void
   */
    const handleSearch = useCallback((value: string): void => {
      setSearchValue(value);
    }, []);

  const handleNavigate = (key: string) => {
    navigate(`${key}`);
  };

  const handleModal = () => setShowModal(!showModal);
  
  const closeModal = (): void => {
    setSelected("");
    handleModal();
  };

  const confirmDelete = async () => {
    setLoading(true);
      const res = await deleteConsul({ id: selected })
      if (res.status) {
        setSuccess(true);
        setLoading(false);
        closeModal();
        dispatch(getConsultations(params));
      }
      if (!res.status) {
        setLoading(false);
        toast.error(res.message);
      }
  }

  const openModal = (key: string) => {
    setSelected(key);
    handleModal();
  };

  return (
    <ContainerCenter>
      <div className="content-search d-flex justify-content-end">
        <div className="w-25">
          <InputCustom
            id="search"
            type="text"
            placeholder="Search consultation..."
            icon={<IoSearchOutline size={20} />}
            onChange={(e) => {
              handleSearch?.(e.target.value);
            }}
          />
        </div>
      </div>

      {all_consultations.loading && (
        <div className="h-100 d-flex justify-center align-items-center">
          <TailSpin
            height="200"
            width="200"
            color="#0F999B"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}

      {!all_consultations.loading && all_consultations.status === 200 && (
        <table className="table mt-4">
          <TableHeader data={tableTitle} />
          <tbody>
            {datas
              ? Object.values(datas).map((columns: any) => (
                  <TableRow
                    key={columns.id}
                    columns={columns}
                    editTitle="Edit Consultation"
                    editAction={() =>
                      handleNavigate(`/admin/consultation/edit/${columns.id}`)
                    }
                    deleteTitle="Delete Consultation"
                    deleteAction={() => openModal(columns.id)}
                  />
                ))
              : ""}
          </tbody>
        </table>
      )}

      {!all_consultations.loading && all_consultations.status === 200 && (
        <Pagination
          pageCount={all_consultations.response.meta.last_page}
          forcePage={all_consultations.response.meta.current_page}
          onPageChange={handlePage}
        />
      )}
      
      <ModalCustom
        show={showModal}
        onHide={closeModal}
        onConfirm={confirmDelete}
        loading={loading}
        type="delete"
        title="Delete Consultation?"
        key_component="Consultation"
        desc="Are you sure want to delete this consultation?, you will not have access to this consultation anymore."
      />
      
      <ModalCustom
        show={success}
        key_component="Course"
        onHide={() => setSuccess(!success)}
        type="success"
        title="Success"
        desc="Successfully delete consultation"
      />

    </ContainerCenter>
  );
}

const ContainerCenter = styled.div`
  padding: 30px 40px;
  position: relative;
  min-height: 90vh;
  margin-bottom: 50px;

  .center-content {
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;
