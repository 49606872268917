/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";
import { CSSObjectWithLabel } from "react-select";
import { TailSpin, Bars } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import {
  getOneCourse,
  updateCourse,
  getAllClassesAdmin,
} from "pages/Home/redux/actions";
import { getAllMentors } from "pages/Admin/MentorManagement/redux/actions";
import { RootState } from "redux/store";

import InputBasic from "components/Atoms/Inputs/InputBasic";
import Layout from "components/Organism/Layouts";
import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import InputSearchSelect from "components/Atoms/Inputs/InputSearchSelect";
import InputCurrency from "components/Atoms/Inputs/InputCurrency";
import SectionVideos from "./components/sectionVideos";
import { ErrorMessage } from "@hookform/error-message";

type UserType = {
  id: string;
  name: string;
};

type DataCurrency = {
  amount: string;
  currency_iso3: string;
};

export default function MyAccount(): JSX.Element {
  const methods = useForm({
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = methods;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [mentorLists, setMentorLists] = useState<any[]>([]);
  const [classesLists, setClassesLists] = useState<any[]>([]);

  const dataMentor = useSelector((state: any) => state.mentors);
  const dataOneCourse = useSelector((state: any) => state.one_course);
  const allClasses = useSelector((state: RootState) => state.classess_courses);

  useEffect(() => {
    dispatch(getAllClassesAdmin());
    dispatch(
      getAllMentors({
        limit: 9999,
        with_pagination: false,
        page: 1,
      })
    );
    if (id) {
      dispatch(getOneCourse({ id: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!dataMentor.loading && dataMentor.status === 200) {
      handleGetDataMentor();
    }
  }, [dataMentor]);

  useEffect(() => {
    if (!dataOneCourse.loading && dataOneCourse.status === 200) {
      handleSetData();
    }
  }, [dataOneCourse]);

  useEffect(() => {
    if (!allClasses.loading && allClasses.status === 200) {
      const temp: any[] = [];
      allClasses.response.map((d: string) => {
        temp.push({
          label: d,
          value: d,
        });
      });
      setClassesLists(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allClasses]);

  const handleGetDataMentor = () => {
    let temp: any[] = [];
    // eslint-disable-next-line array-callback-return
    dataMentor.response.data.map((d: UserType, i: number) => {
      temp.push({
        value: d.id,
        label: d.name,
      });
    });
    setMentorLists(temp);
  };

  const handleSetData = () => {
    const item = dataOneCourse.response.data;
    const data_sections: any = [];
    item.sections.map((d: any) => {
      let temp: {
        id: string;
        name: string;
        contents: any;
      } = {
        id: d.id,
        name: d.name,
        contents: [],
      };
      d.contents.map((item: any) => {
        temp.contents.push({
          id: item.id,
          name: item.name,
          video: item.video_url,
        });
      });

      data_sections.push(temp);
    });

    setValue("class", item.class);
    setValue("name", item.name);
    setValue("mentor_id", item.author.id);
    setValue("price", parseInt(filterDataPrice(item.prices)));
    setValue("description", item.description);
    setValue("sections", data_sections);
  };

  const filterDataPrice = (data: DataCurrency[]) => {
    let result: string = "";
    // eslint-disable-next-line array-callback-return
    data.filter((item) => {
      if (item.currency_iso3.toLowerCase() === "idr") {
        result = item.amount;
      }
    });

    return result;
  };

  const convertPrice = (value: any): any => {
    const numericString = value.replace(/[^0-9]/g, "");

    // Parse the numeric string as an integer
    const result = parseInt(numericString, 10);
    return result;
  };

  const onSubmit = async (value: any) => {
    setLoading(true);
    const payload = new FormData();
    payload.append("_method", "PUT");
    payload.append("course_id", dataOneCourse.response.data.id);
    payload.append("class", value.class);
    payload.append("mentor_id", value.mentor_id);
    payload.append("name", value.name);
    payload.append("description", value.description);
    payload.append(
      "price",
      typeof value.price === "number" ? value.price : convertPrice(value.price)
    );

    // eslint-disable-next-line array-callback-return
    value.sections.map(
      (
        d: {
          id: string | undefined;
          name: string;
          contents: {
            id: string | undefined;
            name: string;
            video: File | string;
          }[];
        },
        i: number
      ) => {
        if (d.id !== undefined) {
          payload.append(`sections[${i}][id]`, d.id);
        }
        payload.append(`sections[${i}][name]`, d.name);
        d.contents.map((item, index) => {
          if (item.id !== undefined) {
            payload.append(`sections[${i}][contents][${index}][id]`, item.id);
          }
          payload.append(`sections[${i}][contents][${index}][name]`, item.name);
          if (typeof item.video !== "string") {
            payload.append(
              `sections[${i}][contents][${index}][video]`,
              item.video
            );
          }
        });
      }
    );

    const res = await updateCourse(payload);
    if (res.status) {
      toast.success(res.message);
      setLoading(false);
      navigate("/admin/course");
    }
    if (!res.status) {
      setLoading(false);
      Object.entries(res.errors).map((d: any) => {
        let message: string = d[1][0];
        setError(
          d[0],
          { message: message },
          {
            shouldFocus: true,
          }
        );
      });
    }
  };

  const handleNavigate = (key: string): void => {
    if (key === "back") {
      return navigate(-1);
    }
    navigate(key);
  };

  const basicStyles = {
    control: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      borderRadius: "10px",
      boxShadow: "none",
      padding: "0 10px",
      margin: 0,
    }),
    valueContainer: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      padding: "10px 0px",
      margin: 0,
      fontSize: "1rem",
    }),
    option: (
      baseStyles: CSSObjectWithLabel,
      state: { isFocused: boolean; isHover: boolean }
    ) => ({
      ...baseStyles,
      backgroundColor: state.isFocused || state.isHover ? "#0F999B" : "",
      color: state.isFocused ? "white" : "black",
      fontSize: "14px",
    }),
  };

  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Edit Course" />

        <ContainerCenter>
          {dataOneCourse.loading && dataOneCourse.response === undefined ? (
            <div className="h-100 d-flex justify-center align-items-center">
              <TailSpin
                height="200"
                width="200"
                color="#0F999B"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <div className="form-content">
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="d-flex flex-column gap-3"
                >
                  <Row className="mb-3 border-bottom pb-5">
                    <div className="title-form">
                      <h2>General Information</h2>
                    </div>
                    <div className="col-lg-6">
                      <Controller
                        control={control}
                        name="class"
                        rules={{
                          required: "Class is required",
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          const checkError = !!error && !value;

                          return (
                            <div className="text-start">
                              <FormLabel>
                                Class
                                <small className="req">*</small>
                              </FormLabel>
                              <InputSearchSelect
                                placeholder="Select Class"
                                innerClassName="mb-1 "
                                styles={basicStyles}
                                options={classesLists}
                                isMulti={false}
                                withCreatable
                                value={
                                  classesLists.filter(
                                    (d) => d.value === watch("class")
                                  )
                                }
                                errors={checkError}
                                onChange={(value: {
                                  value: string;
                                  label: string;
                                }) => {
                                  setValue("class", value.value);
                                  onChange(value.value);
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="class"
                                render={({ message }) => (
                                  <span
                                    className="invalid-feedback d-block text-start"
                                    style={{
                                      fontSize: "0.75rem",
                                    }}
                                  >
                                    {message}
                                  </span>
                                )}
                              />
                            </div>
                          );
                        }}
                      />
                    </div>
                    <InputBasic
                      id="name"
                      type="text"
                      placeholder="Enter course name..."
                      md="6"
                      label="Course Name"
                      isRequired={true}
                      outerClassName="mb-3"
                      rules={{
                        function: register,
                        name: "name",
                        rules: {
                          required: "Course Name is required",
                        },
                        errors,
                      }}
                    />

                    <div className="col-lg-6">
                      <Controller
                        control={control}
                        name="mentor_id"
                        rules={{
                          required: "Mentor is required",
                        }}
                        render={({ field: { onChange } }) => (
                          <div className="text-start mt-2">
                            <FormLabel>
                              Mentor
                              <small className="req">*</small>
                            </FormLabel>
                            <InputSearchSelect
                              placeholder="Select Mentor"
                              innerClassName="mb-1 "
                              styles={basicStyles}
                              options={mentorLists}
                              value={
                                mentorLists.filter(
                                  (d) => d.value === watch("mentor_id")
                                )
                              }
                              isMulti={false}
                              onChange={(value: {
                                value: string;
                                label: string;
                              }) => {
                                onChange(value.value);
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="mentor_id"
                              render={({ message }) => (
                                <span
                                  className="invalid-feedback d-block text-start"
                                  style={{
                                    fontSize: "0.75rem",
                                  }}
                                >
                                  {message}
                                </span>
                              )}
                            />
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-lg-6">
                      <InputCurrency
                        id="price"
                        label="Course Price"
                        placeholder="Enter course price..."
                        className="mt-2"
                        isRequired={true}
                        value={watch("price")}
                        rules={{
                          function: register,
                          name: "price",
                          rules: {
                            required: "Course Price is required",
                          },
                          errors,
                        }}
                        prefix="Rp "
                      />
                    </div>

                    <InputBasic
                      id="description"
                      type="text"
                      placeholder="Enter course description..."
                      md="6"
                      label="Course Description"
                      isRequired={true}
                      textArea="textarea"
                      rules={{
                        function: register,
                        name: "description",
                        rules: {
                          required: "Course Description is required",
                        },
                        errors,
                      }}
                      className="d-flex flex-column"
                    />
                  </Row>

                  <Row className="mb-3 border-bottom pb-5">
                    <div className="title-form">
                      <h2>Course Video</h2>
                    </div>

                    <SectionVideos
                      control={control}
                      watch={watch}
                      register={register}
                      errors={errors}
                    />
                  </Row>

                  <div className="d-flex gap-3 justify-content-end">
                    <ButtonCancel
                      type="button"
                      onClick={() => handleNavigate("back")}
                    >
                      Back
                    </ButtonCancel>

                    <ButtonSubmit type={loading ? "button" : "submit"}>
                      {loading ? (
                        <Bars
                          height="30"
                          width="30"
                          color="#fff"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      ) : (
                        "Save Change"
                      )}
                    </ButtonSubmit>
                  </div>
                </form>
              </FormProvider>
            </div>
          )}
        </ContainerCenter>
      </ContainerCustom>
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0;
`;

const ContainerCenter = styled.div`
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .form-content {
    width: 100%;

    .title-form {
      margin-bottom: 30px;
      h2 {
        color: #303030;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const FormLabel = styled(Form.Label)`
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  .req {
    color: #fb6056;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;

const ButtonSubmit = styled.button`
  display: flex;
  width: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  &:hover {
    background: transparent;
    color: #0f999b;
    border: 1px solid #0f999b;
  }
`;

const ButtonCancel = styled.button`
  display: flex;
  width: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #0f999b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #0f999b;

  &:hover {
    background: #0f999b;
    color: #fff;
  }
`;
