import React, { useRef, WheelEvent } from 'react';
import './HorizontalScroll.css';

interface HorizontalScrollProps {
  children: React.ReactNode;
}

const HorizontalScroll: React.FC<HorizontalScrollProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = (e: WheelEvent<HTMLDivElement>) => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += e.deltaY;
      e.preventDefault();
    }
  };

  return (
    <div
      className="horizontal-scroll-container mt-3"
      onWheel={handleScroll}
      ref={containerRef}
    >
      <div className="horizontal-scroll-content">{children}</div>
    </div>
  );
};

export default HorizontalScroll;
