export const GET_ALL_STUDENTS_REQUEST = "GET_ALL_STUDENTS_REQUEST";
export const GET_ALL_STUDENTS_SUCCESS = "GET_ALL_STUDENTS_SUCCESS";
export const GET_ALL_STUDENTS_FAIL = "GET_ALL_STUDENTS_FAIL";

export const GET_DETAILS_STUDENT_REQUEST = "GET_DETAILS_STUDENT_REQUEST";
export const GET_DETAILS_STUDENT_SUCCESS = "GET_DETAILS_STUDENT_SUCCESS";
export const GET_DETAILS_STUDENT_FAIL = "GET_DETAILS_STUDENT_FAIL";

export const studentsReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_ALL_STUDENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_ALL_STUDENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};

export const detailsStudentReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_DETAILS_STUDENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_DETAILS_STUDENT_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_DETAILS_STUDENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};
