import styled from "styled-components";
import { Container } from "react-bootstrap";

import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import Layout from "components/Organism/Layouts";
import StudentComponent from "./components/students";

export default function Home(): JSX.Element {
  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Course" />
          <StudentComponent />
      </ContainerCustom>
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0px !important;
`;
