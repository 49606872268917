/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Bars, TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";
import { ErrorMessage } from "@hookform/error-message";

import { useDispatch, useSelector } from "react-redux";
import { getDetailsMentor, updateMentor, deleteMentor } from "../redux/actions";
import { getAllClassesAdmin } from "pages/Home/redux/actions";
import { RootState } from "redux/store";

import InputBasic from "components/Atoms/Inputs/InputBasic";
import InputFile from "components/Atoms/Inputs/InputFile";
import Layout from "components/Organism/Layouts";
import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import InputSearchSelect from "components/Atoms/Inputs/InputSearchSelect";
import ModalCustom from "components/Organism/Modal";
import InputToogle from "components/Atoms/Inputs/InputToogle";

export default function EditMentorPage(): JSX.Element {
  const methods = useForm({
    mode: "onChange",
  });

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    formState: { errors },
  } = methods;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const data_mentor = useSelector((state: any) => state.details_mentor);
  const allClasses = useSelector((state: RootState) => state.classess_courses);

  const [classesLists, setClassesLists] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    dispatch(getAllClassesAdmin());
    if (id) {
      dispatch(getDetailsMentor({ id: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!data_mentor.loading && data_mentor.status === 200) {
      handleSetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_mentor]);

  useEffect(() => {
    if (!allClasses.loading && allClasses.status === 200) {
      const temp: any[] = [];
      allClasses.response.map((d: string) => {
        temp.push({
          label: d,
          value: d,
        });
      });
      setClassesLists(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allClasses]);

  const handleSetData = () => {
    const item = data_mentor.response.data;
    setValue("name", item.name);
    setValue("class", item.class);
    setValue("email", item.email);
    setValue("description", item.description);
    setValue("consultation_session", item.is_consultation_session);
    setValue("image", item.image);
  };

  const onSubmit = async (value: any) => {
    setLoading(true);
    const payload = new FormData();
    payload.append("_method", "PUT");
    payload.append("mentor_id", data_mentor.response.data.id);
    payload.append("name", value.name);
    payload.append("email", value.email);
    payload.append("class", value.class);
    payload.append("description", value.description);
    payload.append("consultation_session", value.consultation_session);
    if (typeof value.image !== "string") {
      payload.append("image", value.image);
    }

    const res = await updateMentor(payload);
    if (res.status) {
      setLoading(false);
      toast.success(res.message);
      navigate("/admin/mentor");
    }
    if (!res.status) {
      setLoading(false);
      Object.entries(res.errors).map((d: any) => {
        let message: string = d[1][0];
        setError(
          d[0],
          { message: message },
          {
            shouldFocus: true,
          }
        );
      });
    }
  };

  const handleNavigate = (key: string): void => {
    if (key === "back") {
      return navigate(-1);
    }
    navigate(key);
  };

  const handleShowModal = () => setShowModal(!showModal);

  const confirmDelete = async () => {
    setLoading(true);
    if (id) {
      const res = await deleteMentor({ id: id });
      if (res.status) {
        setSuccess(true);
        setLoading(false);
        navigate("/admin/mentor");
        setSuccess(false);
      }
      if (!res.status) {
        setLoading(false);
        toast.error(res.message);
      }
    }
  };

  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Edit Mentor" />

        <ContainerCenter>
          {data_mentor.loading && data_mentor.response === undefined ? (
            <div className="h-100 d-flex justify-center align-items-center">
              <TailSpin
                height="200"
                width="200"
                color="#0F999B"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <div className="form-content">
              <FormProvider {...methods}>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="d-flex flex-column gap-3"
                >
                  <Row className="mb-3 border-bottom pb-5">
                    <div className="title-form">
                      <h2>General Information</h2>
                    </div>
                    <InputBasic
                      id="name"
                      type="text"
                      placeholder="Enter mentor name..."
                      md="6"
                      isRequired={true}
                      label="Mentor Name"
                      outerClassName="mb-3"
                      rules={{
                        function: register,
                        name: "name",
                        rules: {
                          required: "Mentor name is required",
                        },
                        errors,
                      }}
                    />

                    <div className="col-lg-6">
                      <Controller
                        control={control}
                        name="class"
                        rules={{
                          required: "Class is required",
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          const checkError = !!error && !value;

                          return (
                            <div className="text-start">
                              <FormLabel>
                                Class
                                <small className="req">*</small>
                              </FormLabel>
                              <InputSearchSelect
                                placeholder="Select Class"
                                innerClassName="mb-1"
                                options={classesLists}
                                isMulti={false}
                                value={
                                  classesLists.filter(
                                    (d) => d.value === watch("class")
                                  )
                                }
                                withCreatable
                                errors={checkError}
                                onChange={(value: {
                                  value: string;
                                  label: string;
                                }) => {
                                  setValue("class", value.value)
                                  onChange(value.value);
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="class"
                                render={({ message }) => (
                                  <span
                                    className="invalid-feedback d-block text-start"
                                    style={{
                                      fontSize: "0.75rem",
                                    }}
                                  >
                                    {message}
                                  </span>
                                )}
                              />
                            </div>
                          );
                        }}
                      />
                    </div>

                    <InputBasic
                      id="description"
                      type="text"
                      placeholder="Enter course description..."
                      md="6"
                      label="Mentor Description"
                      isRequired={true}
                      textArea="textarea"
                      rules={{
                        function: register,
                        name: "description",
                        rules: {
                          required: "Mentor Description is required",
                        },
                        errors,
                      }}
                      className="d-flex flex-column my-3"
                    />

                    <div className="col-lg-6 my-3">
                      <InputFile
                        accepted=".jpg,.png,.jpeg"
                        name="image"
                        label="Mentor Photo"
                        isRequired={true}
                        watch={watch}
                        placeholder="Upload photo (JPG, PNG,JPEG)"
                        rules={{
                          function: register,
                          name: "image",
                          rules: {
                            required: "Image is required",
                          },
                          errors,
                        }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <div className="col-lg-6">
                        <InputToogle
                          label="Consultation Session Availability Status"
                          id="consultation_session"
                          rules={{
                            function: register,
                            name: "consultation_session",
                          }}
                        />
                      </div>
                    </div>
                  </Row>

                  <Row className="mb-3 border-bottom pb-5">
                    <div className="title-form">
                      <h2>Login Information</h2>
                    </div>
                    <InputBasic
                      id="email"
                      type="text"
                      placeholder="Enter mentor email address..."
                      md="6"
                      isRequired={true}
                      label="Mentor Email Address"
                      outerClassName="mb-3"
                      rules={{
                        function: register,
                        name: "email",
                        rules: {
                          required: "Mentor Email is required",
                        },
                        errors,
                      }}
                    />
                  </Row>

                  <div className="d-flex gap-3 justify-content-end">
                    <ButtonDelete type="button" onClick={handleShowModal}>
                      Delete Mentor
                    </ButtonDelete>
                    <ButtonCancel
                      type="button"
                      onClick={() => handleNavigate("back")}
                    >
                      Back
                    </ButtonCancel>
                    <ButtonSubmit type={loading ? "button" : "submit"}>
                      {loading ? (
                        <Bars
                          height="30"
                          width="30"
                          color="#fff"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      ) : (
                        "Update Mentor"
                      )}
                    </ButtonSubmit>
                  </div>
                </form>
              </FormProvider>
            </div>
          )}
        </ContainerCenter>
      </ContainerCustom>

      <ModalCustom
        show={showModal}
        onHide={handleShowModal}
        onConfirm={confirmDelete}
        loading={loading}
        type="delete"
        key_component="Mentor"
        title="Delete Mentor?"
        desc="Are you sure want to delete this Mentor?, This Mentor will not have a access anymore."
      />

      <ModalCustom
        show={success}
        key_component="Course"
        onHide={() => setSuccess(!success)}
        type="success"
        title="Success"
        desc="Successfully delete Mentor"
      />
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0;
`;

const ContainerCenter = styled.div`
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .form-content {
    width: 100%;

    .title-form {
      margin-bottom: 30px;
      h2 {
        color: #303030;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const ButtonSubmit = styled.button`
  display: flex;
  width: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
`;

const ButtonDelete = styled.button`
  display: flex;
  width: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #fb6056;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #fb6056;
`;

const ButtonCancel = styled.button`
  display: flex;
  width: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #0f999b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #0f999b;
`;

const FormLabel = styled(Form.Label)`
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  .req {
    color: #fb6056;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;
