import { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TailSpin } from "react-loader-spinner";
import { BsCalendar4Range } from "react-icons/bs";
import moment from "moment";

import EllipseWarn from "assets/icons/ellipse-warning.svg";
import EllipseSuccess from "assets/icons/ellipse-success.svg";
import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import Layout from "components/Organism/Layouts";
import Pagination from "components/Atoms/Paginations";

import { RootState } from "redux/store";
import { getMyConsultations, getAllClasses } from "./redux/actions";

// import QnA from "assets/images/qna.png";

type dataCourse = {
  id: string;
  image: string;
  language: string;
  description: string;
  created_at: Date;
  duration_formatter: string;
  name: string;
  overview_items: string;
  my_consultation: {
    is_completed: boolean;
  };
};

type PropsHandlePage = {
  selected: number;
};

type DataButton = {
  label: string;
  value: string | null;
};

type TypeParams = {
  with_pagination: boolean;
  limit: number;
  page: number;
  class: string | null;
};

export default function Consultations(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setParams] = useState<TypeParams>({
    with_pagination: true,
    limit: 3,
    page: 1,
    class: null,
  });
  const [active, setActive] = useState<string|null>(null);
  const [classes, setClasses] = useState<any>();

  const allMyConsultation = useSelector((state: RootState) => state.my_consultations);
  const allClasses = useSelector((state: RootState) => state.all_classes);

  useEffect(() => {
    dispatch(getAllClasses({ limit: 9999 }))
    dispatch(getMyConsultations(params));
  }, [dispatch, params]);

  useEffect(() => {
    if (!allClasses.loading && allClasses.status === 200) {
      const temp: DataButton[] = [
        {
          label: "All Consultations",
          value: null
        }
      ];
      // eslint-disable-next-line array-callback-return
      allClasses.response.data.map((d: string) => {
        temp.push({
          label: d,
          value: d,
        })
      })
      setClasses(temp);
    }
  }, [allClasses])

  const handleNavigate = (key: string) => {
    navigate(`${key}`);
  };

  const handlePage = async ({ selected }: PropsHandlePage) => {
    setParams((prev) => ({
      ...prev,
      page: selected + 1,
    }));

    const payload = {
      ...params,
      page: selected + 1,
    };
    await dispatch(getMyConsultations(payload));
  };
  
  const handleClass = async (value: string|null) => {
    setParams((prev) => ({
      ...prev,
      class: value,
    }));
    setActive(value);

    const payload = {
      ...params,
      class: value,
    };
    await dispatch(getMyConsultations(payload));
  };
  
  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Course" />

        <ContainerCenter>
          <div className="center-content">
            <div className="top-title">
              <h3>Your Consultation Access</h3>
              <h2>Consultation List</h2>
            </div>

            <div className="content-button-group mt-3">
              {!allClasses.loading && classes && classes.map((d: DataButton, i: number) => (
                <TabCustom
                  key={i}
                  className={`${active === d.value ? "active" : ""} text-capitalize`}
                  onClick={() => handleClass(d.value)}
                >
                  {d.label}
                </TabCustom>
              ))}
            </div>

            <div className="course-list row d-flex justify-content-between my-4">
              {allMyConsultation.loading && (
                <div className="h-100 d-flex justify-center align-items-center">
                  <TailSpin
                    height="200"
                    width="200"
                    color="#0F999B"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              )}
              {!allMyConsultation.loading &&
                allMyConsultation.status === 200 &&
                allMyConsultation.response.data.map(
                  (data: dataCourse, i: number) => (
                    <div className="col-lg-12 card d-flex mb-5 border-0">
                      <div className="row">
                        <div className="col-lg-2 content-img-left">
                          <img src={data.image} alt="qna" />
                        </div>
                        <div className="col-lg-9 content-card">
                          <h4>{data.name}</h4>
                          <span>{data.description}</span>
                          <div className="time">
                            <BsCalendar4Range size={18} />
                            <small>{moment(data.created_at).format("DD MMMM YYYY")}</small>
                          </div>
                          <div className="status">
                            <img
                              src={
                                data.my_consultation.is_completed
                                  ? EllipseSuccess
                                  : EllipseWarn
                              }
                              alt="ellipse"
                            />
                            <small>
                              {data.my_consultation.is_completed
                                ? "Consultation Completed"
                                : "Consultation Ongoing"}
                            </small>
                          </div>
                          <div className="btn-content-view">
                            <TabCustom
                              className="active"
                              onClick={() =>
                                handleNavigate(`/consultations/details/${data.id}`)
                              }
                            >
                              View Consultation Details
                            </TabCustom>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              {!allMyConsultation.loading && allMyConsultation.status === 200 && (
                <Pagination
                  pageCount={allMyConsultation.response.meta.last_page}
                  forcePage={allMyConsultation.response.meta.current_page}
                  onPageChange={handlePage}
                />
              )}
            </div>
          </div>
        </ContainerCenter>
      </ContainerCustom>
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0px !important;
`;

const ContainerCenter = styled.div`
  padding: 30px 40px;
  position: relative;
  min-height: 90vh;

  .center-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;

    .top-title {
      display: flex;
      flex-direction: column;
      color: #303030;

      h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }

      h2 {
        font-size: 27px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
      }
    }

    .filter-content {
      display: flex;
      align-items: center;
      justify-content: end;
      padding: 1.5rem 0;
    }

    .content-button-group {
      width: 1550px;
      display: flex;
      gap: 0.5rem;
      overflow: auto;
      padding-right: 30px;

      &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
      }
    }

    .course-list {
      padding-left: 30px;
      .card {
        padding: 40px 0px;
        border-bottom: 1px solid #dbdbdb !important;

        .row {
          gap: 20px;

          .content-img-left {
            border-radius: 14px;
            background: #3fadaf;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 100%;
            }
          }

          .content-card {
            display: flex;
            flex-direction: column;
            gap: 10px;

            h4 {
              color: #303030;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 30px;
            }

            span {
              color: #6e6e6e;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px;
            }

            .time {
              display: flex;
              align-items: center;
              color: #6e6e6e;

              small {
                margin-left: 6px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
              }
            }

            .status {
              display: flex;
              align-items: center;
              gap: 5px;
            }

            .btn-content-view {
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const TabCustom = styled.button`
  display: flex;
  min-width: 200px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #e0e0e0;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  &.active {
    background: #0f999b;
  }
`;
