import { Bars } from "react-loader-spinner";
import styled from "styled-components";

export default function Loader(): JSX.Element {
  return (
    <Portal>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h1 className="fw-semibold h5">Please wait a moment</h1>
        <Bars
          height="80"
          width="80"
          color="#fff"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    </Portal>
  );
}

const Portal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;
