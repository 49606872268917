import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { v4 as uuidv4 } from "uuid";
import { Bars, TailSpin } from "react-loader-spinner";

import { RootState } from "redux/store";
import {
  getDetailsConvertation,
  sendMessage,
  closeConvertation,
  resetConvertation,
  openConvertation,
} from "../redux/actions";

import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import ModalCustom from "components/Organism/Modal";
import Layout from "components/Organism/Layouts";

export default function Consultations(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalClose, setModalClose] = useState(false);
  const [loadingClose, setLoadingClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState<object>();

  useEffect(() => {
    setDatas(undefined);
    dispatch(resetConvertation());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const conversations = useSelector(
    (state: RootState) => state.convertations_mentor
  );

  useEffect(() => {
    if (!conversations.loading && conversations.status === 200 && conversations.response !== undefined) {
      setDatas(conversations.response.data.conversations);
    }
  }, [conversations]);

  useEffect(() => {
    // Scroll to the bottom of the chat container when messages change
    if (chatContainerRef.current && datas) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [datas]);

  useEffect(() => {
    if (id) {
      dispatch(getDetailsConvertation({ id: id }));
    }
  }, [dispatch, id]);

  const handleNavigate = () => {
    navigate(-1);
  };

  const onSubmit = async (value: any) => {
    setLoading(true);
    const payload = new FormData();
    if (id) {
      payload.append("user_consultation_id", id);
    }
    payload.append("content", value.content);

    const res = await sendMessage(payload);
    if (res.status && id) {
      resetField("content");
      setLoading(false);
      dispatch(getDetailsConvertation({ id: id }));
    }
    if (!res.status) {
      setLoading(false);
    }
  };

  const handleModalClose = () => setModalClose(!modalClose);
  const handleModalOpen = () => setModalOpen(!modalOpen);

  const confirmClose = async () => {
    setLoadingClose(true);
    if (id) {
      const payload = new FormData();
      payload.append("user_consultation_id", id);
      let res = await closeConvertation(payload);

      if (res.status) {
        setLoadingClose(false);
        handleModalClose();
        dispatch(getDetailsConvertation({ id: id }));
      }
      if (!res.status) {
        setLoadingClose(false);
      }
    }
  };

  const confirmOpen = async () => {
    setLoadingClose(true);
    if (id) {
      let res = await openConvertation({ id: id });

      if (res.status) {
        setLoadingClose(false);
        handleModalOpen();
        dispatch(getDetailsConvertation({ id: id }));
      }
      if (!res.status) {
        setLoadingClose(false);
      }
    }
  };

  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Consultation" />

        <ContainerCenter>
          {conversations.loading && datas === undefined && (
            <div className="h-100 d-flex justify-center align-items-center">
              <TailSpin
                height="200"
                width="200"
                color="#0F999B"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}

          {conversations.response !== undefined && datas && (
            <>
              <div className="center-content">
                <div className="content-left">
                  <AiOutlineArrowLeft
                    size={26}
                    className="icon-back"
                    onClick={handleNavigate}
                  />
                  <div className="top-title">
                    <h3>{conversations.response.data.consultation.name}</h3>
                    <h2>
                      {conversations.response.data.consultation.mentor.name}
                    </h2>
                  </div>
                </div>
                {!conversations.response.data.is_completed ? (
                  <ButtonSubmit type="button" onClick={handleModalClose}>
                    {loadingClose ? (
                      <Bars
                        height="30"
                        width="30"
                        color="#fff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    ) : (
                      "Close Consultation"
                    )}
                  </ButtonSubmit>
                ) : (
                  <ButtonSubmit type="button" onClick={handleModalOpen}>
                  {loadingClose ? (
                    <Bars
                      height="30"
                      width="30"
                      color="#fff"
                      ariaLabel="bars-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    "Reopen Consultation"
                  )}
                </ButtonSubmit>
                )}
              </div>
              <div className="divider"></div>
              <ContentChat ref={chatContainerRef}>
                {datas &&
                  Object.entries(datas).map((d: any) => (
                    <div key={uuidv4()}>
                      <div className="text-border">
                        <div className="divider-left"></div>
                        <span>{d[0]}</span>
                        <div className="divider-right"></div>
                      </div>
                      {d[1].map((item: any, i: number) => (
                        <BoxChat key={i} type={item.is_from_mentor}>
                          <div className="user-info">
                            {item.is_from_mentor && (
                              <ContentUser
                                avatar={
                                  conversations.response.data.consultation
                                    .mentor.image
                                }
                              ></ContentUser>
                            )}
                            <div className="chat-info">
                              <h3 className="title">
                                {item.is_from_mentor
                                  ? conversations.response.data.consultation
                                      .mentor.name
                                  : conversations.response.data.user.full_name}
                              </h3>
                              <span className="message">{item.content}</span>
                            </div>
                            {!item.is_from_mentor && (
                              <ContentUser
                                avatar={
                                  conversations.response.data.user.image_url
                                }
                              ></ContentUser>
                            )}
                          </div>
                        </BoxChat>
                      ))}
                    </div>
                  ))}
              </ContentChat>
            </>
          )}
        </ContainerCenter>

        {conversations.response !== undefined && datas && (
          <BoxSendMessage errors={errors["content"] !== undefined} completed={conversations.response.data.is_completed}>
              {conversations.response.data.is_completed ? (
                <span className="close">
                  Your session with this mentor has been completed, plan another
                  consultation to get a private session with the same mentor or
                  another mentor
                </span>
              ) : (
                <form className="content-form" onSubmit={handleSubmit(onSubmit)}>
                  <FormMessage
                    placeholder="Send a message..."
                    aria-label="message"
                    aria-describedby="message"
                    {...register("content", {
                      required: "This message required",
                    })}
                  />
                  {loading ? (
                    <ButtonSend>
                      <Bars
                        height="20"
                        width="30"
                        color="#fff"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </ButtonSend>
                  ) : (
                    <ButtonSend type="submit">Send</ButtonSend>
                  )}
                </form>
              )}
            
            <ErrorMessage
              errors={errors}
              name="content"
              render={({ message }) => (
                <span
                  className="invalid-feedback d-block text-start"
                  style={{
                    fontSize: "0.75rem",
                  }}
                >
                  {message}
                </span>
              )}
            />
          </BoxSendMessage>
        )}
      </ContainerCustom>

      <ModalCustom
        show={modalClose}
        onHide={handleModalClose}
        onConfirm={confirmClose}
        loading={loading}
        type="close"
        title="Close Consultation?"
        key_component="Consultation"
        desc="Are you sure want to close this consultation?, will not have access to this consultation anymore."
      />
      <ModalCustom
        show={modalOpen}
        onHide={handleModalOpen}
        onConfirm={confirmOpen}
        loading={loading}
        type="open"
        title="Open Consultation?"
        key_component="Consultation"
        desc="Are you sure want to open this consultation?, will not have access to this consultation anymore."
      />
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0px !important;
`;

const ContainerCenter = styled.div`
  position: relative;

  .divider {
    width: 100%;
    height: 1px;
    background: #dbdbdb;
  }

  .center-content {
    display: flex;
    align-items: center;
    padding: 30px 40px;

    .content-left {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;

      .icon-back {
        cursor: pointer;
      }

      .top-title {
        display: flex;
        flex-direction: column;
        color: #303030;
        padding-bottom: 10px;

        h3 {
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
          margin: 0;
          text-transform: capitalize;
        }

        h2 {
          text-transform: capitalize;
          font-size: 27px;
          font-style: normal;
          font-weight: 700;
          line-height: 36px;
          margin: 0;
        }
      }
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const ContentChat = styled.div`
  max-height: 65vh;
  overflow: auto;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .text-border {
    position: relative;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    color: #303030;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    .divider-left,
    .divider-right {
      width: 100%;
      height: 1px;
      background-color: #e6e6e6;
    }
  }
`;

const BoxChat = styled.div<{ type: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.type ? "space-between" : "flex-end")};
  gap: 10px;
  padding: 20px 0;
  border-radius: 10px;

  .user-info {
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 230px;
    .chat-info {
      display: flex;
      padding: 10px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: ${(p) => (p.type ? "flex-start" : "flex-end")};
      gap: 10px;
      border-radius: 10px;
      background: #f0f3ff;

      .title {
        color: #303030;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0px;
      }

      .message {
        color: #6e6e6e;
        font-feature-settings: "clig" off, "liga" off;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: justify;
      }
    }
  }
`;

const ContentUser = styled.div<{ avatar: string }>`
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background: center / cover no-repeat url(${(props) => props.avatar}) #0f999b !important;
  overflow: hidden;
  cursor: pointer;
  color: #0f999b;
`;

const BoxSendMessage = styled.div<{ errors?: boolean, completed?: boolean, }>`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  margin-bottom: 20px;
  display: ${(props) => (props.completed ?? false ? "flex" : "block")};;
  justify-content: center;
  align-items: center;

  .close {
    width: 100%;
    border: 1px solid #0f999b;
    background: #cfebeb;
    padding: 20px;

    color: #0f999b;
    text-align: justify;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }

  .content-form {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    bottom: 0;
    padding: 7px;

    border-radius: 10px;
    border: 1px solid
      ${(props) => (props.errors ?? false ? "#FB6056" : "#e6e6e6")};
    background: #fff;
  }

  .desc-info {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .icon-flash {
      color: #0f999b;
    }

    span {
      color: #303030;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
`;

const FormMessage = styled(Form.Control)`
  border: none;
  background: transparent;

  &::placeholder {
    color: #acacac;
    text-align: justify;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
`;

const ButtonSend = styled.button`
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #0f999b;
  border: none;

  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const ButtonSubmit = styled.button`
  display: flex;
  width: 200px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #0f999b;

  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
`;
