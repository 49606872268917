export const GET_ALL_CLASSESS_REQUEST = "GET_ALL_CLASSESS_REQUEST";
export const GET_ALL_CLASSESS_SUCCESS = "GET_ALL_CLASSESS_SUCCESS";
export const GET_ALL_CLASSESS_FAIL = "GET_ALL_CLASSESS_FAIL";

export const GET_ALL_MY_COURSE_REQUEST = "GET_ALL_MY_COURSE_REQUEST";
export const GET_ALL_MY_COURSE_SUCCESS = "GET_ALL_MY_COURSE_SUCCESS";
export const GET_ALL_MY_COURSE_FAIL = "GET_ALL_MY_COURSE_FAIL";

export const GET_ALL_COURSE_REQUEST = "GET_ALL_COURSE_REQUEST";
export const GET_ALL_COURSE_SUCCESS = "GET_ALL_COURSE_SUCCESS";
export const GET_ALL_COURSE_FAIL = "GET_ALL_COURSE_FAIL";

export const GET_DETAILS_COURSE_REQUEST = "GET_DETAILS_COURSE_REQUEST";
export const GET_DETAILS_COURSE_SUCCESS = "GET_DETAILS_COURSE_SUCCESS";
export const GET_DETAILS_COURSE_FAIL = "GET_DETAILS_COURSE_FAIL";

export const myCourseReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_ALL_MY_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_MY_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_ALL_MY_COURSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};

export const coursesReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_ALL_COURSE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_ALL_COURSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};

export const courseOneReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_DETAILS_COURSE_REQUEST:
      return {
        ...state,
        response: undefined,
        loading: true,
      };

    case GET_DETAILS_COURSE_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_DETAILS_COURSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};

export const classessCourseReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_ALL_CLASSESS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_CLASSESS_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_ALL_CLASSESS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};
