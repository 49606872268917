import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { BsPencil, BsTrash, BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";

export const TableRow = (props: any) => {
  return (
    <tr className="nk-tb-item" style={{ padding: "20px 0"}}>
      {props.columns
        ? Object.entries(props.columns).map((value: any) =>
            value[0] !== "action" ? (
              value[0] === "id" ? null : value[0] === "session" ? (
                <TdSession value={value[1]} key={uuidv4()}>
                  {value[1] ? "Available" : "Not Available"}
                </TdSession>
              ) : value[0] === "status" ? (
                <TdStatus key={uuidv4()} value={value[1].toLowerCase()}>
                  {value[1]}
                </TdStatus>
              ) : (
                <Td key={uuidv4()}>
                  {value[1]}
                </Td>
              )
            ) : value[1] !== null ? (
              <Td key={uuidv4()}>
                <Dropdown>
                  <DropdownToggle id="dropdown-autoclose-true">
                    <BsThreeDotsVertical size={18} />
                  </DropdownToggle>

                  <DropdownMenuCustom>
                    {props.detailsAction ? (
                      <DropdownItem
                        href="#"
                        onClick={props.detailsAction}
                        type="edit"
                      >
                        <AiOutlineEye size={18} />
                        {props.detailsTitle}
                      </DropdownItem>
                    ) : null}
                    {props.editAction ? (
                      <DropdownItem
                        href="#"
                        onClick={props.editAction}
                        type="edit"
                      >
                        <BsPencil size={18} />
                        {props.editTitle}
                      </DropdownItem>
                    ) : null}
                    {props.deleteAction ? (
                      <DropdownItem
                        href="#"
                        onClick={props.deleteAction}
                        type="delete"
                      >
                        <BsTrash size={18} />
                        {props.deleteTitle}
                      </DropdownItem>
                    ) : null}
                  </DropdownMenuCustom>
                </Dropdown>
              </Td>
            ) : null
          )
        : null}
    </tr>
  );
};

const Td = styled.td`
  color: #303030 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  padding: 20px 0 !important;
`;

const TdSession = styled.td<{value: boolean}>`
  color: ${(p) => p.value ? "#06BD80" : "#FB6056"} !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  padding: 20px 0 !important;
`;

const TdStatus = styled.td<{value: string}>`
  color: ${(p) => p.value === "active" || p.value === "paid" ? "#06BD80" : "#FB6056"} !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  padding: 20px 0 !important;
  text-transform: capitalize;
`;

const DropdownToggle = styled(Dropdown.Toggle)`
  border: none;
  background: transparent !important;
  color: #303030 !important;

  &:focus,
  &:hover,
  &:active,
  &:focus-visible {
    box-shadow: none;
    background: transparent !important;
    border: none;
    color: #303030 !important;
  }

  &:after {
    display: none;
  }
`;

const DropdownMenuCustom = styled(Dropdown.Menu)`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #e6e6e6 !important;
  background: #fff;
  box-shadow: 0px 7px 29px 0px rgba(100, 100, 111, 0.2);
`;

const DropdownItem = styled(Dropdown.Item)<{ type: string }>`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(p) => (p.type === "edit" ? "#4070FF" : "#FB6056")} !important;
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.24px;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    border-radius: 5px;
    background: transparent !important;
    color: ${(p) => (p.type === "edit" ? "#4070FF" : "#FB6056")} !important;
  }
`;
