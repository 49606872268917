import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import { AiOutlineLock } from "react-icons/ai";
import { TfiEmail } from "react-icons/tfi";
import { Bars } from "react-loader-spinner";
import { useForm } from "react-hook-form";

import InputBasic from "components/Atoms/Inputs/InputBasic";
import { login, getCSRF } from "../redux/actions";
import Person from "assets/icons/person-lock.svg";

export default function LoginForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (value: any) => {
    setLoading(true);

    const payload = new FormData();

    // append data to form data
    payload.append("email", value.email);
    payload.append("password", value.password);
    payload.append("remember", value.remember);
    payload.append("type", "admin");

    dispatch(getCSRF());
    await dispatch(login(payload));
    setLoading(false);
  };

  const handleToForgotPassword = () => {
    navigate("/admin/forgot-password");
  };

  return (
    <Fragment>
      <ContainerCustom>
        <ContentBg className="bg"></ContentBg>
        <div className="content-right">
          <div className="card">
            <div className="content-top">
              <img src={Person} alt="" className="img-person" />
              <h2 className="title-card">Administrator Login</h2>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputBasic
                id="email"
                type="email"
                placeholder="Enter email address"
                outerClassName="mb-3"
                icon={<TfiEmail size={20} />}
                rules={{
                  function: register,
                  name: "email",
                  rules: {
                    required: 'Email is required',
                    pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: 'Entered value does not match email format',
                    },
                },
                errors,
                }}
              />

              <InputBasic
                id="password"
                type="password"
                placeholder="New Password"
                outerClassName="mb-3"
                icon={<AiOutlineLock size={20} />}
                rules={{
                  function: register,
                  name: "password",
                  rules: {
                    required: "Password is required",
                  },
                  errors,
                }}
              />

              <div
                className="d-flex justify-content-between mb-3"
                style={{ fontSize: "0.90rem" }}
              >
                <Form.Check
                  type="checkbox"
                  inline
                  id="remember"
                  label="Remember Me"
                  {...register("remember")}
                />
              </div>
              <ButtonCustomSend type={loading ? "button" : "submit"}>
                {loading ? (
                  <Bars
                    height="40"
                    width="40"
                    color="#fff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                ) : (
                  "Log in"
                )}
              </ButtonCustomSend>
              <LinkRegister>
                Forgot password?{" "}
                <span className="text" onClick={handleToForgotPassword}>
                  Reset Password
                </span>
              </LinkRegister>
            </form>
          </div>
        </div>
      </ContainerCustom>
    </Fragment>
  );
}

const ContentBg = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 50%;
  background-color: #0f999b;
  z-index: 1;
`;

const ContainerCustom = styled.div`
  height: 100vh;
  position: relative;

  .content-right {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;

    .card {
      width: 450px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 50px;
      border-radius: 20px;
      border: 1px solid #efefef;
      background: #fff;

      .content-top {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .img-person {
          width: 40px;
          height: 40px;
        }
        .title-card {
          color: #303030;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Roboto;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
        }
      }
    }
  }
`;

const ButtonCustomSend = styled.button`
  display: flex;
  height: 50px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: #0f999b;
  border: none;
  width: 100%;

  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const LinkRegister = styled.div`
  margin-top: 10px;
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;

  .text {
    color: #0f999b;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    cursor: pointer;
  }
`;
