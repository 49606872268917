/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { v4 as uuidv4 } from "uuid";

import { BsGrid3X3 } from "react-icons/bs";
import { IoPeopleOutline } from "react-icons/io5";
import { LuFileBadge2 } from "react-icons/lu";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import { logout } from "pages/Authentication/redux/actions";
import SidebarData from "assets/json/menu_admin.json";

type ItemMenu = {
  id: string;
  name: string;
  url: string;
  group: string[];
  open: boolean;
  submenu: SubMenuType[];
};

type SubMenuType = {
  id: string;
  name: string;
  url: string;
  open: boolean;
  group: string[];
};

export default function Layout(): JSX.Element {
  const dispatch = useDispatch();
  const { menus } = SidebarData;
  const [content, setContent] = useState<any>([]);

  useEffect(() => {
    const updated = menus.map((item, index) => {
      if (item.group.includes(window.location.pathname.split("/")[2])) {
        return { ...item, open: true }; // Set open on first menu true
      }
      return { ...item, open: false }; // else set open by false
    });
    setContent(updated);
  }, []);

  useEffect(() => {
    handleCollapse();
  }, [window.location.pathname.split("/")[2]]);
  
  const handleCollapse = () => {
    // eslint-disable-next-line array-callback-return
    content.map((item: any, key: number) => {
      if (item.group.includes(window.location.pathname.split("/")[2])) {
        return collapseOtherMenu(key, true);
      }
    });
  }

  /**
   * Function to handle collapse in other menu
   *
   * @param {number} index
   * @param {boolean} value
   * @return {void}
   */
  const collapseOtherMenu = (index: number, value: boolean): void => {
    const updated = content.map((item: any, key: number) => {
      if (key === index) {
        return { ...item, open: !value }; // Set open on first menu true
      }
      return { ...item, open: false }; // else set open by false
    });

    setContent(updated);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  /**
   * Function to handle style of navlink
   *
   */
  const getNavLinkClass = (path: string) => {
    if (window.location.pathname === "/" && path === "/course") {
      return true;
    }
    if (path === "/consultation-room") {
      return window.location.pathname.includes(path)
    }
    return path === window.location.pathname;
  };

  const handleCheckIcon = (key: string): ReactNode => {
    if (key === "course") {
      return <BsGrid3X3 size={20} />;
    }
    if (key === "mentor" || key === "consultation" || key === "students") {
      return <IoPeopleOutline size={20} />;
    }
    if (key === "certificate") {
      return <LuFileBadge2 size={20} />;
    }
  };

  return (
    <Menu>
      {content &&
        content.map((items: ItemMenu, i: number) =>
          items?.submenu?.length ? (
            <SubMenuCustom
              open={items.open}
              active={items.open}
              id={items.id}
              onOpenChange={() => collapseOtherMenu(i, items.open)}
              label={items.name}
              key={uuidv4()}
              icon={handleCheckIcon(items.id)}
            >
              {items.submenu.map((sub, j) => (
                <MenuItemCustom
                  key={uuidv4()}
                  id={sub?.id}
                  active={getNavLinkClass(sub?.url)}
                  component={<Link to={sub.url} />}
                  
                >
                  {sub?.name}
                </MenuItemCustom>
              ))}
            </SubMenuCustom>
          ) : (
            <MenuItemCustom
              onClick={() => {
                collapseOtherMenu(i, false)
              }}
              active={getNavLinkClass(items?.url)}
              key={uuidv4()}
              id={items?.id}
              icon={handleCheckIcon(items.id)}
              component={<Link to={items.url} />}
            >
              {items.name}
              <Link to={items.url} />
            </MenuItemCustom>
          )
        )}
      <MenuItemCustom 
        onClick={handleLogout}
        icon={
          <RiLogoutBoxRLine size={20} />
        }

      >
        Log out
      </MenuItemCustom>
    </Menu>
  );
}

const MenuItemCustom = styled(MenuItem)<{active?: boolean}>`
  color: ${(p) => p.active ? "#0F999B" : "#acacac"};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin: 10px 0px;
  text-transform: capitalize;
`;

const SubMenuCustom = styled(SubMenu)`
  color: #acacac;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin: 10px 0px;
`;
