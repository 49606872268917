import { Fragment } from "react";
import styled from "styled-components";

import FormVerify from "components/Organism/FormVerify";

export default function VerifyAdmin() {
  return (
    <Fragment>
      <ContainerCustom>
        <ContentBg className="bg"></ContentBg>
        <FormVerify type="admin" />
      </ContainerCustom>
    </Fragment>
  );
}

const ContentBg = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 50%;
  background-color: #0f999b;
  z-index: 1;
`;

const ContainerCustom = styled.div`
  height: 100vh;
  position: relative;
`;
