import ReactPaginate from "react-paginate";
import "./index.css";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";
import styled from "styled-components";

type PropsType = {
pageCount: number;
forcePage: number;
onPageChange: any;
}

export default function Pagination(props:PropsType) {
  const { pageCount, forcePage, onPageChange } = props;

  return (
    <div className="mt-5">
      <ReactPaginate
        nextLabel={
          <ContentButton>
            <MdArrowForwardIos size={14} />
          </ContentButton>
        }
        previousLabel={
          <ContentButton>
            <MdArrowBackIos size={14} />
          </ContentButton>
        }
        breakLabel={"..."}
        pageCount={pageCount}
        forcePage={forcePage - 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        containerClassName={"pagination"}
        activeClassName={"active"}
        onPageChange={onPageChange}
      />
    </div>
  );
}

const ContentButton = styled.div`
  min-width: 30px;
  min-height: 30px;
  // border: 1px solid #0F999B;
  // color: #0F999B;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
`;
