import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Bars, TailSpin } from "react-loader-spinner";
import { AiOutlineClockCircle } from "react-icons/ai";
import { createOrderClass } from "pages/CourseDetails/redux/actions";
import EllipseWarn from "assets/icons/ellipse-warning.svg";
import EllipseSuccess from "assets/icons/ellipse-success.svg";
import Pagination from "components/Atoms/Paginations";
import HorizontalScroll from "components/Molecules/HorizontalScroll";

import { RootState } from "redux/store";
import { getMyCourses, getAllClasses } from "../../redux/actions";
import { getCredential } from "utility/Utils";

type DataButton = {
  label: string;
  value: string | null;
};

type dataCourse = {
  id: string;
  image_url: string;
  language: string;
  duration_formatter: string;
  name: string;
  overview_items: string;
  is_paid: boolean;
  my_certificate: {
    completed: number;
  };
};

export default function HomeStudent(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setParams] = useState({
    with_pagination: true,
    limit: "3",
    page: 1,
  });
  const [active, setActive] = useState<string | null>(null);
  const [classes, setClasses] = useState<any>();
  const [loading, setLoading] = useState(false);

  const allMyCourses = useSelector((state: RootState) => state.my_courses);
  const allClasses = useSelector((state: RootState) => state.classess_courses);

  useEffect(() => {
    dispatch(getAllClasses({ limit: 9999 }));
    dispatch(getMyCourses(params));
  }, [dispatch, params]);

  useEffect(() => {
    if (!allClasses.loading && allClasses.status === 200) {
      const temp: DataButton[] = [
        {
          label: "All Course",
          value: null,
        },
      ];
      // eslint-disable-next-line array-callback-return
      allClasses.response.data.map((d: string) => {
        temp.push({
          label: d,
          value: d,
        });
      });
      setClasses(temp);
    }
  }, [allClasses]);

  const handleNavigate = (key: string) => {
    navigate(`${key}`);
  };

  type PropsHandlePage = {
    selected: number;
  };
  const handlePage = async ({ selected }: PropsHandlePage) => {
    setParams((prev) => ({
      ...prev,
      page: selected + 1,
    }));

    const payload = {
      ...params,
      page: selected + 1,
    };
    await dispatch(getMyCourses(payload));
  };

  const handleClass = async (value: string | null) => {
    setParams((prev) => ({
      ...prev,
      class: value,
    }));
    setActive(value);

    const payload = {
      ...params,
      class: value,
    };
    await dispatch(getMyCourses(payload));
  };


  const handleSubmited = async (id: string) => {
    setLoading(true);
    let payload = {
      first_name: "",
      last_name: "",
      email: "",
      course_id: id,
    };

    const user: any = getCredential();
    
    if (user !== null) {
      payload.first_name = user.first_name;
      payload.last_name = user.last_name;
      payload.email = user.email;
    }

    const res = await createOrderClass(payload);
    if (typeof res !== "boolean") {
      window.location.replace(res.data.redirect_url);
      setLoading(false);
    }

    setLoading(false);
  };

  return (
    <ContainerCenter>
      <div className="center-content">
        <div className="top-title">
          <h3>Your Course Access</h3>
          <h2>Course List</h2>
        </div>

        <HorizontalScroll>
          {!allClasses.loading &&
            classes &&
            classes.map((d: DataButton, i: number) => (
              <TabCustom
                key={i}
                className={`${
                  active === d.value ? "active" : ""
                } text-capitalize`}
                onClick={() => handleClass(d.value)}
              >
                {d.label}
              </TabCustom>
            ))}
        </HorizontalScroll>

        <div className="course-list row d-flex justify-content-between my-4">
          {allMyCourses.loading && (
            <div className="h-100 d-flex justify-center align-items-center">
              <TailSpin
                height="200"
                width="200"
                color="#0F999B"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          )}
          {!allMyCourses.loading &&
            allMyCourses.status === 200 &&
            allMyCourses.response.data.map((data: dataCourse, i: number) => (
              <div className="col-lg-12 card d-flex mb-5 border-0">
                <div className="row">
                  <div className="col-lg-2 content-img-left">
                    <img src={data.image_url} alt="saas" />
                  </div>
                  <div className="col-lg-9 content-card">
                    <h4>{data.name}</h4>
                    <span>{data.overview_items}</span>
                    <div className="time">
                      <AiOutlineClockCircle size={18} />
                      <small>{data.duration_formatter}</small>
                    </div>
                    <div className="status">
                      <img
                        src={
                          !data.my_certificate.completed
                            ? EllipseWarn
                            : EllipseSuccess
                        }
                        alt="ellipse"
                      />
                      <small>
                        {!data.my_certificate.completed
                          ? "Bootcamp Ongoing"
                          : "Bootcamp Completed"}
                      </small>
                    </div>
                    {data.is_paid ? (
                      <div className="btn-content-view">
                        <TabCustom
                          className="active"
                          onClick={() => handleNavigate(`/course/${data.id}`)}
                        >
                          View Class Details
                        </TabCustom>
                        <TabCustom
                          className={
                            data.my_certificate.completed ? "active" : ""
                          }
                          disabled={!data.my_certificate.completed}
                          onClick={() => handleNavigate(`/my-certificates`)}
                        >
                          View Certificate
                        </TabCustom>
                      </div>
                    ) : (
                      <div className="btn-content-view">
                        <TabCustom
                          className="active"
                          onClick={() => {
                            if (!loading) {
                              handleSubmited(data.id)
                            }
                          }}
                        >
                          {loading ? (
                            <Bars
                            height="40"
                            width="40"
                            color="#fff"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                          ) : (
                            "Pay"
                          )}
                        </TabCustom>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          {!allMyCourses.loading && allMyCourses.status === 200 && (
            <Pagination
              pageCount={allMyCourses.response.meta.last_page}
              forcePage={allMyCourses.response.meta.current_page}
              onPageChange={handlePage}
            />
          )}
        </div>
      </div>
    </ContainerCenter>
  );
}

const ContainerCenter = styled.div`
  padding: 30px 40px;
  position: relative;
  min-height: 90vh;

  .center-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;

    .top-title {
      display: flex;
      flex-direction: column;
      color: #303030;

      h3 {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }

      h2 {
        font-size: 27px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
      }
    }

    .filter-content {
      display: flex;
      align-items: center;
      justify-content: end;
      padding: 1.5rem 0;
    }

    .course-list {
      padding-left: 30px;
      .card {
        padding: 40px 0px;
        border-bottom: 1px solid #dbdbdb !important;

        .row {
          gap: 20px;

          .content-img-left {
            border-radius: 14px;
            background: #3fadaf;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 100%;
              height: 170px;
            }
          }

          .content-card {
            display: flex;
            flex-direction: column;
            gap: 10px;

            h4 {
              color: #303030;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 30px;
            }

            span {
              color: #6e6e6e;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 30px;
            }

            .time {
              display: flex;
              align-items: center;
              color: #6e6e6e;

              small {
                margin-left: 6px;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 27px;
              }
            }

            .status {
              display: flex;
              align-items: center;
              gap: 5px;
            }

            .btn-content-view {
              display: flex;
              align-items: center;
              gap: 10px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const TabCustom = styled.button`
  display: flex;
  min-width: 200px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: #e0e0e0;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  &.active {
    background: #0f999b;
  }
`;
