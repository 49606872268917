/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { TailSpin } from "react-loader-spinner";
import { toast } from "react-toastify";

import { useDispatch, useSelector } from "react-redux";
import {
  getDetailsStudent,
  loginAsStudent,
  deleteConsultationStudent,
} from "../redux/actions";

import Layout from "components/Organism/Layouts";
import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import { TableHeader } from "components/Atoms/Tables/header";
import TableRowCutom from "./components/row";
import ModalCustomUpload from "./components/modal/UploadCertificate";
import ModalCustomPayment from "./components/modal/ManualPayment";
import ModalAsLogin from "./components/modal/ModalAsLogin";
import ModalCustom from "components/Organism/Modal";

interface TypeDataClasses {
  course_id: string;
  order_id: string;
  user_course_id: string;
  course_name: string;
  joined_at: Date;
  completed_at: Date | null;
  course_status: number;
  payment_status: string;
  certificate_name: string;
  certificate_url: string;
}

interface TypeDataConsult {
  consultation_id: string;
  user_consultation_id: string;
  order_id: string;
  consultation_name: string;
  mentor:  string;
  consultation_status: string;
  payment_status: string;
  duration?: string;
}

let tableTitleClass = [
  "Class Name",
  "Joined Date",
  "End Date",
  "Class Status",
  "Payment Status",
  "Certificate",
  "Action",
];

let tableTitleConsultation = [
  "Consultation Name",
  "Duration",
  "Mentor",
  "Consultation Status",
  "Payment Status",
  "Action",
];

export default function EditStudentPage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [successUploadPayment, setSuccessUploadPayment] = useState(false);
  const [successUpload, setSuccessUpload] = useState(false);
  const [successDeleteConsul, setSuccessDeleteConsul] = useState(false);
  const [successDeleteClass, setSuccessDeleteClass] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalAsLogin, setShowModalAsLogin] = useState(false);
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [showModalDeleteClass, setShowModalDeleteClass] = useState(false);
  const [showModalDeleteConsul, setShowModalDeleteConsul] = useState(false);

  const [dataClass, setDataClass] = useState({});
  const [dataConsultation, setDataConsultation] = useState({});
  const [selected, setSelected] = useState("");
  const [typePayment, setTypePayment] = useState("");

  
  const data_student = useSelector((state: any) => state.details_student);

  useEffect(() => {
    if (id) {
      dispatch(getDetailsStudent({ id: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!data_student.loading && data_student.status === 200) {
      handleGetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data_student]);


  const handleGetData = () => {
    let objClass = {};
    let objConsul = {};

    data_student.response.data.courses.map((d: TypeDataClasses, i: number) => {
      let oneRow = {
        id: d.course_id,
        user_course_id: d.user_course_id,
        name: d.course_name,
        created_at: moment(d.joined_at).format("DD MMMM YYYY"),
        end_at: d.completed_at ? moment(d.completed_at).format("DD MMMM YYYY") : '-',
        class_status: d.course_status ? 1 : 0,
        payment_status: d.payment_status ? "paid" : "unpaid",
        certificate: d.certificate_url && d.certificate_url !== "" ? {
          name: d.certificate_name === "" ? "View Certificate" : d.certificate_name,
          url: d.certificate_url
        } : d.course_status ? null : "",
        action: "",
      };

      objClass = { ...objClass, [i]: oneRow };
    });

    data_student.response.data.consultations.map((d: TypeDataConsult, i: number) => {
      let oneRow = {
        id: d.consultation_id,
        user_consultation_id: d.user_consultation_id,
        name: d.consultation_name,
        duration: d.duration,
        mentor: d.mentor,
        consultation_status: d.consultation_status ? 1 : 0,
        payment_status: d.payment_status ? "paid" : "unpaid",
        action: "",
      };

      objConsul = { ...objConsul, [i]: oneRow };
    });

    setDataClass(objClass);
    setDataConsultation(objConsul);
  };

  const handleNavigate = (key: string): void => {
    if (key === "back") {
      return navigate(-1);
    }
    navigate(key);
  };

  const confirmDelete = async (type: string) => {
    setLoading(true);
    if (type === "class") {
      const res = await deleteConsultationStudent({ user_course_id: selected })
      if (res.status) {
        setLoading(false);
        handleCloseDelete("class");
        setSuccessDeleteClass(true);
        if (id) {
          dispatch(getDetailsStudent({ id: id }))
        }
      }
      if (!res.status) {
        setLoading(false);
        toast.error(res.message);
      }
    }
    if (type === "consul") {
      const res = await deleteConsultationStudent({ user_consultation_id: selected })
      if (res.status) {
        setLoading(false);
        handleCloseDelete("consul");
        setSuccessDeleteConsul(true);
        if (id) {
          dispatch(getDetailsStudent({ id: id }))
        }
      }
      if (!res.status) {
        setLoading(false);
        toast.error(res.message);
      }
    }
  }

  const handleModalUpload = () => setShowModal(!showModal);
  const handleModalPayment = () => setShowModalPayment(!showModalPayment);
  const handleModalLogin = () => setShowModalAsLogin(!showModalAsLogin);
  const handleModalDeleteClass = () => setShowModalDeleteClass(!showModalDeleteClass);
  const handleModalDeleteConsul = () => setShowModalDeleteConsul(!showModalDeleteConsul);

  const handleUpload = (key: string) => {
    setSelected(key);
    handleModalUpload();
  };
  
  const handleCloseUpload = () => {
    setSelected("");
    handleModalUpload();
  };

  const handleViewConvertation = (key: string) => {
    console.log("key", key)
    navigate(`/admin/consultation-room/${key}`);
  };

  const handleShowPayment = (key: string, type: string) => {
    setTypePayment(type);
    setSelected(key);
    handleModalPayment();
  };

  const handleDelete = (key: string, type: string) => {
    setSelected(key);
    if (type === "class") {
      handleModalDeleteClass();
    }
    if (type === "consul") {
      handleModalDeleteConsul();
    }
  };

  const handleCloseDelete = (type: string) => {
    setSelected("");
    if (type === "class") {
      handleModalDeleteClass();
    }
    if (type === "consul") {
      handleModalDeleteConsul();
    }
  };

  const handleSuccessUploadCert = () => {
    setSelected("");
    handleCloseUpload();
    setSuccessUpload(true);
    if (id) {
      dispatch(getDetailsStudent({ id: id }));
    }
  }

  const handleSuccessUploadPayment = () => {
    if (id) {
      setSelected("");
      handleModalPayment();
      setSuccessUploadPayment(true);
      dispatch(getDetailsStudent({ id: id }))
    }
  }

  const ConfirmLoginAsStudent = async () => {
    if (id) {
      setLoadingLogin(true);
      const payload = new FormData();
      payload.append("user_id", id)
      const res = await loginAsStudent(payload);

      if (res.status) {
        setLoadingLogin(false);
        window.open(`${window.location.origin}/course?a=${res.token}`);
        handleModalLogin();
      }
      if (!res.status) {
        setLoadingLogin(false);
      }
    }
  }
  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Student Details" />

        <ContainerCenter>
        {data_student.loading ? (
            <div className="h-100 d-flex justify-center align-items-center">
              <TailSpin
                height="200"
                width="200"
                color="#0F999B"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : !data_student.loading && data_student.status === 200 && (
            <div className="form-content">
              <Row className="mb-3 border-bottom pb-5">
                <div className="title-form">
                  <h2>General Information</h2>
                </div>
                <div className="col-lg-6 my-2">
                  <ContentInfo>
                    <h2>Studen Name</h2>
                    <span>{data_student.response.data.full_name}</span>
                  </ContentInfo>
                </div>
                <div className="col-lg-6 my-2">
                  <ContentInfo>
                    <h2>Date Joined</h2>
                    <span>{moment(data_student.response.data.created_at).format("DD MMMM YYYY")}</span>
                  </ContentInfo>
                </div>
                <div className="col-lg-6 my-2">
                  <ContentInfo>
                    <h2>Email</h2>
                    <span>{data_student.response.data.email}</span>
                  </ContentInfo>
                </div>
              </Row>

              <Row className="mb-3 border-bottom pb-5">
                <div className="title-form p-0">
                  <h2>Class List</h2>
                </div>

                <table className="table mt-3">
                  <TableHeader data={tableTitleClass} />
                  <tbody>
                    {dataClass
                      ? Object.values(dataClass).map((columns: any) => (
                          <TableRowCutom
                            key={columns.id}
                            columns={columns}
                            createTitle="Create a Manual Payment"
                            createAction={columns.payment_status === "unpaid" ? () => handleShowPayment(columns.id, "class") : undefined }
                            handleUpload={() => handleUpload(columns.user_course_id)}
                            deleteTitle="Remove From Class"
                            deleteAction={() => handleDelete(columns.user_course_id, "class")}
                          />
                        ))
                      : ""}
                  </tbody>
                </table>
              </Row>

              <Row className="mb-3 border-bottom pb-5">
                <div className="title-form p-0">
                  <h2>Consultation List</h2>
                </div>
                <table className="table mt-3">
                  <TableHeader data={tableTitleConsultation} />
                  <tbody>
                    {dataConsultation
                      ? Object.values(dataConsultation).map((columns: any) => (
                          <TableRowCutom
                            key={columns.id}
                            columns={columns}
                            viewTitle="View Conversation"
                            viewAction={() => {
                              console.log(columns)
                              handleViewConvertation(columns.user_consultation_id)}}
                            createTitle="Create a Manual Payment"
                            createAction={columns.payment_status === "unpaid" ? () => handleShowPayment(columns.id, "consul") : undefined }
                            deleteTitle="Delete Consultation"
                            deleteAction={() => handleDelete(columns.user_consultation_id, "consul")}
                          />
                        ))
                      : ""}
                  </tbody>
                </table>
              </Row>

              <div className="d-flex gap-3 justify-content-end">
                <ButtonCancel
                  type="button"
                  onClick={handleModalLogin}
                >
                  Login as {data_student.response.data.full_name}
                </ButtonCancel>
                <ButtonCancel
                  type="button"
                  onClick={() => handleNavigate("back")}
                >
                  Back
                </ButtonCancel>
              </div>
            </div>
          )}
        </ContainerCenter>
      </ContainerCustom>

      <ModalCustomUpload
        show={showModal}
        onHide={handleCloseUpload}
        selected={selected}
        onConfirm={handleSuccessUploadCert}
        title="Upload Certificate"
      />
      
      <ModalCustom
        show={successUpload}
        key_component="Certificate"
        onHide={() => {
          setSuccessUpload(false)
        }}
        type="success"
        title="Success"
        desc="Successfully upload certificate class student"
      />
      

      <ModalCustomPayment
        show={showModalPayment}
        onHide={handleModalPayment}
        id={id ? id : ""}
        selected={selected}
        type={typePayment}
        onConfirm={handleSuccessUploadPayment}
        title="Create Manual Payment"
      />

      <ModalAsLogin
        show={showModalAsLogin}
        onHide={handleModalLogin}
        loading={loadingLogin}
        onConfirm={ConfirmLoginAsStudent}
        title={`Login As ${data_student?.response?.data?.full_name}`}
        desc={`Anda akan login sebagai ${data_student?.response?.data?.full_name}`}
      />
      
      <ModalCustom
        show={showModalDeleteClass}
        onHide={() => handleCloseDelete("class")}
        onConfirm={() => confirmDelete("class")}
        loading={loading}
        type="delete"
        key_component="Class Student"
        title="Delete Class Student?"
        desc="Are you sure want to delete this class student?, This class student will not have a access anymore."
      />
      
      <ModalCustom
        show={successDeleteClass}
        key_component="Class"
        onHide={() => setSuccessDeleteClass(false)}
        type="success"
        title="Success"
        desc="Successfully delete class student"
      />
      
      <ModalCustom
        show={showModalDeleteConsul}
        onHide={() => handleCloseDelete("consul")}
        onConfirm={() => confirmDelete("consul")}
        loading={loading}
        type="delete"
        key_component="Consultation"
        title="Delete Consultation Student?"
        desc="Are you sure want to delete this consultation student?, This consultation student will not have a access anymore."
      />
      
      <ModalCustom
        show={successDeleteConsul}
        key_component="Consultation"
        onHide={() => setSuccessDeleteConsul(false)}
        type="success"
        title="Success"
        desc="Successfully delete consultation student"
      />

      <ModalCustom
        show={successUploadPayment}
        key_component="Payment"
        onHide={() => setSuccessUploadPayment(false)}
        type="success"
        title="Success"
        desc="Successfully upload manual payment"
      />

    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0;
`;

const ContainerCenter = styled.div`
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  .form-content {
    width: 100%;

    .title-form {
      margin-bottom: 10px;
      h2 {
        color: #303030;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const ButtonCancel = styled.button`
  display: flex;
  min-width: 150px;
  height: 54px;
  padding: 18px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background: transparent;

  color: #0f999b;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  border: 1px solid #0f999b;

  &:hover {
    background: #0f999b;
    color: #fff;
  }
`;

const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    color: #303030;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    margin-bottom: 0;
  }

  span {
    color: #acacac;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
  }
`;
