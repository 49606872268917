import { loginReducer } from "pages/Authentication/redux/reducers";

import {
  myCourseReducer,
  coursesReducer,
  courseOneReducer,
  classessCourseReducer,
} from "pages/Home/redux/reducers";
import {
  courseDetailsReducer,
  viewCourseDetailsReducer,
} from "pages/CourseDetails/redux/reducers";
import { commentsReducer } from "pages/Playlist/redux/reducers";
import {
  myCertificatesReducer,
  detailsCertificatesReducer,
} from "pages/Settings/MyCertificates/redux/reducers";
import {
  MyConsultationsReducer,
  MyDetailsConsultationsReducer,
  MyConvertationReducer,
  AllClassReducer,
} from "pages/Consultation/redux/reducers";

import {
  mentorsReducer,
  detailsMentorReducer,
} from "pages/Admin/MentorManagement/redux/reducers";
import {
  ConsultationsReducer,
  DetailsConsultationsReducer,
  AllConvertationRoomReducer,
  DetailsConvertationReducer,
} from "pages/Admin/ConsultationManagement/redux/reducers";

import {
  studentsReducer,
  detailsStudentReducer,
} from "pages/Admin/StudentManagement/redux/reducers";
import { MyAccountReducer } from "pages/Settings/MyAccount/redux/reducers";
import { classessReducer, consultationsReducer } from "pages/Checkout/redux/reducers";

const rootReducer = {
  // Authentications
  login: loginReducer,
  my_account: MyAccountReducer,

  // All Class
  all_classes: AllClassReducer,

  // My courses
  my_courses: myCourseReducer,
  classess_courses: classessCourseReducer,

  // My consultations
  my_consultations: MyConsultationsReducer,
  details_my_consultation: MyDetailsConsultationsReducer,
  convertations: MyConvertationReducer,

  // Course details
  course_details: courseDetailsReducer,
  comments: commentsReducer,
  view_course: viewCourseDetailsReducer,

  // Settings
  certificates: myCertificatesReducer,
  details_certificate: detailsCertificatesReducer,

  // Administration
  courses: coursesReducer,
  one_course: courseOneReducer,
  mentors: mentorsReducer,
  details_mentor: detailsMentorReducer,
  students: studentsReducer,
  details_student: detailsStudentReducer,

  consultations_admin: ConsultationsReducer,
  details_consultation_admin: DetailsConsultationsReducer,
  consultations_rooms: AllConvertationRoomReducer,
  convertations_mentor: DetailsConvertationReducer,

  all_classes_data: classessReducer,
  all_consultations_data: consultationsReducer

};

export default rootReducer;
