/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { BiDollar } from "react-icons/bi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/store";

import LogoExclo from "assets/images/exclodemy.png";
import CertExclo from "assets/images/exclodemy-cert.png";
// import NavbarLogin from "components/Organism/Navbar/NavbarLogin";

import { getDetailsCertificate } from "../MyCertificates/redux/actions";
import { fCurrency } from "utility/Utils";

type DataCurrency = {
  amount: string;
  currency_iso3: string;
};

export default function CertificateProof(): JSX.Element {
  const dispatch = useDispatch();
  const { iduser, idcourse } = useParams();

  const certificateData = useSelector((state: RootState) => state.details_certificate);
  
  useEffect(() => {
    if (iduser && idcourse) {
      dispatch(getDetailsCertificate(iduser, idcourse));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const filterDataPrice = (data: DataCurrency[]) => {
    let result: string = "";
    // eslint-disable-next-line array-callback-return
    data.filter((item) => {
      if (item.currency_iso3.toLowerCase() === "idr") {
        result = item.amount;
      }
    });

    return result;
  };

  return (
    <ContainerCustom fluid>
      {/* <NavbarLogin /> */}

      <ContainerCenter>
        <div className="center-content">
          <div className="cert-head-div">
            <div className="cert-head-title">
              <h2>Digital Completion Certificate</h2>
            </div>
            {!certificateData.loading && certificateData.status === 200 &&
            (
              <div className="cert-sub-head">
                <div className="cert-sub-head-user-div">
                  <span className="cert-sub-head-user-text">{certificateData.response.user.full_name}</span>
                </div>
                <div className="cert-sub-head-poweredby-div">
                  <span className="cert-sub-head-poweredby-text">Powered by</span>
                  <img
                    src={LogoExclo}
                    alt="logo"
                    className="cert-sub-head-poweredby-icon"
                  />
                </div>
              </div>
            )
            }
          </div>

          <CertDiv>
            <div className="content-center">
            {!certificateData.loading && certificateData.status === 200 && (
              <>
                <h1>{certificateData.response.user.full_name}</h1>
                <span>
                  For completing the {certificateData.response.course.name} by ExcloDemy in the month of {certificateData.response.course.my_certificate.month} {certificateData.response.course.my_certificate.year}.
                </span>
              </>
            )}
            </div>
          </CertDiv>

          <div className="mt-3 content-foot">
            <div className="content-foot-head">
                <span className="title-foot">About the Course:</span>
            </div>
            {!certificateData.loading && certificateData.status === 200 && (
              <div className="content-foot-bottom mt-">
                  <div className="content-course">
                      <img src={certificateData.response.course.image_url} alt="logo" />
                      <h2>{certificateData.response.course.name}</h2>
                  </div>
                  <div className="content-desc">
                      <div className="content-list-desc">
                          <div className="item-list">
                              <AiOutlineClockCircle className="icon-list" />
                              <span className="text-list">Length</span>
                          </div>
                          <span className="text-desc">
                          {certificateData.response.course.duration_formatter}
                          </span>
                      </div>
                      <div className="content-list-desc">
                          <div className="item-list">
                              <BiDollar className="icon-list" />
                              <span className="text-list">Price</span>
                          </div>
                          <span className="text-desc">
                          {fCurrency(
                            filterDataPrice(certificateData.response.course.prices)
                          )}
                          </span>
                      </div>
                  </div>
              </div>
            )}
          </div>
        </div>
        <div className="footer-content">
            <span className="text-title">
                Certificate issued by:
            </span>
            <a href="https://exclolab.com/exclodemy" target="_blank" >
                ExcloDemy
            </a>
        </div>
      </ContainerCenter>
    </ContainerCustom>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0;
  background-color: rgba(241, 244, 247, 1);
`;

const ContainerCenter = styled.div`
  padding: 120px 300px 50px 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .center-content {
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem;
    box-shadow: 0px 1px 3px rgba(126, 141, 160, 0.15) !important;
    background-color: #fff;
    border-radius: 6px;

    .cert-head-div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .cert-head-title {
        text-align: center;
        color: #303030;
        padding-bottom: 25px;
        width: 100%;

        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 29.5px;
        }
      }

      .cert-sub-head {
        display: flex;
        align-items: center;
        justify-content: space-between !important;

        .cert-sub-head-user-div {
            .cert-sub-head-user-text {
                font-weight: 500;
                font-size: 14.5px;
                letter-spacing: -.02em;
                color: #2d343f;
            }
        }

        .cert-sub-head-poweredby-div {
            display: flex;
            align-items: center;
            .cert-sub-head-poweredby-text {
                margin-right: 0.15rem;
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                letter-spacing: -.02em;
                color: #7e8da0;
            }
             .cert-sub-head-poweredby-icon {
                height: 70px;
             }
        }
      }
    }

    .content-foot {
        .content-foot-head {
            .title-foot {
                font-style: normal;
                font-weight: 500;
                font-size: 13.5px;
                letter-spacing: -.02em;
                color: #7e8da0;
                margin-bottom: 25px;
            }
        }

        .content-foot-bottom {
            display: flex;
            justify-content: space-between;

            .content-course {
                display: flex;
                align-items: center;
                margin-top: 20px;

                img {
                    width: 62px;
                    height: 40px;
                }

                h2 {
                    margin-left: 0.45rem;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 17.5px;
                    letter-spacing: -.02em;
                    color: #11294f;
                    overflow-wrap: anywhere;
                    height: -webkit-fit-content;
                    height: -moz-fit-content;
                    height: fit-content;
                    text-transform: capitalize;
                }
            }

            .content-desc {
                display: flex;
                align-items: center;
                gap: 20px;
                .content-list-desc {
                    .item-list {
                        display: flex;
                        align-items: center;
                        .icon-list {
                            color: #2973e0;
                        }
                        .text-list {
                            margin-left: 0.2rem;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12.5px;
                            letter-spacing: -.02em;
                            color: #7e8da0;
                            text-transform: capitalize;
                        }
                    }

                    .text-desc {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14.5px;
                        letter-spacing: -.02em;
                        color: #2d343f;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
  }

  .footer-content {
    padding-top: 50px;
    text-align: center;

    .text-title {
        font-style: normal;
        font-weight: 500;
        font-size: 13.5px;
        letter-spacing: -.02em;
        color: #11294f;
        margin-right: 0.15rem;
    }

    a {
        font-style: normal;
        font-weight: 500;
        font-size: 13.5px;
        letter-spacing: -.02em;
        color: #2973e0;
        text-transform: capitalize;
    }
  }

  @media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  }

  @media (max-width: 428px) {
  }
`;

const CertDiv = styled.div`
  background-image: url(${CertExclo});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  min-height: 900px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;

  .content-center {
    position: absolute;
    top: 46%;
    left: 15%;
    display: flex;
    // justify-content: center;
    // align-items: center;
    flex-direction: column;
    // text-align: center;
    gap: 10px;

    h1 {
      font-family: "Dancing Script", cursive;
      font-size: 72px;
    }
  }
`;
