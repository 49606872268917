import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

import Loader from "components/Molecules/Loader";
import { PrivateRoute, PublicRoute } from "services/AuthService";

// import StudentLoginPage from "pages/Authentication/login/student";
import AdminLoginPage from "pages/Authentication/login/admin";
import ForgotPasswordPage from "pages/Authentication/verify/student";
import ForgotPasswordAdminPage from "pages/Authentication/verify/admin";
import ChangePasswordStudentPage from "pages/Authentication/resetPassword/student";
import ChangePasswordAdminPage from "pages/Authentication/resetPassword/admin";

import HomeStudent from "pages/Home";

import HomeAdmin from "pages/Admin/CourseManagement";
import CourseNew from "pages/Admin/CourseManagement/admin/CreateCourse";
import CourseEdit from "pages/Admin/CourseManagement/admin/EditCourse";
import CourseDetails from "pages/CourseDetails";
import Playlist from "pages/Playlist";
import Checkout from "pages/Checkout";
import Consultations from "pages/Consultation";
import ConsultationsDetails from "pages/Consultation/Details";
import MyAccount from "pages/Settings/MyAccount";
import MyCertificates from "pages/Settings/MyCertificates";
import CertificatesProof from "pages/Settings/ProofCertificate";

import MentorPage from "pages/Admin/MentorManagement/pages";
import MentorNewPage from "pages/Admin/MentorManagement/pages/Create";
import MentorEditPage from "pages/Admin/MentorManagement/pages/Edit";
import ConsultationManagementPage from "pages/Admin/ConsultationManagement/pages";
import ConsultationNewPage from "pages/Admin/ConsultationManagement/pages/Create";
import ConsultationEditPage from "pages/Admin/ConsultationManagement/pages/Edit";
import ConsultationRoomPage from "pages/Admin/ConsultationManagement/pages/Room";
import ConvertationRoomPage from "pages/Admin/ConsultationManagement/pages/Convertation";

import StudentsListPage from "pages/Admin/StudentManagement/pages";
import StudentsNewPage from "pages/Admin/StudentManagement/pages/Create";
import StudentsDetailsPage from "pages/Admin/StudentManagement/pages/Details";

export default function Router(): JSX.Element {
  // Lazy Load
  const LoginLazy = lazy(
    async () => await import("pages/Authentication/login/student")
  );

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* Admin */}
        <Route
          path="/admin/login"
          element={
            <PublicRoute>
              <AdminLoginPage />
            </PublicRoute>
          }
        />
        
        <Route
          path="/admin/forgot-password"
          element={
            <PublicRoute title="Forgot Password">
              <ForgotPasswordAdminPage />
            </PublicRoute>
          }
        />
        
        <Route
          path="/admin/reset-password"
          element={
            <PublicRoute title="Forgot Password">
              <ChangePasswordAdminPage />
            </PublicRoute>
          }
        />
        
        <Route
          path="/admin/course"
          element={
            <PrivateRoute title="Course">
              <HomeAdmin />
            </PrivateRoute>
          }
        />
        
        <Route
          path="/admin/course/new"
          element={
            <PrivateRoute title="Course New">
              <CourseNew />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin/course/edit/:id"
          element={
            <PrivateRoute title="Course Edit">
              <CourseEdit />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin/mentor"
          element={
            <PrivateRoute title="Mentor">
              <MentorPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin/mentor/new"
          element={
            <PrivateRoute title="Mentor New">
              <MentorNewPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin/mentor/edit/:id"
          element={
            <PrivateRoute title="Mentor Edit">
              <MentorEditPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin/consultation-room"
          element={
            <PrivateRoute title="Consultations Room">
              <ConsultationRoomPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin/consultation-room/:id"
          element={
            <PrivateRoute title="Consultations Room">
              <ConvertationRoomPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin/consultation-management"
          element={
            <PrivateRoute title="Consultations Management">
              <ConsultationManagementPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin/consultation/new"
          element={
            <PrivateRoute title="Consultations New">
              <ConsultationNewPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/consultation/edit/:id"
          element={
            <PrivateRoute title="Consultations Edit">
              <ConsultationEditPage />
            </PrivateRoute>
          }
        />
        
        <Route
          path="/admin/students"
          element={
            <PrivateRoute title="Students List">
              <StudentsListPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin/students/new"
          element={
            <PrivateRoute title="Student Create">
              <StudentsNewPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin/students/view/:id"
          element={
            <PrivateRoute title="Student Details">
              <StudentsDetailsPage />
            </PrivateRoute>
          }
        />
        
        <Route
          path="/admin/my-account"
          element={
            <PrivateRoute title="Account">
              <MyAccount />
            </PrivateRoute>
          }
        />        

        {/* Students Sections */}
        <Route
          path="/login"
          element={
            <PublicRoute>
              <LoginLazy />
            </PublicRoute>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <PublicRoute title="Forgot Password">
              <ForgotPasswordPage />
            </PublicRoute>
          }
        />

        <Route
          path="/reset-password"
          element={
            <PublicRoute title="Forgot Password">
              <ChangePasswordStudentPage />
            </PublicRoute>
          }
        />

        <Route path="/checkout/:idcourse" element={<Checkout />} />

        <Route
          index
          element={
            <PrivateRoute title="Course">
              <HomeStudent />
            </PrivateRoute>
          }
        />

        <Route
          path="/"
          element={
            <PrivateRoute title="Course">
              <HomeStudent />
            </PrivateRoute>
          }
        />

          <Route
            path="/course"
            element={
              <PrivateRoute title="Course">
                <HomeStudent />
              </PrivateRoute>
            }
          />

        <Route
          path="/course/:namecourse"
          element={
            <PrivateRoute title="Course Details">
              <CourseDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/course/:namecourse/:sections/lessons/:contents"
          element={
            <PrivateRoute title="Playlist">
              <Playlist />
            </PrivateRoute>
          }
        />

        <Route
          path="/consultations"
          element={
            <PrivateRoute title="Consultation">
              <Consultations />
            </PrivateRoute>
          }
        />

        <Route
          path="/consultations/details/:id"
          element={
            <PrivateRoute title="Consultation Details">
              <ConsultationsDetails />
            </PrivateRoute>
          }
        />

        <Route
          path="/my-account"
          element={
            <PrivateRoute title="Account">
              <MyAccount />
            </PrivateRoute>
          }
        />

        <Route
          path="/my-certificates"
          element={
            <PrivateRoute title="Certificate">
              <MyCertificates />
            </PrivateRoute>
          }
        />

        <Route
          path="/certificate-proof/:iduser/:idcourse"
          element={<CertificatesProof />}
        />
      </Routes>
    </Suspense>
  );
}
