import { useEffect, useState } from "react";

type Props = {
  id: string;
  check: boolean;
  onChange: Function;
};

const InputCheckbox = (props: Props) => {
  const [checked, setChecked] = useState<boolean>(props.check);

  useEffect(() => {
    setChecked(props.check);
  }, [props.check]);
  const handleOnchangeChecked = async (
    e: React.FormEvent<HTMLInputElement>,
    id: string
  ) => {
    const { checked } = e.target as unknown as { checked: boolean };
    setChecked(checked);
    props.onChange(checked, id);
  };

  return (
    <input
      type="checkbox"
      className="checkbox-input"
      checked={checked}
      onChange={(e) => handleOnchangeChecked(e, props.id)}
    />
  );
};

export default InputCheckbox;
