export const GET_ALL_MENTOR_REQUEST = "GET_ALL_MENTOR_REQUEST";
export const GET_ALL_MENTOR_SUCCESS = "GET_ALL_MENTOR_SUCCESS";
export const GET_ALL_MENTOR_FAIL = "GET_ALL_MENTOR_FAIL";

export const GET_DETAILS_MENTOR_REQUEST = "GET_DETAILS_MENTOR_REQUEST";
export const GET_DETAILS_MENTOR_SUCCESS = "GET_DETAILS_MENTOR_SUCCESS";
export const GET_DETAILS_MENTOR_FAIL = "GET_DETAILS_MENTOR_FAIL";

export const mentorsReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_ALL_MENTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_MENTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_ALL_MENTOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};

export const detailsMentorReducer = (state = {}, action: any): any => {
  switch (action.type) {
    case GET_DETAILS_MENTOR_REQUEST:
      return {
        ...state,
        response: undefined,
        loading: true,
      };

    case GET_DETAILS_MENTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.payload,
        status: 200,
      };

    case GET_DETAILS_MENTOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: 400,
      };

    default:
      return state;
  }
};
