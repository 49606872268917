import styled from "styled-components";
import { Container } from "react-bootstrap";

import NavbarLogin from "components/Organism/Navbar/NavbarLogin";
import Layout from "components/Organism/Layouts";
import InfiniteScroll from "./components/InfiniteScroll";

import { checkRoles } from "utility/Utils";
import { Navigate } from "react-router-dom";

export default function RoomConvertations(): JSX.Element {
  const role = checkRoles();

  return (
    <Layout>
      <ContainerCustom fluid>
        <NavbarLogin title="Consultation Room" />
        {role === 1 ? (
          <InfiniteScroll />
        ) : (
          <Navigate to="/course" />
        )}
      </ContainerCustom>
    </Layout>
  );
}

const ContainerCustom = styled(Container)`
  position: relative;
  padding: 0px !important;
`;
