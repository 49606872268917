import { useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Form, InputGroup } from "react-bootstrap";
import { Bars } from "react-loader-spinner";

import { forgotPassword } from "../redux/actions";
import { toast } from "react-toastify";
import { RiLockPasswordLine } from "react-icons/ri";
import { MdOutlineEmail } from "react-icons/md";

export default function VerifyPage() {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return setValidated(true);
    }
    const payload = {
      email: event.target.email.value,
    };

    const res = await forgotPassword(payload);

    if (typeof res !== "boolean") {
      setLoading(false);
      toast.success(res.message);
      navigate("/login");
    }
    setLoading(false);
  };

  const handleSignIn = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <Container>
        <Card>
          <div className="content-top">
            <RiLockPasswordLine size={40} className="icon-lock" />
            <h2 className="title-card">Reset Password</h2>
            <span className="text-desc">
              We sent the verification code to your mail, please check your
              inbox and enter the code to the form below
            </span>
          </div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <InputGroupCustom>
              <InputCostumLeft>
                <MdOutlineEmail size={20} />
              </InputCostumLeft>
              <FormControl
                required
                type="email"
                placeholder="example@mail.com"
                id="email"
              />
            </InputGroupCustom>
            <ButtonCustomSend type="submit">
              {loading ? (
                <Bars
                  height="40"
                  width="40"
                  color="#fff"
                  ariaLabel="bars-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              ) : (
                "Confirm"
              )}
            </ButtonCustomSend>
            <LinkRegister>
              Already have an account? {" "}
              <span className="text" onClick={handleSignIn}>
                Sign In
              </span>
            </LinkRegister>
          </Form>
        </Card>
      </Container>
    </Fragment>
  );
}

const Container = styled.div`
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0f999b;
`;

const Card = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  display: flex;
  padding: 50px 30px;
  flex-direction: column;
  gap: 10px;
  align-self: stretch;
  border-radius: 20px;
  background: #fff;

  .content-top {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .icon-lock {
      color: #0f999b;
    }
    .title-card {
      color: #303030;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
    }

    .text-desc {
      color: #6e6e6e;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
    }
  }
`;

const FormControl = styled(Form.Control)`
  border: none;
  background: #f9fbfe;

  &::placeholder {
    color: #707070;
    text-align: justify;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
`;

const InputGroupCustom = styled(InputGroup)`
  padding: 5px !important;
  margin: 12px 0;
  border-radius: 10px;
  border: 1px solid #e6e6e6;

  background: #f9fbfe;
`;

const InputCostumLeft = styled(InputGroup.Text)`
  background: #f9fbfe;
  border: none;
`;

const ButtonCustomSend = styled.button`
  display: flex;
  height: 50px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: #0f999b;
  border: none;
  width: 100%;

  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const LinkRegister = styled.div`
  margin-top: 10px;
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;

  .text {
    color: #0f999b;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    cursor: pointer;
  }
`;
