import { ReactNode, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Sidebar } from "react-pro-sidebar";

import SidebarHeaderCustom from "components/Atoms/SidebarHeader";
import StudentMenu from "./components/students";
import AdminMenu from "./components/admin";

type PropTypes = {
  children: ReactNode;
};

export default function Layout({ children }: PropTypes): JSX.Element {
  const [check, setChek] = useState("");

  useEffect(() => {
    if (window.location.pathname.includes("admin")) {
      setChek("admin")
    }
    if (!window.location.pathname.includes("admin")) {
      setChek("student")
    }
  }, [])

  return (
    <main style={{ overflow: "hidden", minHeight: "100vh" }}>
      <Container fluid className="p-0">
        <div className="d-flex p-0" style={{ minHeight: "100vh" }}>
          <Sidebar
            rootStyles={{
              borderRight: "1px solid #DBDBDB",
              backgroundColor: "#fff !important",
              paddingLeft: "22px",
              paddingRight: "22px",
              minWidth: '300px'
            }}
          >
            <SidebarHeaderCustom
              style={{ marginBottom: "24px", marginTop: "16px" }}
            />
            {check === "student" ? (
              <StudentMenu />
            ) : (
              <AdminMenu />
            )}
          </Sidebar>
          {children}
        </div>
      </Container>
    </main>
  );
}
