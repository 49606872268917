import { axiosClient } from "services/GatewayService";
import { AppDispatch } from "redux/store";

import {
  GET_ALL_CLASSESS_REQUEST,
  GET_ALL_CLASSESS_SUCCESS,
  GET_ALL_CLASSESS_FAIL,
  GET_ALL_CONSULTATIONS_REQUEST,
  GET_ALL_CONSULTATIONS_SUCCESS,
  GET_ALL_CONSULTATIONS_FAIL,
} from "../reducers";

/**
 * Function to get all classes
 *
 */
export const getAllClasses = (params: {
  limit: number;
}): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: GET_ALL_CLASSESS_REQUEST });
    try {
      const res = await axiosClient.get("/api/courses", {
        params: {
          ...params,
        },
      });

      dispatch({
        type: GET_ALL_CLASSESS_SUCCESS,
        payload: res.data,
      });

    } catch (error: any) {
      dispatch({
        type: GET_ALL_CLASSESS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};

/**
 * Function to get all consultations
 *
 */
export const getAllConsultations = (params: {
  limit: number;
}): any => {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch({ type: GET_ALL_CONSULTATIONS_REQUEST });
    try {
      const res = await axiosClient.get("api/admin/common/consultations", {
        params: {
          ...params,
        },
      });

      dispatch({
        type: GET_ALL_CONSULTATIONS_SUCCESS,
        payload: res.data,
      });

    } catch (error: any) {
      dispatch({
        type: GET_ALL_CONSULTATIONS_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
};
