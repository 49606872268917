import { Fragment } from "react";
import { Form, Col } from "react-bootstrap";
import styled from "styled-components";
import { useFormContext, Controller } from "react-hook-form";

interface InputFileProps {
  name: string;
  label: string;
  isRequired?: boolean;
  placeholder: string;
  md?: string;
  id: string;
  type: string;
  rules?: {
    name: string;
    rules: any;
    errors?: any;
  };
}

/**
 * Input File component boilerplate.
 *
 * @param props
 * @constructor
 */
export default function InputFile(props: InputFileProps): JSX.Element {
  const { name, label, isRequired, placeholder, md, rules, id, type } = props;

  const { control } = useFormContext();

  return (
    <Fragment>
      <Controller
        name={name}
        control={control}
        rules={rules?.rules}
        render={({ field, fieldState: { error } }) => {
          const checkError = !!error && !field.value;

          return (
            <Form.Group as={Col} md={md} {...props}>
              <FormLabel>
                {label}
                {isRequired && <small className="req">*</small>}
              </FormLabel>
              <FormControlBasic
                id={id}
                type={type}
                placeholder={placeholder}
                errors={checkError}
                {...field}
              />
              {checkError && (
                <span
                  className="invalid-feedback d-block text-start"
                  style={{
                    fontSize: "0.75rem",
                  }}
                >
                  {error.message}
                </span>
              )}
            </Form.Group>
          );
        }}
      />
    </Fragment>
  );
}

const FormLabel = styled(Form.Label)`
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;

  .req {
    color: #fb6056;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }
`;

const FormControlBasic = styled(Form.Control)<{ errors?: boolean }>`
  background: #fff;
  border-radius: 10px;
  border: 1px solid
    ${(props) => (props.errors ?? false ? "#FB6056" : "#e6e6e6")};

  padding: 11px 22px;

  &::placeholder {
    color: #707070;
    text-align: justify;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
  }
`;
